<template>

  <!-- Homepage and Authentication Pages -->
  <section v-if="appIsRunning == 'website'">
    <router-view />
    <CookieVue />
  </section>
  <!-- Homepage and Authentication Pages -->

  <!-- Owner Dashboard Conent and Router View -->
  <OwnerDashboard v-if="appIsRunning == 'owner'" />
  <!-- Owner Dashboard Conent and Router View -->

  <!-- Tenants Dashboard and Router View -->
  <TenantDashboard v-if="appIsRunning == 'tenant'" />
  <!-- Tenants Dashboard and Router View -->

  <!-- Admin Dashboard -->
  <AdminDashboard v-if="appIsRunning == 'admin'" />
  <!-- Admin Dashboard -->

  <!-- Employee Dashboard -->
  <EmployeeDashboard v-if="appIsRunning == 'employee'" />
  <!-- Employee Dashboard -->

  <!-- Loader -->
  <div class="loadwebsite" id="loadwebsite" style="position: fixed;display: block;left: 0px;right: 0px;top: 0px;bottom: 0px;background-color: #343768;z-index: 99999999;text-align: center;">
      <div style="display: block; position: relative; width: 80px; vertical-align: middle; margin: 0px auto; top: calc(50vh - 55px); text-align: center;"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAABLCAYAAAA4TnrqAAAABHNCSVQICAgIfAhkiAAAA8JJREFUeF7tm09IVFEUxo+rmhwX0h/QtJ0TFCEpgVbkPyJIySJNjbBFaZktGi1XBUFRbnTchKa2aFOYQpQztQt1kUJoKbRoXCbpphY6NgVG3XPpyaSjc8/YG3D8Lrhxvnvfvb/5zrlvzn0v4bdqhGZEIAGwjDhpEWCZswIsASvAAiwJAYEWOQuwBAQEUjgLsAQEBFI4C7AEBARSOAuwBAQE0pg465P/M3V1+2hwaFwwNTOp0+mg7CwXNbjLKTVlq1mnKFW2w2JQ56rvRTk9824pClTPk1vkcGwy7yRU2g6rsamdhoYmhNOKTt58v4aKCrKi62zQy3ZYJ07dpOnprwZTWbuk6XoFlZflr32gFUawHdaBnDrbJr904KrKQmq4Vm7b9WIGK2t/Bj1sb7BlIdYXAlgGeAHLAJIlASzAQs5a1QNWiCDBG4QKYBlAWpp84SwDaHCWASQ4SwAJsGIE6+3wR0pL20670nfoK7Z4eik3Zw8dzN0bdgYb8qZ0dNRPrW295J+c0lBKinPVD+MyKjzaSDUXiqm2pgSwvqgSTqtyD1dSi4/nUKOqevr9U3T7zmMKBIL6D7CUTzq7vPS05w25MtKo9mIJOZMc5Gnr06XiyooC6vcNa83O1G26/JKd7VrmrrgPw4HBDyrk+mh2dl456Qzl52XqOj2DsxqXijkMGRznLd+rEco7krms3h7XsC7VtdLY+0ntHHbTwOC4zlUcbuEaw3IraIG5IHV2e3VOq68rpbLTeVoe17BaPM+oqrJIL/RGU8diQo+02VZVqEqoymf93mFKTnbS4UP74h+WBYV3v8v1nkiM/vn83Uj7xstZvGILFv9u5FDT/xvz6xDlxjsjnwXybsn5ituGhxV6W8A7X9cjnwbT8cCtd79QBwJWyA0nYCmXhCvRWI7hWwTr6J1DzjpnhLNC0nOkBA9YgLXy7o/in+DOCLAAS0BAIIWzAEtAQCCFswBLQEAghbMAS0BAIMVjkjbCSkzcTGdVFXUu8F1XROfnfxhfLW4ekzRd8YvndxcrEJF+aC8dc93Dyi9yG7sjXBlHEsZXr5yk89XHTL8Xsc72p5UlLw3wqyUvlbOSkrbohXBtq1Q9R2/a1v1LA9JQ0oetf4/p+azQ9IUDznWvvc3r+3UUdgUfqPLCZ2a+mZpEpOPw5SOy3a50UT+p2PYwDJ3QwsIvCgZ/Sue4qt4K2f866AqDxRRWLBZk5zUAS0AXsABLQEAghbMAS0BAIIWzAEtAQCCFswBLQEAghbMAS0BAIIWzAEtAQCD9A49mMk0RQ5BoAAAAAElFTkSuQmCC" alt="loading" style="width: 65px; height: 65px; margin-bottom: 0px; padding: 5px; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 6px; background-color: rgb(52, 55, 104); border: 2px solid rgb(52, 55, 104);"><p style="font-size: 11pt;letter-spacing: 0.15px;line-height: 30px;color: rgb(255, 255, 255);padding-left: 6px;">Loading...</p></div>
  </div>

  <ToolTip />
  <PropNotification />
  
</template>

<script>

  //@import custom library of Javascript
  import Document from './javascript/Document';
  import Cookie from './javascript/Cookie';
  
  // cookie
  import CookieVue from './components/website/Cookie.vue';

  import ToolTip from './components/reusable/ToolTip.vue';
  import PropNotification from './components/reusable/PropNotification.vue';

  // @import Componernts
  import OwnerDashboard from './components/app-route/OwnerDashboard.vue';
  import TenantDashboard from './components/app-route/TenantDashboard.vue';
  import AdminDashboard from './components/app-route/AdminDashboard.vue';
  import EmployeeDashboard from './components/app-route/EmployeeDashboard.vue';

  export default {
    name: 'App',

    components: {
      CookieVue,
      OwnerDashboard,
      TenantDashboard,
      AdminDashboard,
      EmployeeDashboard,

      ToolTip,
      PropNotification
    },

    data () {
      return {
        appIsRunning : null,
      }
    },

    mounted () {

      
      // site title and seo
      Document.DocumentHeadings ({title : 'PropStop'});

      const WebsitePath = [
        {
          id: 1,
          hash: '#privacy-policy',
          path: 'privacy-policy'
        },
        {
          id: 2,
          hash: '#terms-conditions',
          path: 'terms-conditions'
        },
        {
          id: 3,
          hash: '#cookie-policy',
          path: 'cookie-policy'
        },
        {
          id: 4,
          hash: '#about',
          path: 'about-us'
        },
        {
          id: 5,
          hash: '#features',
          path: 'web-features'
        },
        {
          id: 6,
          hash: '#tenants',
          path: 'web-tenants'
        },
        {
          id: 7,
          hash: '#landlords',
          path: 'web-landlords'
        },
        {
          id: 8,
          hash: '#waitlist',
          path: 'web-waitlist'
        },
        {
          id: 9,
          hash: '#explore',
          path: 'explore'
        },
        {
          id: 10,
          hash: '#auth',
          path: 'Auth'
        }
      ];

      // initiate the application 
      // start from website or dashboards
      let userRoute = Cookie.getCookie('ps-route');
      
      if (userRoute == "owner") {
        this.appIsRunning = "owner";
        this.$router.push({name : 'owner-dashboard'});
      
      }else if (userRoute == "tenant") {
        this.appIsRunning = "tenant";
        this.$router.push({name : 'tenant-dashboard'});

      }else if (userRoute == "admin") {
        this.appIsRunning = "admin"
        this.$router.push({name : 'admin-dashboard'});

      }else if (userRoute == "employee") {
        this.appIsRunning = "employee"
        this.$router.push({name : 'employee-dashboard'});
      
      }else {
        this.appIsRunning = "website";

        //todo only on the development environment
        this.$router.push({name: 'home-page'});
        
        //todo only on the production environemnt
        // let location = window.location;
        // let hash = location.hash;

        // let PathToPush = WebsitePath.filter(path => hash == path.hash);
        // if (PathToPush.length === 0) {
        //   // this.$router.push({name : 'home-page'});
        // }else this.$router.push({name : PathToPush[0].path});
      }

      // remove loader
      var ap = document.querySelectorAll('#loadwebsite');
      if (ap.length >= 1) {
          setTimeout(function () {
              ap[0].classList.add('remloadwebsite');
              setTimeout(function () {
              ap[0].remove();
              }, 10);
          }, 10);
      }

    },

  }

</script>
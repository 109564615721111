<template>
    <!-- Skeleton -->
    <div v-if="tickets === false" style="margin-top:55px">
        <Skeletion :data="4" />
    </div>

    <div v-if="tickets.length >= 1" class="tc-tickets">
        <div class="prop--table">
            <div class="prop-row row-header">
                <div class="column column25">Requested For</div>

                <div class="column column30">Subject
                    <span @click="SortBySubjectHandler">
                        <img src="../../images/icons/sort-up.svg" v-if="SortBySubject == true" />
                        <img src="../../images/icons/sort-down.svg" v-else-if="SortBySubject == false" />
                        <img src="../../images/icons/sort.svg" v-else />                            
                    </span>
                </div>

                <div class="column column25">Assignee</div>

                <div class="column column20">Status
                    <span @click="SortByStatusHandler">
                        <img src="../../images/icons/sort-up.svg" v-if="SortBySubject == true" />
                        <img src="../../images/icons/sort-down.svg" v-else-if="SortBySubject == false" />
                        <img src="../../images/icons/sort.svg" v-else />                            
                    </span>
                </div>
            </div>
            
            <!-- All ticket list -->
            <div class="prop-row ticketClk transAe" v-for="(ticket, index) in FilteredTicketList" :key="ticket"  :class="index % 2 ? 'odd' : 'even'" @click="tiketclicked(ticket)">
                <div class="column column25">
                    <div v-if="ticket.requested.length == 0" class="ticket--requested">
                        <div class="image">
                            <ReplaceImage :source="ticket.prop_unit.thumbnail"  />
                        </div>
        
                        <div class="content">
                            <h3>{{ ticket.prop_unit.unit }}</h3>
                            <p>{{ ticket.created }}</p>
                        </div>
                    </div>

                    <div v-else class="ticket--requested">
                        <div class="image">
                            <UserImage :init="ticket.requested.fullname" :source="ticket.requested.avatar" />
                        </div>
        
                        <div class="content">
                            <h3>{{ ticket.requested.fullname }}</h3>
                            <p>{{ ticket.created }}</p>
                        </div>
                    </div>
                </div>
        
                <div class="column column30">
                    <div class="ticket--subject">
                        <p><strong>#{{ticket.id}}</strong> {{ ticket.subject }}</p>
                        <ul class="ticket-priority">
                            <li v-if="ticket.priority == 'Critical'" class="critical"><img src="../../images/icons/critical.svg" /> <p>{{ticket.priority}}</p></li>
                            <li v-if="ticket.priority == 'High'" class="high"><img src="../../images/icons/high.svg" /> <p>{{ticket.priority}}</p></li>
                            <li v-if="ticket.priority == 'Normal'" class="normal"><img src="../../images/icons/normal.svg" /> <p>{{ticket.priority}}</p></li>
                            <li v-if="ticket.priority == 'Low'" class="low"><img src="../../images/icons/low.svg" /> <p>{{ticket.priority}}</p></li>
                        </ul>                        
                    </div>
                </div>
        
                <div class="column column25">
                    <div class="ticket-assignee">
                        <div class="assigneenme" v-for="(assignee) in ticket.assignee" :key="assignee">
                            <h3>{{assignee.fullname}}</h3>

                            <div class="employeehver animated fadeInUp" :key="index">
                                <div class="image">
                                    <UserImage :init="assignee.fullname" :source="assignee.avatar" />
                                    <img src="../../images/icons/favorite.svg" />
                                </div>
                                <div class="content">
                                    <h3>{{assignee.fullname}}</h3>
                                    <p>{{assignee.email}}</p>
                                    <p>{{assignee.phone}}</p>
                                </div>
                            </div>

                        </div>

                        <h3 v-if="ticket.assignee.length < 1" style="color: #ff5722;">Not Assigned</h3>
                    </div>
                </div> 
        
                <div class="column column20">
                    <div v-if="ticket.status == 'Open'" class="status primary">{{ticket.status}}</div>
                    <div v-else class="status closedticket">{{ticket.status}}</div>
                    
                    <p v-if="ticket.expiry == 'Expired' && ticket.status == 'Open'" style="color: #F00">{{ticket.expiry}}</p>
                    <p v-else-if="ticket.status == 'Open'" style="color: hsl(120deg 34% 32%)">{{ticket.expiry}}</p>
                </div>
            </div>
            <!-- All ticket list -->

        </div>
    </div>

    <!-- If no data on result -->
    <div v-if="FilteredTicketList.length <= 0" class="emptyskeleton">
        <img src="../../images/icons/empty-result.svg" class="psanim-1 fadeInUp">
        <p class="psanim-2 fadeInUp">You dont have much information on this request. <br />Please add new ticket to see more features.</p>
    </div>
    
</template>

<script>

    // js import
    import Handler from './../../javascript/Handler';

    import Skeletion from './../blocks/Skeleton.vue';
    import ReplaceImage from './../blocks/ReplaceImage.vue';
    import UserImage from './../blocks/UserImage.vue';

    import MultiPropertySelectionVue from '../web-forms/MultiPropertySelection.vue';
    
    export default {
        name: 'OwnerTickets',
        props: ['propselected', 'tickets', 'filter'],
        emits: ['ticketClicked'],
        components: {
            Skeletion,
            ReplaceImage,
            UserImage,

            MultiPropertySelectionVue
        },

        data () {
            return {
                FilteredTicketList : [],

                // last 30 days data
                ticketCategories : [],

                // sort functions
                SortByDate : null,
                SortBySubject : null,
                SortByStatus : null,
            }
        },

        mounted () {
            
            // filter according the clicked filter menu and load the data
            if (this.tickets)
                this.filterTheList ();

        },

        methods: {

            filterTheList () {
                // filter ticket with selected property
                // all tickets initially
                this.FilteredTicketList = this.tickets;

                // filter with selected now
                this.FilteredTicketList = this.FilteredTicketList.filter (ticket => {
                    // nothing to worry if need to load all the tickets 
                    if (this.filter == "all") {return ticket;}

                    // now load only the open status tickets
                    if (this.filter == "open") {
                        if (ticket.status == "Open") return ticket;
                    }

                    // now load only the closed status tickets
                    if (this.filter == "closed") {
                        if (ticket.status == "Closed") return ticket;
                    }

                    // now load only the unassigned tickets
                    if (this.filter == "unassigned") {
                        if (!ticket.isAssigned) return ticket;
                    }

                    // now load only the favourites tickets
                    if (this.filter == "favourites") {
                        if (ticket.isFavourite) return ticket;
                    }                    
                });
            },

            tiketclicked (ticket) {
                this.$emit('ticketClicked', ticket);
            },

            // sort handlers
            SortByDateHandler () {
                // toggle
                this.SortByDate = !this.SortByDate;

                this.FilteredTicketList.sort((a, b) => {
                    var c, d;
                    if (this.SortByDate) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    var aDate = a.created;
                    var aDateStamp = Date.parse(aDate);
                    var bDate = b.created;
                    var bDateStamp = Date.parse(bDate);

                    if (aDateStamp > bDateStamp) return 1;
                    if (aDateStamp == bDateStamp) return 0;
                    if (aDateStamp < bDateStamp) return -1;
                });
            },

            SortBySubjectHandler () {
                // toggle
                this.SortBySubject = !this.SortBySubject;
                this.FilteredTicketList.sort ((a, b) => {
                    var c, d;
                    if (this.SortBySubject) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    if (a.priority > b.priority) return 1;
                    if (a.priority == b.priority) return 0;
                    if (a.priority < b.priority) return -1;
                });
            },

            SortByStatusHandler () {
                // toggle
                this.SortByStatus = !this.SortByStatus;
                this.FilteredTicketList.sort ((a, b) => {
                    var c, d;
                    if (this.SortByStatus) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    if (a.status > b.status) return 1;
                    if (a.status == b.status) return 0;
                    if (a.status < b.status) return -1;
                });
            },

            // tooltip
            PropToolTip (todo) {
                var event = event || window.event;
                Handler.ToolTipHandler (todo, event);                
            }

        }
    };
</script>
<template>
    <div class="tenant-nav" style="position: relative;" :style="SidebarHamClick ? 'left: 0px !important;box-shadow: 0px 0px 10px rgb(0 0 0 / 33%) !important;' : ''">
        <div class="logo-content">
            <img src="../../images/company/nav-logo.svg" />
        </div>

        <ul class="navigation">
            <TenantSideNav v-for="list in navigationlist" :key="list.key" :current="list" @navchange="NavigationClicked" />
        </ul>

        <div class="owrprtties" id="ownerprtities" v-if="OnlylTenantProperties.length >= 1" @click="isPropertySelection = true">
            <div class="image">
                <ReplaceImageVue :source="SelectedProperty.property.image" :key="''" />
            </div>
            <div class="content">
                <h3>{{SelectedProperty.property.name}}</h3>
                <p>{{SelectedProperty.unit.unit_name}}</p>
            </div>
        </div>

        <div v-else class="sk-owrprtties">
            <SkeletonVue :type="'user-list'" :data="1" />
        </div>

        <div v-if="isPropertySelection">
            <PropertyListTenantVue :properties="TenantProperties" @prosel="PropertyChoosed" @proboxclse="propertylistClose" />
        </div>
    </div>

    <div class="tenant-content">
        <div class="content-header">
            <div class="ham-side-menu" @click="SidebarHamClick = true">
                <img src="./../../images/icons/line-menu.svg" />
            </div>

            <ul>
                <li class="transAe" :class="searchIsActive ? 'active' : ''"><h3>Dashboard</h3></li>
                <li class="transAe" :class="searchIsActive ? 'active' : ''">
                    <input type="text"
                            @click="openSearchRoute"
                            placeholder="Search in PropStop..." 
                            :style="{'background-image':'url('+require('../../images/icons/search.svg')+')'}" />
                </li>
                <li class="transAe">
                    <div class="tenant" v-if="TenantProfile">
                        <div class="notification" @click="tenantNotification = true">
                            <img src="../../images/icons/notification.svg" />
                            <span id="NotificationChanges" style="display: none;">0</span>
                        </div>

                        <div class="user" style="cursor: pointer;" @click="userProfileActive = true">
                            <h3>Hi, {{TenantProfile.fname}}</h3>
                            <div class="avatar">
                                <UserImage :init="TenantProfile.fullname" :source="TenantProfile.avatar" style="border-radius:50%" />
                            </div>
                        </div>
                        <TenantProfilePopup v-if="userProfileActive" :user="TenantProfile" @profileclose="closeProfilepopup" />
                    </div>
                </li>
            </ul>
        </div>

        <div class="page-content">
            <!-- Dynamic Loading via Fetch -->
            <router-view 
                    :profile="TenantProfile"
                    :properties="TenantProperties"
                    :selected="SelectedProperty"
                    :hasNoProperty="TenantHasNoProperty"

                    :key="tenantprofileloaded"   />
            <!-- Dynamic Loading via Fetch -->
        </div>
    </div>

    <!-- pop ups --> 
    <RightModal v-if="UpdateTenantProfile" :form="'tenant-profile-complete'" :data="TenantProfile" @modalclose="TenantIsVerified" />

    <!-- Welcome to propstop pop-ups -->
    <PropModal v-if="WelcomeToTenant == true" :from="'welcome-owner'" @modalclose="CloseWelcomeModal" />

    <Notifications v-if="tenantNotification" :accounttype="'tenant'" @notificlose="closeNotification" />
    <PropModal v-if="isOnRentChangesMode" :from="'rent-changes'" :data="RentChangesData" @modalclose="CloseRentChangesModal" />
    <AccountChanges accountype='tenant' />

</template>

<script>

    //@import JavaScript
    import DataCenter from './../../javascript/DataCenter';

    // pinia
    import { useTenantStore } from './../../stores/TenantStore';
    
    //@import component
    import TenantSideNav from './../tenants/TenantSideNav.vue';
    import UserImage from './../blocks/UserImage.vue';
    import ReplaceImageVue from '../blocks/ReplaceImage.vue';
    import TenantProfilePopup from './../tenants/TenantProfilePopup.vue';
    import AccountChanges from './../reusable/PropStopAccountChanges.vue';

    import SkeletonVue from '../blocks/Skeleton.vue';

    import Notifications from './../reusable/Notifications.vue';

    import PropertyListTenantVue from '../blocks/PropertyListTenant.vue';

    // Modal
    import RightModal from './../modal/RightModal.vue';
    import PropModal from './../modal/PropModal.vue';

    export default {
        name : 'TenantDashboard',
        components: {
            TenantSideNav,
            UserImage,
            ReplaceImageVue,
            TenantProfilePopup,
            AccountChanges,

            SkeletonVue,

            Notifications,
            PropertyListTenantVue,

            RightModal,
            PropModal
        },

        data () {
            return {
                userProfileActive : false,
                tenantNotification : false,
                isPropertySelection : false,

                TenantStore: useTenantStore (),
                
                // Tenant Dashboard Navigation List
                navigationlist : [
                    {
                        key  : 1,
                        name : 'Dashboard',
                        route : 'tenant-dashboard',
                        icon : 'images/icons/dashboard.svg',
                        active : true
                    },
                    {
                        key  : 2,
                        name : 'Property Detail',
                        route : 'tenant-property',
                        icon : 'images/icons/property.svg',
                        active : false
                    },
                    {
                        key  : 3,
                        name : 'Tickets',
                        route : 'tenant-tickets',
                        icon : 'images/icons/tickets.svg',
                        notification: 4,
                        active : false
                    },
                    {
                        key  : 4,
                        name : 'Payments',
                        route : 'tenant-payments',
                        icon : 'images/icons/finance.svg',
                        active : false
                    },
                    {
                        key  : 5,
                        name : 'Explore Properties',
                        route : 'tenant-search',
                        icon : 'images/icons/search.svg',
                        active : false
                    }
                ],

                // search functionality
                searchIsActive: false,

                // logged in owner profile
                TenantProfile : false,
                TenantProperties: [],
                SelectedProperty: {},
                tenantprofileloaded : false,
                UpdateTenantProfile : false,
                WelcomeToTenant : false,
                isOnRentChangesMode : false,
                RentChangesData: {},

                OnlylTenantProperties: [],

                TenantHasNoProperty: false,

                // responsive
                SidebarHamClick : false,
            }
        },

        async mounted () {

            // load the current tenant profile
            // user info if verified or not
            const RequestData = await DataCenter.ProPRequest('tenant-profile', false, false);
            if (!RequestData) {}else {
                const ReturnData = RequestData;
                const PropertyInfo = ReturnData.properties;
                this.TenantProfile = ReturnData.profile;
                this.TenantProperties = PropertyInfo
                this.SelectedProperty = PropertyInfo[0];

                this.TenantStore.ResetUser(this.TenantProfile);
                this.TenantStore.ResetProperties(this.TenantProperties);

                if (this.TenantProperties.length == 0) this.TenantHasNoProperty = true;

                // collect only properties
                var Properties = [];
                this.TenantProperties.filter(property => {
                    Properties.push (property.property);
                });this.OnlylTenantProperties = Properties;

                this.tenantprofileloaded = true;
                if (this.TenantProfile.verified == "false" || this.TenantProfile.verified == null) this.UpdateTenantProfile = true; 
                // this is the first load, they must complete simple profile.

                // rent changes mode
                this.RentChangesData = this.TenantProfile.rent_changes_of;
                this.isOnRentChangesMode = this.TenantProfile.rent_changes;

                 // update notifications
                let ElTickets = document.getElementById('TicketsChanges');
                let t  = this.TenantProfile.changes.tickets;
                ElTickets.innerHTML = t;
                if (t <= 0) ElTickets.style.display = 'none';
                else ElTickets.style.display = 'block';
            }
        },

        methods : {

            // property selected
            PropertyChoosed (property) {
                this.SelectedProperty = property;
                this.tenantprofileloaded = Math.random();

                // now close the list too
                this.propertylistClose ();
            },

            // close property list
            propertylistClose () {
                this.isPropertySelection = false;
            },

            TenantIsVerified (profile) {
                if (!profile) return;
                this.TenantProfile = profile;
                this.tenantprofileloaded = true;

                // close update profile
                this.UpdateTenantProfile = false;

                // welcome message to the tenant
                this.WelcomeToTenant = true;
            },

            CloseWelcomeModal () {
                this.WelcomeToTenant = false;
            },

            CloseRentChangesModal () {
                this.isOnRentChangesMode = false;
            },

             // change the active state on the navigation
            NavigationClicked (nav) {
                this.navigationlist = this.navigationlist.filter(list => {
                    list.active = false;
                
                    if (list.key == nav.key) {
                        list.active = true;
                    }

                    return list;
                });

                // now route to the acitve one
                this.$router.push({name : nav.route});
                this.searchIsActive = false;

                // remove sidebar menu
                this.SidebarHamClick = false;
            },

            closeProfilepopup () {
                this.userProfileActive = false;
            },

            closeNotification () {
                this.tenantNotification = false;
            },

            openSearchRoute () {
                this.searchIsActive = true;
                this.$router.push({name : 'tenant-search'});
            }
        },

        watch: {
            '$route' (to, from) {
                if(to.name == "tenant-profile") {
                    this.searchIsActive = false;
                }
            }
        }

    };
</script>


<style scoped>
/* this page css */.sk-owrprtties {    position: absolute;
    width: 90%;
    margin: 0px auto;
    height: auto;
    bottom: 25px;
    left: 5%;}
    .sk-owrprtties span {    background: #474a85 !important;
    border: 1px solid #449adf !important;}
    .sk-owrprtties .sk-image span {    height: calc(100% - 2px) !important;}
/* this page css */        
</style>
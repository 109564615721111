<template>
    <div class="internal--header" v-if="!TenantisSelected">
        <div class="summery">
            <ul class="backtounitlist" v-if="FloorPlanSelected">
                <li><img src="../../images/employee-detail/arrow-left.svg" @click="FloorplanBackBtnClick"> <p>Back</p></li>
            </ul>

            <h3 v-if="!FloorPlanSelected"><strong>{{totalFloor}}</strong> Floors</h3>
            <h3 v-if="!FloorPlanSelected"><strong>{{totalUnits}}</strong> Units</h3>            
        </div>

        <div class="actions" style="display: none;">
            <div class="btn-secondary" @click="ModifyUnit"><p>Modify Units</p></div>
        </div>
    </div>

    <!-- Loading State Units Skeletion -->
    <div v-if="!PropertyUnits" style="margin-top:55px">
        <Skeletion :data="3" />
    </div>

    <div v-if="!TenantisSelected && !FloorPlanSelected && PropertyUnits" class="prop--table animated fadeInUp">
        <WalkThroughVue
                v-if="WthoughPrimary"
                :image="'edit-property.svg'"
                :title="'Assign units'"
                :content="'You can assign units with new tenant and link foorplan too!'"
                :toPoint="'nowhere'"
                :style="'top: -55px;right: 55px;'"
                @closewth="CloseWalkThrough()" />

        <div v-for="floor in totalFloor" :key="floor">
            <div class="prop-row row-header">
                <div class="column column35">{{FloorName[floor - 1]}} Floor Units</div>
                <div class="column column25">Status</div>
                <div class="column column25">Floor Plan</div>
                <div class="column column15">Actions</div>
            </div>
            
            <UnitLists v-for="(unit, index) in PropertyUnits" 
                :key="unit.id" 
                :unit="unit" 
                :count="index" 
                :floor="floor - 1"
                @hamclick="ActionMenuClicked"
                @assignplan="AssignFloorPlan"
                @unitselected="UnitSelected"
                @assigntenant="AssignNewTenant"
                @unassigntenant="UnassignTenant"
                @floorplan="UnitFloorPlanDetail" />

        </div>        
    </div>

    <!-- Floorlan Detail Page -->
    <UnitFloorPlanDetail v-if="FloorPlanSelected == true" :property="property" :planid="CurrentUnitFloorPlanID" />

    <!-- Tenant Detail Page -->
    <TenantDetail v-if="TenantisSelected == true" :tenant="TenantChoosed" :property="property" :fullscreen="true" @backtolist="TennantBackBtnClick" />

    <!-- <UnitDetail v-if="UnitIsSelected" :unit="SelectedUnitDetail" @update="UpdateUpdatedUnits" /> -->


    <!-- If no data on result -->
    <div v-if="PropertyUnits.length <= 0" class="empty-result animated fadeInUp">
        <img src="../../images/icons/empty-result.svg" />
        <p>You dont have much information on this request. <br />Please modify or add units or floors.</p>
    </div>

    <!-- modify units -->
    <PropModal v-if="ModifyUnitsModal == true" :from="'modify-units'" :data="ModifyUnitData" @modalclose="CloseModifyUnitModal" />

    <RightModal v-if="AssignFloorPlanModal" :form="'assign-floor-plan'" :data="FloorPlanAssignData" @modalclose="CloseAssignFloorPlanModalBox" />

    <RightModal v-if="AssignTenantUnit" :form="'assign-unit'" :data="AssignTenantData" @modalclose="CloseAssignUnitModal" />

    <!-- remove contract -->
    <PropModal v-if="WantUnassignedTenant == true" :from="'confirm-unassign-tenant'" :data="UnassignUnitID" @modalclose="CloseConfrimUnassigned" />


</template>

<script>

    //@ JS Modules
    import Request from './../../javascript/getRequest';
    import Document from '../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';
    import Locale from './../../javascript/Locale';
    import Handler from './../../javascript/Handler';
    import Storage from './../../javascript/Storage';

    // pinia storage
    import { useOwnerStore } from './../../stores/OwnerStore';

    import Skeletion from './../blocks/Skeleton.vue';

    // @import components
    import HtmlImage from './../blocks/HtmlImage.vue';
    import UnitLists from './blocks/UnitLists.vue';
    import UnitDetail from './blocks/UnitDetail.vue';

    import TenantDetail from './../../components/owner/OwnerTenantDetail.vue';
    import UnitFloorPlanDetail from './blocks/UnitFloorPlanDetail.vue';

    // walk through
    import WalkThroughVue from './../reusable/WalkThrough.vue';

    // modal 
    import PropModal from './../modal/PropModal.vue';
    import RightModal from './../modal/RightModal.vue';

    export default {
        name : 'Units',
        props: ['property', 'initModify'],
        emits: ['dischargeModify'],
        components : {
            Skeletion,

            HtmlImage,
            UnitLists,
            UnitDetail,

            TenantDetail,
            UnitFloorPlanDetail,
            WalkThroughVue,

            // modal
            PropModal,
            RightModal,
        },

        data () {
            return {
                PropertyUnits : false,
                TenantisSelected : false,
                TenantChoosed: {},

                FloorPlanSelected: false,
                CurrentUnitFloorPlanID: '',

                UnitIsSelected : false,
                SelectedUnitDetail : null,
                totalUnits : parseInt(this.property.units.total),
                totalFloor : parseInt(this.property.floors),
                
                FloorName : Locale.FloorNumName(),

                ModifyUnitsModal : false,

                ModifyUnitData : null,

                // assign floor plan
                FloorPlanAssignData : null,
                AssignFloorPlanModal : false,

                // assign tenant
                AssignTenantUnit : false,
                AssignTenantData: {},
                WantUnassignedTenant : false,
                UnassignUnitID: null,

                // pinia storage
                OwnerStore: useOwnerStore (),

                // page walk through
                WthoughPrimary: false,

            }
        },

        async mounted () {

            // site title and seo
            Document.DocumentHeadings ({title : ' Properties - Units'});

            // walkthrough settings
            this.WthoughPrimary =  this.OwnerStore.walkthrough.property.units;  
            
            await this.loadCurrentPropertyUnits ();            

            if (this.initModify) {
                this.ModifyUnit();
            }
        },

        methods: {

            // close the walkthrough
            async CloseWalkThrough () {
                this.WthoughPrimary = false;
                this.OwnerStore.walkthrough.property.units = false;

                // update to the server
                await DataCenter.ProPRequest('owner-walkghrough', false, {
                    key : 'wlkough',
                    value : 'units'
                });
            },

            // tenant detail page back to units page
            TennantBackBtnClick (reload) {
                this.TenantisSelected = false;
                if (reload) this.loadCurrentPropertyUnits ();
            },

            // load floorplan detail page
            UnitFloorPlanDetail (unit) {
                var CurrentPlan = unit.floorplan;
                if (CurrentPlan) {
                    this.CurrentUnitFloorPlanID = CurrentPlan.id;
                    this.FloorPlanSelected = true;
                }
            },

            FloorplanBackBtnClick () {
                this.FloorPlanSelected = false;
            },

            // load property units
            async loadCurrentPropertyUnits () {
                // load units data from server
                let getdata = {
                    key : 'property',
                    value : this.property.id
                }
                
                const Units = await DataCenter.ProPRequest('property-units', true, getdata);
                if (!Units) {}else {
                    this.PropertyUnits = Units;
                }
            },

            //clicked to the unit
            UnitSelected (unit) {
                var CurrentTenant = unit.tenant;
                if (CurrentTenant.length > 0 || CurrentTenant.length == undefined) {
                    this.TenantChoosed = unit.tenant;
                    this.TenantisSelected = true;
                }                
            },
            
            // close modify unit modal
            CloseModifyUnitModal (units) {
                this.ModifyUnitsModal = false;
                this.$emit('dischargeModify');

                if (units) {
                    // show the skeletion
                    this.PropertyUnits = false;
                    
                    this.totalUnits = units.units,
                    this.totalFloor = units.floors,

                    this.property.units.total = units.units;
                    this.property.floors = units.floors;

                    // load all units
                    Storage.deleteCache ('property-units');
                    this.loadCurrentPropertyUnits ();
                }
            },

            // modify unit
            ModifyUnit () {
                if (this.PropertyUnits) {
                    
                    // modify unit data 
                    this.ModifyUnitData = {
                        totalfloor  : this.totalFloor,
                        totalunits  : this.totalUnits,
                        units       : this.PropertyUnits,
                        property    : this.property.id
                    };

                    // show the modal
                    this.ModifyUnitsModal = true;
                }
            },

            // action menu clicked
            ActionMenuClicked (unit) {
                this.PropertyUnits = this.PropertyUnits.filter (unt => {
                    unt.active = false;
                    if (unit.id == unt.id) unt.active = true;
                    return unt;
                });
            },

            // assign floorplan to the selected unit
            AssignFloorPlan (unit) {
                // sending data
                this.FloorPlanAssignData = {
                    property : this.property,
                    unit : unit
                }
                
                // remove action
                unit.active = false;

                // open modal
                this.AssignFloorPlanModal = true;
            },

            // close floor plan modal box
            CloseAssignFloorPlanModalBox (data) {

                this.AssignFloorPlanModal = false;
                
                if (data) {
                    // alert add plan box
                    if (data.addplan) {
                        // this.FloorPlanUpdate = true;
                    }

                    // update currently select plan to the unit
                    if (data.updateplan) {
                        this.PropertyUnits = this.PropertyUnits.filter (unit => {
                            if (unit.id == data.unit.id) {
                                unit.floorplan = data.planselected
                            }return unit;
                        })

                        // update notification
                        Handler.PropNotification ("Floorplan successfully assigned to the unit selected!");
                    }
                }
            },

            // assign tenant to the selected unit
            AssignNewTenant (unit) {
                // assign unit data
                this.AssignTenantData = {
                    property : this.property.id,
                    unit : unit.id,
                    tenant : false,
                    properties: []
                }

                // start the modal
                this.AssignTenantUnit = true;

                // remove action
                unit.active = false;
            },

            // Unassign tenant
            UnassignTenant (unit) {
                this.WantUnassignedTenant = true;
                this.UnassignUnitID = unit;

                // remove action
                unit.active = false;
            },

            async CloseConfrimUnassigned (unit) {
                if (unit) {
                    let formData = new FormData();
                    formData.append('property', this.property.id);
                    formData.append('unit', unit.id);
                    formData.append('tenant', unit.tenant.tid);

                    const UpdatedUnits = await Request.postServer('tenants/end-contract.php', formData);
                    if (!UpdatedUnits.error) {
                        this.PropertyUnits = false;
                        this.PropertyUnits = UpdatedUnits.response;
                    }
                }

                this.WantUnassignedTenant = false;
            },

            // close assign unit modal
            CloseAssignUnitModal (data) {
                if (data) {                    
                    this.PropertyUnits = data;
                    
                    // update notification
                    Handler.PropNotification ("Tenant successfully assigned to the unit selected!");
                }

                this.AssignTenantUnit = false;
            },

            // update units
            UpdateUpdatedUnits (units) {
                this.PropertyUnits = units;
            }

        }
    };
</script>
<template>
    <div class="admin-property">
        <div class="sidebar admin_s-debar">
            <ul class="admin-menu">
                <li v-for="filter in PropertyFilters" 
                                :key="filter" 
                                :class="filter.active ? 'active' : ''"
                                @click="PropertyFilterHandler(filter)">
                    <p>{{filter.name}}</p>
                </li>  
            </ul>

            <div class="btn-primary" @click="UpdateRent">
                <img src="../../images/icons/addbtn-icon.svg" />
                <p>Add Rent</p>
            </div>

        </div>

        <div class="admin-property-content animated fadeInUp">
            <div class="admin-prop--table">
                <Properties v-for="(property, index) in FilteredOwnerProperties" 
                            :key="property.id" 
                            :property="property" 
                            :owners="OwnerAccounts" :count="index" @choose="PropertyClickHandler" />
                
                <!-- If no data on result -->
                <div v-if="EmptyPropertiesList" class="empty-result animated fadeInUp">
                    <img src="../../images/icons/empty-result.svg" />
                    <p>You dont have much information on this request. <br />Properties will be listed once found on {{ FilterChoosed.name }} list.</p>
                </div>
            </div>
        </div>
    </div>

    <!-- Property detail and Confirm verificaiton -->
    <PropModal v-if="ConfirmDialog == true" :from="'propert-verify'" :data="PropertyData" @modalclose="CloseConformModal" />
    <PropModal v-if="UpdateUnitRent == true" :from="'admin-update-rent'" :data="'null'" @modalclose="CloseUpdateUnitRent" />

</template>

<script>

    // javascript library
    import Document from './../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';

    // component
    import Properties from './../../components/admin/Properties.vue';

    import PropModal from './../../components/modal/PropModal.vue';

    export default {
        name: 'OwnerProperties',
        props: ['userProfile', 'owners'],
        components : {
            Properties,

            PropModal
        },

        data () {
            return {
                PropertyFilters : [
                    {
                        id      : 1,
                        name    : 'All',
                        active  : true,
                    },
                    {
                        id      : 4,
                        name    : 'Verified',
                        active  : false,
                    },
                    {
                        id      : 3,
                        name    : 'Unverified',
                        active  : false,
                    },
                    {
                        id      : 2,
                        name    : 'Pending Verification',
                        active  : false,
                    },
                ],
                FilterChoosed : false,

                AdminProfile : false,

                OwnerAccounts : [],

                OwnerProperties : [],
                FilteredOwnerProperties : [],
                EmptyPropertiesList : false,
                TotalProperties : 0,
                PropertiesKey : false,

                // dialog
                ConfirmDialog : false,
                PropertyData: false,

                // update rent
                UpdateUnitRent: false,
            }
        },

        async mounted () {
            // site title and seo
            Document.DocumentHeadings ({title : 'Dashboard - Properties'});

            // Admin Profile
            this.AdminProfile = this.userProfile;

            // Accounts and Properties
            const admindata = await DataCenter.ProPRequest('owner-properties', true, false);
            if (!admindata) {}else {
                // accounts and count
                this.OwnerProperties = admindata.properties; 
                this.TotalProperties = this.OwnerProperties.length;

                this.OwnerAccounts = admindata.owners;

                // filter
                this.FilterChoosed = this.PropertyFilters[0];
                this.PropertyFilterHandler (this.FilterChoosed);

                // re-render componets with the data
                this.PropertiesKey = true;
            }

        },

        methods: {

            UpdateRent () {
                this.UpdateUnitRent = true;
            },

            CloseUpdateUnitRent () {
                this.UpdateUnitRent = false;
            },

            // filter click
            PropertyFilterHandler (filter) {
                this.PropertyFilters.filter(lter => {
                    lter.active = false;
                    if (filter.id == lter.id) {lter.active = true;}
                })

                this.FilterChoosed = filter;

                // now filter as per clicked
                this.FilteredOwnerProperties = this.OwnerProperties.filter (flter => {
                    if (filter.name == "All") return flter;

                    if (filter.name == "Verified") {
                        if (flter.verified == "true") return flter;
                    }

                    if (filter.name == "Unverified") {
                        if (flter.verified == "false") return flter;
                    }

                    if (filter.name == "Pending Verification") {
                        if (flter.verification == "true") return flter;
                    }
                });

                if (this.FilteredOwnerProperties.length <= 0) this.EmptyPropertiesList = true;
                else this.EmptyPropertiesList = false;
            },

            PropertyClickHandler (property) {                
                // select the owner of this property
                const PropertOwners = this.OwnerAccounts.filter (owner => {
                    if (property.owner == owner.owner) return owner;
                });

                property['ownerprofile'] = PropertOwners[0];

                this.PropertyData = {
                    property : property,
                    owner : PropertOwners[0]
                };

                this.ConfirmDialog = true;

            },

            CloseConformModal (action) {
                if (action != false) {
                    // upadte this account as verified
                    this.OwnerProperties = this.OwnerProperties.filter(prty => {
                        if (action == prty.id) {
                            prty.verified = 'true';
                            prty.verification = 'false';
                        } return prty;
                    })

                    // filter to the Verified section
                    this.PropertyFilterHandler (this.PropertyFilters[1]);
                }else {this.ConfirmDialog = false;}
            },

            VerifyProperty (id) {
                
            }


        }

    };
</script>
<template>
  <div class="admin-accounts">
    <div class="sidebar admin_s-debar">
        <ul class="admin-menu">            
            <li v-for="filter in AccountFilters" 
                                :key="filter" 
                                :class="filter.active ? 'active' : ''"
                                @click="AccountFilterHandler(filter)">
                <p>{{filter.name}}</p>
            </li>            
        </ul>       
    </div>

    <div class="admin-content animated fadeInUp">
        <div class="prop--table">
            <Accounts v-for="(owner, index) in FilteredOwnerAccounts" :key="owner.id" 
                        :owner="owner" 
                        :count="index" 
                        @choose="AccountClickHandler"  />

            <!-- If no data on result -->
            <div v-if="EmptyOwnerList" class="empty-result animated fadeInUp">
                <img src="../../images/icons/empty-result.svg" />
                <p>You dont have much information on this request. <br />Accounts will be listed once found on {{ FilterChoosed.name }} list.</p>
            </div>
            
        </div>
    </div>
</div>

    <!-- Account detail and Confirm verificaiton -->
    <PropModal v-if="ConfirmDialog == true" :from="'account-verify'" @modalclose="CloseConformModal" :data="AccountData" />

</template>

<script>

    // javascript library
    import Document from './../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';

    // component
    import Skeletion from './../../components/blocks/Skeleton.vue';
    import Accounts from './../../components/admin/Accounts.vue'; 

    import PropModal from './../../components/modal/PropModal.vue';

    export default {
        name: 'OwnerAccounts',
        props: ['userProfile', 'owners'],
        components : {
            Skeletion,
            Accounts,
            PropModal
        },

        data () {
            return {
                AccountFilters : [
                    {
                        id      : 1,
                        name    : 'All',
                        active  : true,
                    },
                    {
                        id      : 2,
                        name    : 'Waitlist',
                        active  : false,
                    },
                    {
                        id      : 3,
                        name    : 'Verified',
                        active  : false,
                    },
                    {
                        id      : 4,
                        name    : 'Unverified',
                        active  : false,
                    },
                    {
                        id      : 5,
                        name    : 'Unverified Email',
                        active  : false,
                    },
                ],

                FilterChoosed : false,

                AdminProfile : false,

                OwnerProperties: [],

                OwnerAccounts : [],
                FilteredOwnerAccounts : [],
                EmptyOwnerList : false,
                TotalOwners : 0,
                AccountsKey : false,               

                // dialog
                ConfirmDialog : false,
                AccountData: false,
            }
        },

        async mounted () {
            // site title and seo
            Document.DocumentHeadings ({title : 'Dashboard - Accounts'});

            // Admin Profile
            this.AdminProfile = this.userProfile;

            // Accounts and Properties
            const admindata = await DataCenter.ProPRequest('owner-properties', true, false);
            if (!admindata) {}else {
                // accounts and count
                this.OwnerAccounts = admindata.owners; 
                this.TotalOwners = this.OwnerAccounts.length;

                this.OwnerProperties = admindata.properties; 

                // filter
                this.FilterChoosed = this.AccountFilters[0];
                this.AccountFilterHandler (this.FilterChoosed);

                // re-render componets with the data
                this.AccountsKey = true;
            }
        },

        methods: {
            // filter click
            AccountFilterHandler (filter) {
                this.AccountFilters.filter(lter => {
                    lter.active = false;
                    if (filter.id == lter.id) {lter.active = true;}
                })

                this.FilterChoosed = filter;

                // now filter as per clicked
                this.FilteredOwnerAccounts = this.OwnerAccounts.filter (flter => {
                    if (filter.name == "All") return flter;

                    if (filter.name == "Verified") {
                        if (flter.verified == "true") return flter;
                    }

                    if (filter.name == "Unverified") {
                        if (flter.verified == "false") return flter;
                    }

                    if (filter.name == "Waitlist") {
                        if (flter.verification == "true") return flter;
                    }

                    if (filter.name == "Unverified Email") {
                        if (flter.email_verify == "false") return flter;
                    }
                });

                if (this.FilteredOwnerAccounts.length <= 0) this.EmptyOwnerList = true;
                else this.EmptyOwnerList = false;
            },

            AccountClickHandler (owner) {
                this.ConfirmDialog = true;
                this.AccountData = owner;

                // select the properties of this owner
                const Proprties = this.OwnerProperties.filter (property => {
                    if (property.owner == owner.owner) return property;
                });

                this.AccountData = {
                    properties : Proprties,
                    owner : owner
                };
            },

            CloseConformModal (action) {
                if (action != false) {
                    // upadte this account as verified
                    this.OwnerAccounts = this.OwnerAccounts.filter(lter => {
                        if (action == lter.id) {
                            lter.verified = 'true';
                            lter.verification = 'false';
                        } return lter;
                    })

                    // filter to the Verified section
                    this.AccountFilterHandler (this.AccountFilters[1]);
                }else {this.ConfirmDialog = false;}
            }
        }

    };
</script>
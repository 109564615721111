<template>
    <div class="summery-chart">
        <div class="summery">
            <h3>Last 30 days</h3>
            <p>{{ ticketSummeryCount.total }} tickets in total</p>

            <ul class="smry">
                <li>{{ ticketSummeryCount.open }} open tickets</li>
                <li>{{ ticketSummeryCount.assigned }} assigned</li>
                <li>{{ ticketSummeryCount.closed }} closed tickets</li>
            </ul>
        </div>
        <div class="chart">
            <div id="ticketChartContainer"></div>
        </div>
    </div>

    <div class="fc--header" style="width: calc(100% - 15px);">      
      <div class="fc-more-filter" style="float: right; position:relative">

        <div class="fmf-btn" style="float: right;" @click="FilterTicketByProperty">
          <img src="./../../images/finance/unit.svg" />
          <p>Filter Property</p>
        </div>

        <MultiPropertySelectionVue v-if="MultiPropertySelection" :properties="properties" :style="'top: -15px;left: 85px;'"   
        @lstboxclse="PropertiesAreSelected"/>

      </div>
    </div>

    <!-- Skeleton -->
    <div v-if="tickets === false" style="margin-top:55px">
        <Skeletion :data="4" />
    </div>

    <div v-else class="tc-tickets">
        <div class="prop--table">
            <div class="prop-row row-header">
                <div class="column column25">Requested For</div>

                <div class="column column30">Subject
                    <span @click="SortBySubjectHandler">
                        <img src="../../images/icons/sort-up.svg" v-if="SortBySubject == true" />
                        <img src="../../images/icons/sort-down.svg" v-else-if="SortBySubject == false" />
                        <img src="../../images/icons/sort.svg" v-else />                            
                    </span>
                </div>

                <div class="column column25">Assignee</div>

                <div class="column column20">Status
                    <span @click="SortByStatusHandler">
                        <img src="../../images/icons/sort-up.svg" v-if="SortBySubject == true" />
                        <img src="../../images/icons/sort-down.svg" v-else-if="SortBySubject == false" />
                        <img src="../../images/icons/sort.svg" v-else />                            
                    </span>
                </div>
            </div>
            
            <!-- All ticket list -->
            <div class="prop-row ticketClk transAe" v-for="(ticket, index) in FilteredTicketList" :key="ticket"  :class="index % 2 ? 'odd' : 'even'" @click="tiketclicked(ticket)">
                <div class="column column25">
                    <div v-if="ticket.unit" class="ticket--requested">
                        <div class="image">
                            <ReplaceImage :source="ticket.prop_unit.thumbnail"  />
                        </div>
        
                        <div class="content">
                            <h3 style="font-size: 10pt;">{{ ticket.prop_unit.property }}</h3>
                            <p>{{ ticket.prop_unit.unit }}</p>
                            <p style="font-size: 9pt;line-height: 20pt;color: #8f8f8f;">{{ ticket.created }}</p>
                        </div>
                    </div>

                    <div v-else class="ticket--requested">
                        <div class="image">
                            <UserImage :init="ticket.requested.fullname" :source="ticket.requested.avatar" />
                        </div>
        
                        <div class="content">
                            <h3>{{ ticket.requested.fullname }}</h3>
                            <p>{{ ticket.created }}</p>
                        </div>
                    </div>
                </div>
        
                <div class="column column30">
                    <div class="ticket--subject">
                        <p><strong>#{{ticket.id}}</strong> {{ ticket.subject }}</p>
                        <ul class="ticket-priority">
                            <li v-if="ticket.priority == 'Critical'" class="critical"><img src="../../images/icons/critical.svg" /> <p>{{ticket.priority}}</p></li>
                            <li v-if="ticket.priority == 'High'" class="high"><img src="../../images/icons/high.svg" /> <p>{{ticket.priority}}</p></li>
                            <li v-if="ticket.priority == 'Normal'" class="normal"><img src="../../images/icons/normal.svg" /> <p>{{ticket.priority}}</p></li>
                            <li v-if="ticket.priority == 'Low'" class="low"><img src="../../images/icons/low.svg" /> <p>{{ticket.priority}}</p></li>

                            <li v-if="ticket.moveout == '1'">
                                <img src="./../../images/tickets/moveout.svg" />
                                <p style="color: red">Moveout</p>
                            </li>

                            <li v-if="ticket.tovisit == 'true'">
                                <img src="./../../images/icons/tovisit.svg" />
                                <p>Tenant Visit</p>
                            </li>
                        </ul>             
                    </div>
                </div>
        
                <div class="column column25">
                    <div class="ticket-assignee">
                        <div class="assigneenme" v-for="(assignee) in ticket.assignee" :key="assignee">
                            <h3>{{assignee.fullname}}</h3>

                            <div class="employeehver animated fadeInUp" :key="index">
                                <div class="image">
                                    <UserImage :init="assignee.fullname" :source="assignee.avatar" />
                                    <img src="../../images/icons/favorite.svg" />
                                </div>
                                <div class="content">
                                    <h3>{{assignee.fullname}}</h3>
                                    <p>{{assignee.email}}</p>
                                    <p>{{assignee.phone}}</p>
                                </div>
                            </div>

                        </div>

                        <h3 v-if="ticket.assignee.length < 1" style="color: #ff5722;">Not Assigned</h3>
                    </div>
                </div> 
        
                <div class="column column20">
                    <div v-if="ticket.status == 'Open'" class="status primary">{{ticket.status}}</div>
                    <div v-else-if="ticket.status == 'On Hold'" class="status onhold">{{ticket.status}}</div>
                    <div v-else-if="ticket.status == 'In-Progress'" class="status inprogress">{{ticket.status}}</div>
                    <div v-else class="status closedticket">{{ticket.status}}</div>
                    
                    <p v-if="ticket.expiry == 'Expired' && ticket.status != 'Closed'" style="color: #F00">Past Due</p>
                    <p v-else-if="ticket.expiry == 'Expired' && ticket.status == 'Closed'" style="color: #F00"></p>
                    <p v-else style="color: hsl(120deg 34% 32%)">{{ticket.expiry}}</p>
                </div>
            </div>
            <!-- All ticket list -->

        </div>
    </div>

    <!-- If no data on result -->
    <div v-if="FilteredTicketList.length <= 0" class="emptyskeleton">
        <img src="../../images/icons/empty-result.svg" class="psanim-1 fadeInUp">
        <p class="psanim-2 fadeInUp">You dont have much information on this request. <br />Please add new ticket to see more features.</p>
    </div>
    
</template>

<script>

    // js import
    import Handler from './../../javascript/Handler';

    import Skeletion from './../blocks/Skeleton.vue';
    import ReplaceImage from './../blocks/ReplaceImage.vue';
    import UserImage from './../blocks/UserImage.vue';

    import MultiPropertySelectionVue from '../web-forms/MultiPropertySelection.vue';
    
    export default {
        name: 'OwnerTickets',
        props: ['propselected', 'properties', 'tickets', 'chart', 'category', 'filter'],
        emits: ['ticketClicked', 'propertyfiltered'],
        components: {
            Skeletion,
            ReplaceImage,
            UserImage,

            MultiPropertySelectionVue
        },

        data () {
            return {
                FilteredTicketList : [],

                // last 30 days data
                AllTickets : [],
                ticketCategories : this.category,
                ticketData : this.chart,
                ticketSummeryCount: {
                    total: 0,
                    open: 0,
                    assigned: 0,
                    closed: 0
                },

                // sort functions
                SortByDate : null,
                SortBySubject : null,
                SortByStatus : null,

                // filter by property
                MultiPropertySelection: false,
                PropertiesSelected : [],

                EmptyTicketResult: false,
            }
        },

        mounted () {

            // console.log (this.ticketCategories);

            // active this selected property  
            if (this.PropertiesSelected.length <= 0) {
                this.PropertiesSelected = this.properties;
            }

            // ticket summery and chart count
            this.ticketSummeryCount.total = this.tickets.length;
            var open = 0, closed = 0, assigned = 0;
            for (var i = 0; i < this.tickets.length ; i++) {
                var ticket = this.tickets[i];
                if (ticket.status == 'Open') open++;
                if (ticket.status == 'Closed') closed++;
                if (ticket.isAssigned) assigned++;
            }
            this.ticketSummeryCount.open = open;
            this.ticketSummeryCount.assigned = assigned;
            this.ticketSummeryCount.closed = closed;

            // get started with the highchart data null in the beggining
            this.ticketHighChartHandlers (this.ticketCategories, this.ticketData);  
            
            // filter according the clicked filter menu and load the data
            if (this.tickets)
                this.filterTheList ();

            // now show on priorty basis from high to low
            this.SortBySubject = false;
            this.SortBySubjectHandler ();

        },

        methods: {

            ticketHighChartHandlers (cat, data) {                
                let ticketChart = document.getElementById('ticketChartContainer');
                if (ticketChart) {
                    Highcharts.chart(ticketChart, {
                        chart: {
                            type: 'column'
                        },
                        title: {
                            text: ''
                        },
                        subtitle: {
                            text: ''
                        },
                        xAxis: {
                            categories: cat,
                            crosshair: true
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: ''
                            }
                        },
                        tooltip: {
                            headerFormat: '<span style="font-size:10px">{point.x}</span><table>',
                            pointFormat: '<tr>' +
                                '<td style="padding:0"><b>{point.y} Tickets</b></td></tr>',
                            footerFormat: '</table>',
                            shared: true,
                            useHTML: true
                        },
                        plotOptions: {
                            column: {
                                pointPadding: 0.2,
                                borderWidth: 0
                            }
                        },
                        series: [{
                            name: 'Tickets',
                            showInLegend : false,
                            data: data

                        }]
                    });
                }
            },

            filterTheList () {
                // filter ticket with selected property
                // all tickets initially
                this.FilteredTicketList = this.tickets;

                // selected properties
                this.PropertiesSelected = this.PropertiesSelected.filter (property => {
                    if (property.active) return property;
                });

                // now filter accordintly
                if (this.PropertiesSelected.length == 0) {
                }else {
                    var NewFilter = [];
                    this.FilteredTicketList.forEach (ticket => {
                        this.PropertiesSelected.forEach(property => {
                            var keyword = property.id.toUpperCase();
                            if (ticket.property.toUpperCase().indexOf(keyword) > -1) {
                                NewFilter.push (ticket);
                            }
                        });
                    });

                    this.FilteredTicketList = NewFilter;
                }

                // filter with selected now
                this.FilteredTicketList = this.FilteredTicketList.filter (ticket => {
                    // nothing to worry if need to load all the tickets 
                    if (this.filter == "all") {
                        if (!ticket.isArchive) return ticket;
                    }

                    // now load only the open status tickets
                    if (this.filter == "open") {
                        if (ticket.status == "Open" && !ticket.isArchive) return ticket;
                    }

                    // now load only the closed status tickets
                    if (this.filter == "closed") {
                        if (ticket.status == "Closed" && !ticket.isArchive) return ticket;
                    }

                    // now load only the unassigned tickets
                    if (this.filter == "unassigned") {
                        if (!ticket.isAssigned && !ticket.isArchive) return ticket;
                    }

                    // now load only the favourites tickets
                    if (this.filter == "favourites") {
                        if (ticket.isFavourite && !ticket.isArchive) return ticket;
                    }

                    // onhold tickets
                    if (this.filter == "onhold") {
                        if (ticket.status == "On Hold" && !ticket.isArchive) return ticket;
                    }

                    // inprogress tickets
                    if (this.filter == "inprogress") {
                        if (ticket.status == "In-Progress" && !ticket.isArchive) return ticket;
                    }
                    
                    // archive list
                    if (this.filter == "archive") {
                        if (ticket.isArchive) return ticket;
                    }
                });

                if (this.FilteredTicketList.length == 0) {

                }
            },

            tiketclicked (ticket) {
                this.$emit('ticketClicked', ticket);
            },

            // filter property list click
            FilterTicketByProperty () {
                this.MultiPropertySelection = true;
            },

            // filter the properties by selection
            PropertiesAreSelected (propties) {
                this.MultiPropertySelection = false;
                this.PropertiesSelected = propties;

                // now filter the list
                this.filterTheList ();
            },

            // sort handlers
            SortByDateHandler () {
                // toggle
                this.SortByDate = !this.SortByDate;

                this.FilteredTicketList.sort((a, b) => {
                    var c, d;
                    if (this.SortByDate) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    var aDate = a.created;
                    var aDateStamp = Date.parse(aDate);
                    var bDate = b.created;
                    var bDateStamp = Date.parse(bDate);

                    if (aDateStamp > bDateStamp) return 1;
                    if (aDateStamp == bDateStamp) return 0;
                    if (aDateStamp < bDateStamp) return -1;
                });
            },

            SortBySubjectHandler () {
                // toggle
                this.SortBySubject = !this.SortBySubject;
                this.FilteredTicketList.sort ((a, b) => {
                    var c, d;
                    if (this.SortBySubject) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    if (a.priority > b.priority) return 1;
                    if (a.priority == b.priority) return 0;
                    if (a.priority < b.priority) return -1;
                });
            },

            SortByStatusHandler () {
                // toggle
                this.SortByStatus = !this.SortByStatus;
                this.FilteredTicketList.sort ((a, b) => {
                    var c, d;
                    if (this.SortByStatus) { c = a; d = b} else {d = a; c = b;}
                    a = c; b = d;

                    if (a.status > b.status) return 1;
                    if (a.status == b.status) return 0;
                    if (a.status < b.status) return -1;
                });
            },

            // tooltip
            PropToolTip (todo) {
                var event = event || window.event;
                Handler.ToolTipHandler (todo, event);                
            }

        }
    };
</script>

<style scoped>
div.onhold {background-color: #fff;border: 1px solid #ff5722;color: #f34812;}
div.inprogress {background-color: #fff;border: 1px solid #00bcd4;color: #03a9f4;}
</style>
import Request from './getRequest';
import Storage from './Storage';

const appExtension = ".php";

let hasProperty = (Obj) => {
    return (Obj === false || Obj === undefined || Obj == null || Object.keys(Obj).length <= 0) ? false : true;
};

/**
 * Propstop all dashboard api URL
 * dashboard, properties, user etc
*/

const apiUrl = Object({

    // owner api list *new
    'property-floor-plans'  : 'owner/property/floorplan/list',
    'owner-tickets'         : 'owner/tickets/list',
    'property-gallery'      : 'owner/property/gallery/list',
    'tenant-contracts'      : 'owner/tenant/agreements',
    'owner-tenants-list'    : 'owner/tenant/all',
    'property-tenants-list' : 'owner/tenant/list',
    'owner-floor-plan'      : 'owner/property/floorplan/detail',
    'owner-dashboard'       : 'owner/user/dashboard',
    'owner-profile'         : 'owner/user/profile',
    'parkings-list'         : 'property/parking/list',
    'tenant-invitation'     : 'owner/tenant/invitation',
    'owner-walkghrough'      : 'owner/user/wthupdate',
    'former-tenant-history'     : 'owner/tenant/history-finance',
    'units-payment-info'        : 'owner/finance/bulkunitpay',
    'tenants-payment-info'      : 'owner/finance/tenant-finance',
    'support-messages'      : 'owner/support/list',

    // owner api list
    'owner-welcome'     : 'user/welcome',
    'owner-notify-read' : 'dashboard/read-notification',
    'property-list'     : 'property/list',
    'property-info'     : 'property/info',
    'property-units'    : 'property/units',
    'tenants-list'      : 'tenants/list',
    'employees-list'    : 'employees/list',
    'property-tasks'    : 'property/tasks',
    'ticket-list'       : 'tickets/list',
    'owner-activity'    : 'user/activity',
    'owner-tenant-tickets'    : 'owner/tenant/tickets',
    'owner-employee-tickets'    : 'owner/employee/tickets',

    'employee-tasks'    : 'employees/tasks',

    'tenants-transaction-list'      : 'owner/finance/tenants',
    'property-transaction-list' : 'owner/finance/property',
    'all-property-transaction-list' : 'owner/finance/all-transactions',
    'owner-property-transaction-list'   : 'owner/finance/finance',
    'unit-transaction-list' : 'owner/finance/unitfinance',
    'tenant-transactions' : 'owner/finance/tenantfinance',
    'owner-tenant-rent-history' : 'owner/finance/renthistory',    


    // admin api list
    'admin-profile'         : 'admin/list/admin-profile',
    'owner-properties'      : 'admin/list/owner-properties',
    'disputed-properties'   : 'admin/list/disputed',
    'app-features'          : 'admin/list/features',
    'admin-update-rent'     : 'admin/action/update-rent',
    'admin-dashboard'       : 'admin/list/dashboard',
    'admin-assistance'      : 'admin/list/support',
    'assistance-detail'     : 'admin/list/support-detail',

    // tenant api list
    'tenant-profile'     : 'tenant/user/profile',
    'tenant-dashboard'   : 'tenant/dashboard',
    'tenant-property'    : 'tenant/property',
    'tenant-tickets'     : 'tenant/tickets/list',
    'properties'         : 'tenant/propstop/properties',
    'tenant-property-detail' : 'tenant/propdetail',
    'tenant-activity'    : 'tenant/activity',
    'tenant-notify-read' : 'tenant/user/read-notification',
    'tenant-transaction-list' : 'tenant/finance/unitfinance',
    'tenant-rent-history'   : 'tenant/finance/renthistory',    
    
    // account changes
    'owner-changes'     : 'updates/owner',
    'tenant-changes'    : 'updates/tenant',
    'admin-changes'     : 'updates/admin',
    'employee-changes'  : 'updates/employee',
    
    // employee dashboard api list
    'employee-profile'      : 'employee/user/profile',
    'employee-dashboard'    : 'employee/dashboard',
    'employee-tickets'      : 'employee/tickets/list',
    'employee-units'        : 'employee/property/units',
    'employee-activity'       : 'employee/activity',
    'employee-notify-read'    : 'employee/user/read-notification',
    
    // public apis
    'public-property-detail' : 'public/propdetail',
    'explore-properties'    : 'public/explore-properties',

});

/**
 * Request to the API url or storage if stored
 * @param {*} key       key is the storage key and/or apiUrl Object key to find the api url
 * @param {*} istored   determines if to look the local storage or not, 
 *                      if true it look and also save to storage
*/

const ProPRequest = async (key, istored, getdata) => {
    let ApiUrl = apiUrl[key] + appExtension;
    let Response, ServerResponse, datatosend = '';

    // url paramaters
    if (getdata) {
        datatosend = '?' + getdata.key + '=' + getdata.value;
        ApiUrl += datatosend;
    }
    
    // check to chache or local storage
    if (istored) {
        Response = Storage.getCache (key);
        if (hasProperty(Response)) return Response;
    }

    // now if not in storage request for the server
    Response = await Request.getData(ApiUrl);
    ServerResponse = Response.response;
    if (hasProperty(ServerResponse)) {        
        if (istored) {Storage.storeCache(key, ServerResponse)};
        return ServerResponse;
    }else return false;
};

const ProProDRequest = async (url, getdata) => {
    let ApiUrl = url + appExtension;
    let Response, ServerResponse, datatosend = '';

    // url paramaters
    if (getdata) {
        datatosend = '?' + getdata.key + '=' + getdata.value;
        ApiUrl += datatosend;
    }

    // now if not in storage request for the server
    Response = await Request.getProdData(ApiUrl);
    ServerResponse = Response.response;
    if (hasProperty(ServerResponse)) return ServerResponse;
    else return false;

};

// remove local storage of admin dashboard 
const RemoveAdminStorage = () => {

    Storage.deleteCache ('admin-profile');
    Storage.deleteCache ('owner-properties');

};

// remove local storage of owner dashboard 
const RemoveOwnerStorage = () => {
    Storage.deleteCache ('employees-list');
    Storage.deleteCache ('property-list');
    Storage.deleteCache ('tenants-list');
    Storage.deleteCache ('ticket-list');
    Storage.deleteCache ('parkings-list');
    Storage.deleteCache ('owner-dashboard');
};

// remove local storage of tenant dashboard
const RemoveTenantStorage = () => {
    Storage.deleteCache ('tenant-dashboard');
    Storage.deleteCache ('tenant-profile');
    Storage.deleteCache ('properties');
    Storage.deleteCache ('tenant-property');
    Storage.deleteCache ('tenant-activity');
    Storage.deleteCache ('tenant-tickets');
};

// remove local storage for employee dashboard
const RemoveEmployeeStorage = () => {

};

export default {
    ProPRequest,
    ProProDRequest,
    RemoveAdminStorage,
    RemoveOwnerStorage,
    RemoveTenantStorage,
    RemoveEmployeeStorage,
};


<template>
    <div v-if="count == 0" class="prop-row row-header">
        <div class="column column30">Tenant</div>
        <div class="column column20">Property</div>
        <div class="column column20">Balance</div>
        <div class="column column30">Contact</div>
    </div>
    
    <div class="tenantsel prop-row" :class="count % 2 ? 'odd' : 'even'" @click="TenantClicked(tenant)" style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
        <div class="column column30" style="display: flex;justify-content: center;align-items: center;">
            <div class="unit--tenant" style="display: flex;justify-content: center;align-items: center;">
                <div class="image">
                    <UserImage :init="tenant.fullname" :source="tenant.avatar" />
                </div>

                <div class="content" style="margin-top: 0;">
                    <h3>{{tenant.fullname}}</h3>
                    <p>{{tenant.start_date}}</p>
                </div>
            </div>
        </div>

        <div class="column column20">
            <div v-if="tenant.isRented == 'true'" class="floor-unit">
                <div v-if="tenant.properties.length > 1">
                    <p v-for="property in tenant.properties" style="margin-bottom: 10px;">
                        <span style="display: flex;font-weight: 400;font-size: 10pt;font-weight: bold;">({{ property.propname }})</span>
                        (Unit: {{property.unitnum}})
                    </p>
                </div>

                <div v-else>
                    <p style="font-weight: 500;">{{tenant.properties[0].propname}}</p>
                    <p>Unit: {{tenant.properties[0].unitname}}</p>
                </div>
            </div>

            <div v-else class="floor-unit">
                <span class="unit-notassign" style="margin-top: 0;">Not Assigned</span>
            </div>
        </div>

        <div class="column column20">
            <p v-if="tenant.balance < 0" style="color: #f00;">-${{-tenant.balance}}</p>
            <p style="color: #919191;" v-else>${{tenant.balance}}</p>
        </div>

        <div class="column column30">
            <div class="contact" style="margin-top: 0;">
                <p v-if="tenant.type == 'email'">{{tenant.email}}</p>
                <p v-else style="filter: blur(4px);">xxxxxx@xxxx.xxxx</p>
                
                <p>{{tenant.mobile}}</p>
            </div>
        </div>
        
    </div>
    
</template>

<script>
    //@import javascript module
    import Locale from './../../../javascript/Locale';

    //@import component
    import UserImage from './../../blocks/UserImage.vue';

    export default {
        name: 'TenantList',
        props: ['tenant',  'count'],
        emits: ['select'],
        components: {
            UserImage,
        },

        data () {
            return {
                FloorName : Locale.FloorNumName()
            }
        },

        methods: {
            TenantClicked (tenant) {
                this.$emit('select', tenant);
            }
        }
    };
</script>

<style scoped>
    div.tenantsel {cursor: pointer;}
    span.unit-notassign {background-color: #fbfbfb;border: 1px solid #e5e5e5;color: #898989;padding: 7px;border-radius: 35px;display: block;width: 90px;text-align: center;font-size: 9pt;letter-spacing: 0.15px;margin-top: 10px;}
</style>
<template>
    <div class="admin-dashboard _propscrl" :style="{'background-image':'url('+require('../../images/company/loading-bg.jpg')+')'}">
        <div v-if="isRequesting" style="width: 50%;">
            <Skeletion :type="'user-list'" :data="1" />
        </div>

        <div v-else class="user">
            <div class="avatar">
                <UserImage :init="AdminProfile.fullname" :source="AdminProfile.avatar" />
            </div>
            <div class="content">
                <h2>Hi, {{AdminProfile.fname}}</h2>
            </div>
        </div>

        <div class="fin-summery" style="margin-top: 30px;">
            <ul>
                <li>
                    <div class="image" style="background: #fd686b;">
                        <img style="width: 32px; height: 32px;" src="./../../images/admin/property.svg" />
                    </div>
                    <div class="content">
                        <p style="font-size: 11pt;">Properties / Units</p>
                        <h3 style="margin-top: 10px;">
                            <span style="font-size: 14pt;font-weight: 600;">{{ Counter.properties }} /</span>
                            <span class="big" style="font-size: 14pt;">{{ Counter.units }}</span>
                        </h3>
                    </div>
                </li>

                <li>
                    <div class="image" style="background: #209cff;">
                        <img style="width: 32px; height: 32px;"  src="./../../images/admin/users.svg" />
                    </div>
                    <div class="content">
                        <p style="font-size: 11pt;">Owners / Tenants</p>
                        <h3 style="margin-top: 10px;">
                            <span style="font-size: 14pt;font-weight: 600;">{{ Counter.owners }} /</span>
                            <span class="big" style="font-size: 14pt;">{{ Counter.tenants }}</span>
                        </h3>
                    </div>
                </li>

                <li>
                    <div class="image" style="background: #ff8818;">
                        <img style="width: 32px; height: 32px;"  src="./../../images/admin/employee.svg" />
                    </div>
                    <div class="content">
                        <p style="font-size: 11pt;">Employees</p>
                        <h3>{{ Counter.employees }}</h3>
                    </div>
                </li>

                <li>
                    <div class="image" style="background: #fd686b;">
                        <img style="width: 32px; height: 32px;"  src="./../../images/admin/tickets.svg" />
                    </div>
                    <div class="content">
                        <p style="font-size: 11pt;">Tickets</p>
                        <h3>{{ Counter.tickets }}</h3>
                    </div>
                </li>
            </ul>
        </div>
        
        <div class="admin-dash">            
            <div class="recent-prop">
                <h3 style="margin-bottom: 0;">Properties ({{ OwnerProperties.length }}) <span @click="ShowAllAccounts">See All</span></h3>
                <p>Verified vs Unverified properties listed by owners</p>

                <ul class="tasksum" style="margin-top: 5px;">
                    <li>
                        <div class="taskper">
                            <div class="task-percentage transAe" :style="'width: '+ PropertiesPer +'%;'"></div>
                        </div>            
                    </li>
                </ul>
                
                <div v-if="isRequesting" style="margin-top: 45px;">
                    <Skeletion :type="'user-list'" :data="4" />
                </div>
                <DashboardProperties v-else :properties="OwnerProperties" :key="PropertyKey" @choose="PropertyClickHandler"  />
            </div>

            <div class="recent-acc">
                <h3 style="margin-bottom: 0;">Owner Accounts ({{ Counter.owners }}) <span @click="ShowAllProperties">See All</span></h3>
                <p>Verified vs Unverified property owner accounts</p>

                <ul class="tasksum" style="margin-top: 5px;">
                    <li>
                        <div class="taskper">
                            <div class="task-percentage transAe" :style="'width: '+ OwnerPer +'%;'"></div>
                        </div>            
                    </li>
                </ul>

                <div v-if="isRequesting" style="margin-top: 45px;">
                    <Skeletion :type="'user-list'" :data="5" />
                </div>
                <DashboardAccounts v-else :accounts="OwnerAccounts" :key="AccountsKey" @choose="AccountClickHandler" />
            </div>
        </div>
    </div>

    <!-- Modals -->
    <PropModal v-if="AccountVerifyDialog == true" :from="'account-verify'" :data="AccountData" @modalclose="CloseAccountConformModal" />
    <PropModal v-if="PropertyVerifyDialog == true" :from="'propert-verify'" :data="PropertyData" @modalclose="CloseConformModal" />

</template>

<script>
    // javascript library
    import Document from './../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';

    // component
    import Skeletion from './../../components/blocks/Skeleton.vue';
    import UserImage from './../../components/blocks/UserImage.vue';

    import DashboardAccounts from './../../components/admin/DashboardAccounts.vue';
    import DashboardProperties from './../../components/admin/DashboardProperties.vue';

    import PropModal from './../../components/modal/PropModal.vue';

    export default {
        name: 'Dashboard',
        props: ['userProfile', 'owners'],
        components: {
            Skeletion,
            UserImage,

            DashboardAccounts,
            DashboardProperties,

            PropModal
        },

        data () {
            return {
                AdminProfile : false,

                OwnerAccounts : [],
                AllOwners: [],
                TotalOwners : 0,
                AccountsKey : false,

                OwnerProperties : [],
                TotalProperties: 0,
                PropertyKey : false,

                AccountVerifyDialog : false,
                PropertyVerifyDialog : false,
                AccountData : [],
                PropertyData: [],

                UnverifiedProperties: 0,
                UnverifiedOwners: 0,
                Counter: {
                    properties: 0,
                    units: 0,
                    owners: 0,
                    tenants: 0,
                    employees: 0,
                    tickets: 0,
                },
                PropertiesPer: 0,
                OwnerPer: 0,
                isRequesting : true,
            }
        },

        async mounted () {

            // site title and seo
            Document.DocumentHeadings ({title : 'Dashboard - PropStop'});

            // Admin Profile
            this.AdminProfile = this.userProfile;

            // Accounts and Properties
            const admindata = await DataCenter.ProPRequest('admin-dashboard', false, false);
            if (!admindata) {}else {

                // update counters
                this.Counter = admindata.conter;
                
                // accounts and count                
                const Owners = admindata.owners;
                this.AllOwners = Owners;
                Owners.forEach(owner => {
                    if (owner.verified == 'false') {
                        this.OwnerAccounts.push(owner);
                        this.TotalOwners++;
                    }
                });

                this.UnverifiedOwners = this.OwnerAccounts.length;
                this.TotalOwners = this.Counter.owners;
                this.OwnerPer = Math.floor((this.UnverifiedOwners / this.TotalOwners) * 100);
                
                // properties & count
                const Properties = admindata.properties;
                Properties.forEach(property => {
                    if (property.verification == 'true') {
                        this.OwnerProperties.push(property);
                        this.TotalOwners++;
                    }
                });
                this.UnverifiedProperties = this.OwnerProperties.length;
                this.TotalProperties = this.Counter.properties;
                this.PropertiesPer = Math.floor((this.UnverifiedProperties / this.TotalProperties) * 100);

                this.isRequesting = false;

                // re-render componets with the data
                this.AccountsKey = true;
                this.PropertyKey = true;
            }
        },

        methods: {
            ShowAllProperties () {
                document.getElementById('pnav_Accounts').click();
            },

            ShowAllAccounts () {
                document.getElementById('pnav_Properties').click();
            },

            AccountClickHandler (owner) {
                this.AccountVerifyDialog = true;
                this.AccountData = owner;

                // select the properties of this owner
                const Proprties = this.OwnerProperties.filter (property => {
                    if (property.owner == owner.owner) return property;
                });

                this.AccountData = {
                    properties : Proprties,
                    owner : owner
                };
            },

            PropertyClickHandler (property) {
                this.PropertyVerifyDialog = true;

                this.PropertyData = {
                    property : property,
                };

            },

            CloseConformModal () {
                this.AccountVerifyDialog = false;
                this.PropertyVerifyDialog = false;
            },

            CloseAccountConformModal (action) {
                this.AccountVerifyDialog = false;
                if (action.task == 'delete') {
                    var NewAccounts = this.OwnerAccounts.filter(owner => {
                        if (owner.id != action.owner.id) return owner;
                    });

                    this.OwnerAccounts = NewAccounts;
                }
            }
        }

    };
</script>
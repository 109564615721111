import { defineStore } from "pinia";

export const useOwnerStore = defineStore ({
    id: 'owner',

    // reactive state owner data
    state: () => ({

        user: {},
        route : {
            page: 'Dashboard',
            params: null,
        },

        walkthrough: {
            property: {},
            tickets: {},
            tenants: {},
            employees: {},
            finance: {}
        },

        dashboard: {},

        properties: [],
        propselected: {},
        
        tenants: [],
        employees: [],

        tickets: [],

        finance: {
            properties: [],
            transactions: [],
            profitloss: [],
        },

        financepaymentdata: {
            type: 'choose',
            isMainPage: true,
            tenant: false,
            property: false,
            unit: false,
            whichProperty: {},
            whichUnit: {},
            whichTenant: {}
        },

        conversations: [],

    }),

    // methods
    actions: {

        ResetUser (user) {
            this.user = user;
        },

        ResetDashboard (dashboard) {
            this.dashboard = dashboard;
        },

        ResetProperties (properties) {
            this.properties = properties;
        },

        ResetTenants (tenants) {
            this.tenants = tenants;
        },

        ResetEmployees (employees) {
            this.employees = employees;
        },

        ResetTickets (tickets) {
            this.tickets = tickets;
        },

        ResetFinancePaymentData () {
            this.financepaymentdata = {
                type: 'choose',
                isMainPage: true,
                tenant: false,
                property: false,
                unit: false,
                whichProperty: {},
                whichUnit: {},
                whichTenant: {}
            }
        },

        ResetConversations (conversations) {
            this.conversations = conversations
        }

    }
});
<script>

      import Document from '../../javascript/Document';
      import DataCenter from '../../javascript/DataCenter';
      import Request from './../../javascript/getRequest';

      import UserImage from '../../components/blocks/UserImage.vue';
      import Skeleton from '../../components/blocks/Skeleton.vue';

      export default {
            name: 'UserAssistance',
            props: ['userProfile', 'owners'],
            components: {
                  UserImage,
                  Skeleton,
            },
            
            data () {
                  return {
                        Requesting: true,
                        detailRequesting: false,
                        SupportThreads: [],
                        SupportDetailThreads: [],
                        ThreadSelected: {},
                        SupportDetail: false,

                        Message: '',
                  }
            },


            async mounted () {


                   // site title and seo
                  Document.DocumentHeadings ({title : 'User Assistance'});

                  const Threads = await DataCenter.ProPRequest('admin-assistance', false, false);
                  if (Threads) {
                        this.Requesting = false;
                        this.SupportThreads = Threads;

                        // auto select the first thread
                        this.ThreadClickedHandler (this.SupportThreads[0]);
                  }


            },

            methods: {

                  async ThreadClickedHandler (thrd) {
                        this.SupportDetailThreads = [];
                        this.ThreadSelected = this.SupportThreads.find(thread => thread.id == thrd.id);
                        this.SupportThreads.map(thread => {
                              if (thread.id == thrd.id) {
                                    thread.active = true;
                                    thread.unread = false;
                              }else thread.active = false;
                              return thread;
                        });

                        // now load the detail of the clicked thread
                        this.detailRequesting = true;
                        const Detail = await DataCenter.ProPRequest('assistance-detail', false, {key: 'thread', value: thrd.id});
                        if (!Detail) {}else this.SupportDetailThreads = Detail;
                        this.detailRequesting = false;
                  },

                  async SendMessage () {
                        if (this.Message.length <=3) return;

                        let formData = new FormData();
                        formData.append('thread', this.ThreadSelected.id);
                        formData.append('message', this.Message);
                        formData.append('owner', this.ThreadSelected.user.id);
                        formData.append('email', this.ThreadSelected.email);

                        let newThread = {
                              id: Math.round(new Date()/1000),
                              admin: 'true',
                              message: this.Message,
                              created: 'Just Now',
                        };

                        // update to state
                        if (this.SupportDetailThreads.length == 0) this.SupportDetailThreads[0] = newThread;
                        else this.SupportDetailThreads.push(newThread);

                        // blank the message box
                        this.Message = '';

                        // post to server
                        await Request.postServer('admin/action/update-thread.php', formData);

                  }

            }

      }

</script>

<template>
      <!-- Skeleton -->
      <div v-if="Requesting" style="width: 50%;display: flex;margin: 0px auto;margin-top: 25%;">
            <Skeleton :type="'user-list'" :data="2" />
      </div>

      <div v-else class="admin-assistance">
            <ul class="conversation">
                  <li v-for="thread in SupportThreads" 
                        :key="thread" 
                        :class="thread.active ? 'active' : ''"
                        @click="ThreadClickedHandler(thread)">

                        <div class="image">
                              <UserImage :init="thread.user.fullname" :source="thread.user.avatar" />
                        </div>
                        <div class="content">
                              <h3>{{ thread.user.fullname }}</h3>
                              <p>{{ thread.topic }}</p>
                        </div>
                        <div v-if="thread.unread == 'true'" class="unread">
                              <span>1</span>
                        </div>
                  </li>
            </ul>
            
            <div class="ticket-details-content">
                  <div class="lft-sid">
                        <div class="lft-heading">
                              <div class="headngs" style="width: calc(100% - 60px);">
                                    <p>{{ ThreadSelected.topic }}</p>
                                    <p class="nam-dtl"><a href="#">{{ ThreadSelected.user.fullname }}</a> {{ ThreadSelected.created }}</p>
                              </div>
                              <div class="ticket-setting">
                                    <img src="../../images/icons/ticket-setting.svg">
                              </div>
                        </div>

                        <div class="ticket-summery _propscrl">

                        <!-- Ticket details -->
                        <ul class="ticket-threads">
                              <li>

                                    <div>
                                          <div class="image">
                                                <div class="avatar">
                                                      <UserImage :init="ThreadSelected.user.fullname" :source="ThreadSelected.user.avatar" />
                                                </div>
                                          </div>

                                          <div class="content">
                                                <div class="user">
                                                      <h3>{{ ThreadSelected.user.fullname }}</h3>
                                                      <p>{{ ThreadSelected.created }}</p>
                                                </div>

                                                <div class="message">
                                                      <p>{{ ThreadSelected.message }}</p>                                                
                                                </div>
                                          </div>
                                    </div>
                              </li>

                              <!-- Skeleton -->
                              <div v-if="detailRequesting" style="margin-top:55px">
                                    <Skeleton :type="'large-list'" :data="1" />
                              </div>

                              <div v-else>
                                    <li v-for="detail in SupportDetailThreads" :key="detail.id">
                                          <div>
                                                <div class="image">
                                                      <div class="avatar" v-if="detail.admin == 'true'">
                                                            <UserImage :init="userProfile.fullname" :source="userProfile.avatar" />
                                                      </div>
                                                      <div class="avatar" v-else>
                                                            <UserImage :init="ThreadSelected.user.fullname" :source="ThreadSelected.user.avatar" />
                                                      </div>
                                                </div>

                                                <div class="content">
                                                      <div class="user">
                                                            <h3 v-if="detail.admin == 'true'">{{ userProfile.fullname }}</h3>
                                                            <h3 v-else>{{ ThreadSelected.user.fullname }}</h3>
                                                            <p>{{ detail.created }}</p>
                                                      </div>

                                                      <div class="message">
                                                            <p>{{ detail.message }}</p>                                                
                                                      </div>
                                                </div>
                                          </div>
                                    </li>
                              </div>
                        </ul>
            </div>

            <div class="ticket-reply">

                <div class="mesz-input">
                    <textarea v-model="Message" placeholder="Type a assistance reply here.."></textarea>
                </div>
                <div class="mesz-actions">
                    <ul class="mesz-buttons">
                        <li @click="SendMessage" :style="Message.length >= 3 ? '' : 'cursor: not-allowed;background-color: #b3b3b3;'"><p>Send</p></li>
                    </ul>
                </div>
            </div>
            
        </div>

        <div class="rht-sid" style="overflow: hidden;">
            <h3>Support Information</h3>

            <p>Status</p>
            <p class="des-bld">Open</p>

            <p>Requested Page</p>
            <p class="des-bld">{{ ThreadSelected.title }}</p>
            <a :href="ThreadSelected.url" class="des-bld" style="color: #449adf;word-break: break-word;">{{ ThreadSelected.url }}</a>

            <p>Cateogry</p>
            <p class="des-bld">{{ ThreadSelected.category }}</p>

            <p>Email Reply</p>
            <p v-if="ThreadSelected.email == 'true'" class="des-bld">Yes</p>
            <p v-else class="des-bld">No</p>
        </div>

    </div>
      </div>
</template>

<style>

div.admin-assistance {    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;}
div.admin-assistance ul.conversation {     width: 300px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-right: 1px solid #dbdbdb;
    gap: 5px;}
div.admin-assistance ul.conversation li {     display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 15px;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid #e1e1e1;
    padding: 15px 10px;}
    div.admin-assistance ul.conversation li.active {background-color: #e1e1e161;}
    div.admin-assistance ul.conversation li:hover {background-color: #e1e1e161;}
div.admin-assistance ul.conversation li div.image {    width: 45px;
    overflow: hidden;
    height: 45px;
    border-radius: 50%;}
div.admin-assistance ul.conversation li div.image img {    width: 100%;
    height: 100%;
    object-fit: cover;}
div.admin-assistance ul.conversation li div.content {    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;}
div.admin-assistance ul.conversation li div.content h3 {    color: black;
    font-size: 12pt;}
div.admin-assistance ul.conversation li div.content p {    font-size: 10pt;}
div.admin-assistance ul.conversation li div.unread {    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 15px;}
div.admin-assistance ul.conversation li div.unread span {    width: 25px;
    height: 25px;
    display: block;
    background-color: #007fff;
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgb(3 169 244 / 46%);
    color: white;
    text-align: center;
    font-size: 12pt;
    font-weight: 600;
    line-height: 20pt;}

div.admin-assistance div.conmessage {}
div.conmessage div.mezdetail {}
div.conmessage div.mezsummery {}


</style>
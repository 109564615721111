<template>
    <li @click="EmployeeClicked(employee)">
        <div class="content">
            <div class="image">
                <UserImage :init="employee.fullname" :source="employee.avatar" />
            </div>

            <div class="contact">
                <h3>{{employee.fullname}} <strong style="display: inline;font-size: 9pt;font-weight: 500;letter-spacing: 0.25px;background-color: #e9e9e9;padding: 2px 9px;color: #505050;border-radius: 7px;margin-left: 3px;">{{employee.assignments[0].role}}</strong></h3>
                <p>
                    <span>{{employee.date}}</span>
                    <span>{{employee.email}}</span>
                    <span>{{employee.phone}}</span>
                </p>
            </div>
        </div>

        <ul class="hover"></ul>
    </li>
</template>

<script>
    //@import component
    import UserImage from './../../blocks/UserImage.vue';

    export default {
        name: 'EmployeeList',
        props: ['employee'],
        emits: ['select'],
        components: {
            UserImage,
        },

        methods: {
            EmployeeClicked (employee) {
                this.$emit('select', employee);
            }
        }
    };
</script>
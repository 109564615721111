<template>
  <!-- Owner home or Dashboard -->
  <div class="owner-dashboard _propscrl" :style="{'background-image':'url('+require('../../images/company/loading-bg.jpg')+')'}">
    
        <div v-if="!profile" style="width: 50%;">
            <Skeletion :type="'user-list'" :data="1" />
        </div>
      
        <div v-else class="user">
            <div class="avatar">
                <UserImage :init="profile.fullname" :source="profile.avatar" />
            </div>

            <div class="content">
                <h2>Hi, {{profile.fname}}</h2>
            </div>
        </div>

      <div class="owndashboard">
          <div class="large-charts">
              <div class="dash-finance">
                  <Finance :requesting="isRequesting" :key="isRequesting" />
              </div>

              <div class="dash-activity" style="overflow: hidden;position: relative;">
                    <div class="header">
                        <h3>Activities</h3>
                        <span @click="MarkasAllRead">Mark all as Read</span>
                    </div>
                
                    <RecentActivity :data="RcntActivity" :requesting="isRequesting" :key="isRequesting" />
              </div>
          </div>

          <ul class="medium-charts">
              <li>
                <div class="header">
                    <h3>Tasks Summary</h3> 
                    <p v-if="TaskSummery.open == 0">No open tasks found for you to resolve.</p>                              
                    <p v-else>A total of {{TaskSummery.open}} open tasks are waiting for you to resolve.</p>                              
                </div>
                <Tasks :data="TaskSummery" :key="TaskSummery" />
            </li>

            <li>
                <div class="header">
                    <h3>Properties ({{PropertyData.property}})</h3>                                 
                    <span @click="RouteToPage ('owner-properties')">All Properties</span>
                </div>

                <div class="content">
                    <Properties :data="PropertyData" :key="PropertyData" />
                </div>
            </li>

            <li>
                <div class="header">
                    <h3>Tickets Summary</h3>
                    <span @click="RouteToPage ('owner-tickets')">All Tickets</span>
                </div>

                <div class="content">
                    <Tickets :data="TicketSummery" :key="TicketSummery" />
                </div>
            </li>
          </ul>
      </div>

  </div>
  <!-- Owner home or Dashboard -->

  <!-- new property created -->
  <PropModal v-if="NewPropertyCreated == true" :from="'property-created'" @modalclose="CloseNewPropertyModal" />
  
</template>

<script>

    // @ is an alias to /src
    import Document from '../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';

    // pinia
    import { useOwnerStore } from '../../stores/OwnerStore';

    import Skeletion from './../../components/blocks/Skeleton.vue';
    import ReplaceImage from '../../components/blocks/ReplaceImage.vue';
    import UserImage from './../../components/blocks/UserImage.vue';

    // dashboard components
    import Finance from './../../components/owner/dashboard/Finance.vue';
    import RecentActivity from './../../components/owner/dashboard/RecentActivity.vue';
    import Properties from './../../components/owner/dashboard/Properties.vue';
    import Tasks from './../../components/owner/dashboard/Tasks.vue';
    import Tickets from './../../components/owner/dashboard/Tickets.vue';

    // Modal
    import RightModal from './../../components/modal/RightModal.vue';
    import PropModal from './../../components/modal/PropModal.vue';

    export default {
        name: 'Dashboard',
        props: ['profile', 'properties', 'employees', 'selected'],
        emits: ['updateproperties'],

        components: {
            Skeletion,
            ReplaceImage,
            UserImage,

            // dashboard components
            Finance,
            RecentActivity,
            Properties,
            Tasks,
            Tickets,

            RightModal,
            PropModal,
        },

        data () {
            return {                
                DashboardInformation : false,

                // charts data
                RcntActivity : [],
                TaskSummery : false,
                PropertyData: false,
                TicketSummery: false,

                // recent activity loaded
                rctyload : false,
                isRequesting: true,

                NewPropertyCreated : false,
                OwnerStore: useOwnerStore(),
            }
        },

        methods: {

            // mark as all read notificaiton
            async MarkasAllRead () {
                if (this.RcntActivity) {
                    // mark as read from dom
                    this.RcntActivity = this.RcntActivity.filter (activity => {
                        activity.unread = 'false'; return activity;
                    });

                    // mark as unread from sql server
                    await DataCenter.ProPRequest('owner-notify-read', false, false);
                }

                this.rctyload = true;
            },

            RouteToPage (page) {
                // change the route to profile page
                this.$router.push({name : page});
            },

            CloseNewPropertyModal () {
                this.NewPropertyCreated = false;
            },

        },

        async mounted () {

            // pinia setup
            const OwnerStore = useOwnerStore ();

            // check if owner has propeties inactive
            let hasInactive = this.OwnerStore.properties.some(property => !property.active);
            let createdDays = this.OwnerStore.properties.some(property => property.created_days <= 1 ? true : false);
            if (hasInactive && createdDays) this.NewPropertyCreated = true;

            // show back the properties selection from here
            var OwnPrty = document.getElementById('ownerprtities');
            if (OwnPrty != null || OwnPrty != undefined) {
                OwnPrty.style.visibility = 'visible';
                OwnPrty.style.opacity = '1';
            }

            // site title and seo
            Document.DocumentHeadings ({title : 'Dashboard - PropStop'});

            // property and units data
            this.PropertyData = {
                property : 0,
                total : 0,
                occupied : 0,
                vacant : 0,
                maintenance : 0
            };

            // tasks summery data
            this.TaskSummery = {
                total : 0,
                newtask : 0,
                open : 0,
                completed : 0
            };

            // tickets summery data
            this.TicketSummery = {
                resolved : 0,
                open : 0,
                assigned : 0,
                created : 0
            };

            // now load data from server
            // finance, owner info, recent activity, tasks summery, property unit data, tickets summery
            // load listing data from server vs storage
            if (Object.keys(OwnerStore.dashboard).length === 0) {
                const RequestData = await DataCenter.ProPRequest('owner-dashboard', false, false);
                if (!RequestData) {} else {
                    this.DashboardInformation = RequestData;
                    this.PropertyData = this.DashboardInformation.property;
                    this.TaskSummery = this.DashboardInformation.tasks;            
                    this.TicketSummery = this.DashboardInformation.tickets;
                    this.RcntActivity = this.DashboardInformation.activity;

                    // pinia upadte
                    OwnerStore.dashboard = this.DashboardInformation;

                    this.rctyload = true;
                    this.isRequesting = false;
                }
            }else {

                this.DashboardInformation = OwnerStore.dashboard;
                this.PropertyData = this.DashboardInformation.property;
                this.TaskSummery = this.DashboardInformation.tasks;            
                this.TicketSummery = this.DashboardInformation.tickets;
                this.RcntActivity = this.DashboardInformation.activity;

                this.rctyload = true;
                this.isRequesting = false;
            }
        },
        
    }

</script>
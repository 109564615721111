<template>
    <ul class="recent-accounts _propscrl">
        <li v-for="account in accounts" :key="account" @click="ClickHandler (account)">
            <div class="image">
                <UserImage :init="account.fullname" :source="account.avatar" />
            </div>
            <div class="acc-dtls">
                <h3>{{ account.fullname }}</h3>
                <p>{{ account.email }}</p>
                <span v-if="account.verified == 'false'" style="color:#ff5722">(Unverified)</span>
            </div>
        </li>
    </ul>
</template>

<script>    
    // components
    import UserImage from './../blocks/UserImage.vue';
    export default {
        name: 'DashboardAccounts',
        props: ['accounts'],
        emits: ['choose'],
        components: {
            UserImage
        },
        
        methods: {
            ClickHandler (owner) {
                this.$emit('choose', owner);
            }
        }
    };
</script>
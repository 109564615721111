<template>
      <div class="mp-content">
            <div class="header">
                  <div class="logo">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAABNCAYAAADuF5n3AAAABHNCSVQICAgIfAhkiAAABQ5JREFUeF7tm31M1VUYx7834CIWoGRlRsAEspsNjZmQsFaWpUJciEA0ylqRKRgvsTHJXuhl5laEQ2C8R7ZlWorBwK0tLQysPyogMHQZdivBBJQAIRD6nQM6cFfu77m718XleQbbZff7nPPbh+85v7PznKNZEvDiCDhUEdAwLFWcpIhhqWfFsAisGBbDohAgaHnOYlgEAgQpO+v/AGv7tjgsvVcHJyct4XHMlw4ODqG9vQsJidloa+s0v6FJMq3irLKSNCxYcLtVHthUoz09FxCqfwX9/f+akpK/tzisp2JXIH6jnvwglkyoq2tGcmquJZu0zgo+LycJ9yz2sfiDUho0GP5GVEwGJUWV1uLOys9LxiI/b1WdW0v0519nERn1hsWbZ1gEpFaFVVxajV0ff0l4HPOlPt7zUFSQKhuYks7KL6hEadlB8wkQMn19bsOusi0MSw0zhqWG0piGYTEsAgGClJ3FsAgECFJ2FsMiECBI2VkMi0CAIGVnMazJCWg0Gig/GB4ePYLh4GAvt3wGh4ZQX//rVZOnnbOClt2N5MRICSQ7pxxff1OPm+a4ouLAO+jo6EZIWDrD8vC4GSlJUQgM0EHscgp3ubvPQW1dEz7Z/RWyd2xmWI6ODkjYFA59WBBGRkaQm3cA+8qPyM/RUQ/ghedD5DC0s7tuesMK1wcpMELh5uaMiso6FBRWQsxSYhgqrJCZtRfK7IX4TXqsXhWAgYFBvJe5R2qNhU3OWbo7PZD40uNYvMgHLccNyNrxORoaT2JN9IN4dv2jcHaeKVn09vaj5MNq7P70EBbe5YXUl6Nxh687GhpO4n0FZEuLYQIzm4Ll4jJTDrmQ1YHo6b0gnbRfGXL3BS7E5vhweHnNNeqY1tY2OdnXHW1CRHgw4p4LkUC/qKhFvtLGuXM9Ms+mYJWVpsF7/jzs21+DwuIqzJ51g3SYeAOqiZqaRuzMLUdnVzc2xD2GJyLvx/ETf+DpZ961PVj+/r7o6vwHvylOWbVyKbakrYNWa6+G02WNKNGnby1GzZFGeHreghvdXPDDjydsD9Z4KsJRa9csJ4G6JM5R3pjGKkY2NQyNwerrG8DR75ovf6XTeeLWuW7yb/H2+7b2Z/lZq3VAcNDocJ22sK4ss29Nj0VoSKCE0n6mC/qIV+Vnt9nOqKrcxrDGn0lgWEaKrJfmLHbWuIMhV6tIq5ngeRiOTeUMawzE+CNH7CwTqyM1sJycHDFjxuRnTYcvDuN8d6/sTWzZzFJW/CL6+vrlsuLKsNl1llmrURNJDItAlWExLAIBgpSdxbAIBAhSdhbDIhAgSNlZDItAgCCd8s5qamqV5a5rEa6u1+Oh5f6yqyl5aeBaQDLWx7SA1XzsFFJS8yDuDCYplekIfbAs3VPD5mGJEtnaJ9+ewCVBKb7GrnuYysr2h2GRUngtKqmaAEaU7YsLRy8vUWLKOCs3OxGioEqNPXsPK4dBPpuQJo4gZWXGU5vC74YziI55k5xnKsHiV+geWbEEGa+vlxt2lBAbejHKMDx9ukOmiblKXIkTB0moUX3we2S89RE1zaTe4rBEjwV5KfDzm2+yc2OCQ4d/wtmO8wgLXQZxfosa4uZ9eORr1DRVeqvAEj2LS+XiQJq9vZ2qBzFXJBx4UdmCFr/HfjmFDRs/MLcpk3lWg2Wy5ykoYFiEfxrDYlgEAgQpO4thEQgQpOwshkUgQJCysxgWgQBBys5iWAQCBOl/CG43tPlKYzkAAAAASUVORK5CYII=" alt="">
                  </div>
                  <h3>Welcome to PropStop!</h3>
                  <p>Our dedicated team is on standby 24/7/365, ensuring you receive the help you need, whenever you need it.</p>
            </div>
            
            <div class="conthreads">
                  <div v-if="isRequesting" class="loder">
                        <Skeleton :type="'line'" :data="2" />
                  </div>

                  <ul v-else>
                        <li v-for="message in UserConversations" :key="message.id" 
                                    @click="OpenPreviousConversation(message)" 
                                    :style="message.unread >= 1 ? 'background-color: #2196f31a;': ''">
                              <h3>{{ message.topic }}</h3>
                              <div class="startbtn">
                                    <p>{{ message.created }}</p>
                                    <img src="./../../images/icons/arrow-right-blue.svg" />
                                    <span v-if="message.unread >= 1">{{ message.unread }}</span>
                              </div>
                        </li>

                        <li @click="StartNewConversation()">
                              <h3>We are online</h3>
                              <div class="startbtn">
                                    <p>Create new conversation</p>
                                    <img src="./../../images/icons/arrow-right-blue.svg" />
                              </div>
                        </li>
                  </ul>
            </div>
      </div>
</template>

<script>

      // pinia
      import { useOwnerStore } from "./../../stores/OwnerStore";

      import DataCenter from "../../javascript/DataCenter";

      import Skeleton from "./../blocks/Skeleton.vue";

      export default {
            name: 'MainPage',
            props: ['user',],
            emits: ['start', 'open'],
            components: {
                  Skeleton,
            },

            data () {
                  return {
                        OwnerStore: useOwnerStore (),
                        UserConversations: [],
                        isRequesting: true,
                  }
            },

            async mounted () {

                  console.log (this.OwnerStore.conversations);

                  // show previous conversation
                  // first load from the server all the conversations
                  if (this.OwnerStore.conversations.length == 0 || !this.OwnerStore.conversations) {
                        this.isRequesting = true;
                        let Conversations = await DataCenter.ProPRequest('support-messages', false, false);

                        if (!Conversations || Conversations.length == 0) Conversations = [];
                        
                        this.OwnerStore.ResetConversations(Conversations);
                        this.UserConversations = this.OwnerStore.conversations;
                  }else {
                        this.UserConversations = this.OwnerStore.conversations;
                  }

                  this.isRequesting = false;
            },

            methods: {

                  StartNewConversation () {
                        this.$emit('start');
                  },

                  OpenPreviousConversation (message) {
                        this.$emit('open', message);
                  }

            }
      }

</script>

<style>

      div.mp-content {    display: flex;
    flex-direction: column;
    height: calc(100% - 30px);
    padding: 15px;
    background-color: #f5f5f5;
    justify-content: space-between; border-radius: 15px;}
      div.mp-content div.header {    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
    align-items: flex-start;}
      div.mp-content div.header div.logo {    width: 55px;
    height: 55px;
    overflow: hidden;
    border-radius: 50%;
    margin: 15px;
    margin-left: 0;}
      div.mp-content div.header div.logo img {    width: 100%;
    height: 100%;
    object-fit: cover;}
      div.mp-content div.header h3 {    font-size: 14pt;}
      div.mp-content div.header p {    text-align: left;
    font-size: 11pt;
    line-height: 17pt;
    margin: 0;
    font-weight: normal;}
      
      div.mp-content div.conthreads {    margin-bottom: 15px;
    height: calc(100% - 180px);
    overflow-y: scroll;
    margin-top: 25px;}
      div.mp-content div.conthreads ul {    display: flex;
    flex-direction: column;
    gap: 5px;}
      div.mp-content div.conthreads ul li {    background-color: white;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;}
      div.mp-content div.conthreads ul li h3 {    font-size: 11pt;
    color: black;
    font-weight: 500;}
      div.mp-content div.conthreads ul li div.startbtn {    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;}
      div.mp-content div.conthreads ul li div.startbtn p {    font-size: 10pt;}
      div.mp-content div.conthreads ul li div.startbtn img {    width: 18px;}

      div.mp-content div.conthreads ul li div.startbtn span {
            background-color: red;
    color: white;
    font-weight: 600;
    font-size: 11pt;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 17pt;
      }

</style>
<template>
    <div class="tenant-nav" :style="SidebarHamClick ? 'left: 0px !important;box-shadow: 0px 0px 10px rgb(0 0 0 / 33%) !important;' : ''">
        <div class="logo-content">
            <img src="../../images/company/nav-logo.svg" />
        </div>

        <ul class="navigation">
            <EmployeeSideNav v-for="list in navigationlist" :key="list.key" :current="list" @navchange="NavigationClicked" />
        </ul>
    </div>

    <div class="tenant-content">
        <div class="content-header">
            <div class="ham-side-menu" @click="SidebarHamClick = true">
                <img src="./../../images/icons/line-menu.svg" />
            </div>

            <ul>
                <li class="transAe"><h3>Dashboard</h3></li>

                <li class="transAe">
                    <input type="text"
                            placeholder="Search in PropStop..." 
                            :style="{'background-image':'url('+require('../../images/icons/search.svg')+')'}" />
                </li>

                <li class="transAe">
                    <div class="tenant" v-if="CurrentEmployeeProfile">
                        <div class="notification" @click="employeeNotification = true">
                            <img src="../../images/icons/notification.svg" />
                            <span id="NotificationChanges" style="display: none;">0</span>
                        </div>

                        <div class="user" style="cursor: pointer;" @click="userProfileActive = true">
                            <h3>Hi, {{CurrentEmployeeProfile.fname}}</h3>
                            <div class="avatar">
                                <UserImage :init="CurrentEmployeeProfile.fullname" :source="CurrentEmployeeProfile.avatar" style="border-radius:50%" />
                            </div>                            
                        </div>
                        <EmployeeProfilePopup v-if="userProfileActive" 
                                                    @profileclose="closeProfilepopup"
                                                    :profile="CurrentEmployeeProfile"
                                                    :key="employeeprofileloaded" />

                    </div>
                </li>
            </ul>
        </div>

        <div class="page-content">
            <!-- Dynamic Loading via Fetch -->
            <router-view :employeeProfile="CurrentEmployeeProfile"
                            :properties="EmployeeProperties"
                            :selected="SelectedProperty"
                    :key="employeeprofileloaded"   />
            <!-- Dynamic Loading via Fetch -->
        </div>
    </div>

    <!-- pop ups --> 
    <RightModal v-if="UpdateEmployeeProfile" :form="'employee-profile-complete'" :data="CurrentEmployeeProfile" @modalclose="EmployeeIsVerified" />

    <!-- Welcome to propstop pop-ups -->
    <PropModal v-if="WelcomeToEmployee == true" :from="'welcome-owner'" @modalclose="CloseWelcomeModal" />

    <Notifications v-if="employeeNotification" :accounttype="'employee'" @notificlose="closeNotification" />

    <AccountChanges accountype='employee' />

</template>

<script>

    //@import JavaScript
    import DataCenter from './../../javascript/DataCenter';
    import Storage from './../../javascript/Storage';
    
    //@import component
    import EmployeeSideNav from './../employee/EmployeeSideNav.vue';
    import UserImage from './../blocks/UserImage.vue';
    import EmployeeProfilePopup from './../employee/EmployeeProfilePopup.vue';
    import AccountChanges from './../reusable/PropStopAccountChanges.vue';

    import Notifications from './../reusable/Notifications.vue';

    // Modal
    import RightModal from './../modal/RightModal.vue';
    import PropModal from './../modal/PropModal.vue';

    export default {
        name : 'TenantDashboard',
        components: {
            EmployeeSideNav,
            UserImage,
            EmployeeProfilePopup,
            AccountChanges,

            Notifications,

            RightModal,
            PropModal
        },

        data () {
            return {
                userProfileActive : false,
                employeeNotification : false,
                
                // Tenant Dashboard Navigation List
                navigationlist : [
                    {
                        key  : 1,
                        name : 'Dashboard',
                        route : 'employee-dashboard',
                        icon : 'images/icons/dashboard.svg',
                        active : true
                    },
                    {
                        key  : 3,
                        name : 'Tickets',
                        route : 'employee-tickets',
                        icon : 'images/icons/tickets.svg',
                        notification: 4,
                        active : false
                    }
                ],


                // logged in employee profile
                CurrentEmployeeProfile : false,
                employeeprofileloaded : false,
                UpdateEmployeeProfile : false,
                WelcomeToEmployee : false,

                // properties
                EmployeeProperties : [],
                SelectedProperty: {},

                // responsive
                SidebarHamClick : false,
            }
        },

        async mounted () {
            
            /* delete cache once login again */
            Storage.deleteCache ('employee-dashboard');
            Storage.deleteCache ('employee-profile');
            //*/

            // load the current tenant profile
            // user info if verified or not
            const RequestData = await DataCenter.ProPRequest('employee-profile', false, false);
            if (!RequestData) {}else {
                this.CurrentEmployeeProfile = RequestData.profile;
                this.employeeprofileloaded = true;
                if (this.CurrentEmployeeProfile.verified == "false" || this.CurrentEmployeeProfile.verified == null) this.UpdateEmployeeProfile = true; 
                // this is the first load, they must complete simple profile.

                // employee properties
                this.EmployeeProperties = RequestData.properties;
                this.SelectedProperty = this.EmployeeProperties[0];

                 // update notifications
                let ElTickets = document.getElementById('TicketsChanges');
                let t  = this.CurrentEmployeeProfile.changes.tickets;
                ElTickets.innerHTML = t;
                if (t <= 0) ElTickets.style.display = 'none';
                else ElTickets.style.display = 'block';
            }
        },

        methods : {

            EmployeeIsVerified (profile) {
                if (!profile) return;
                this.CurrentEmployeeProfile = profile;
                this.employeeprofileloaded = true;

                // close update profile
                this.UpdateEmployeeProfile = false;

                // welcome message to the tenant
                this.WelcomeToEmployee = true;
            },

            CloseWelcomeModal () {
                this.WelcomeToEmployee = false;
            },

             // change the active state on the navigation
            NavigationClicked (nav) {
                this.navigationlist = this.navigationlist.filter(list => {
                    list.active = false;
                
                    if (list.key == nav.key) {
                        list.active = true;
                    }

                    return list;
                });

                // now route to the acitve one
                this.$router.push({name : nav.route});

                // remove sidebar menu
                this.SidebarHamClick = false;
            },

            closeProfilepopup () {
                this.userProfileActive = false;
            },

            closeNotification () {
                this.employeeNotification = false;
            },
        }

    };
</script>
<template>
    <div class="profile-content _propscrl">
        <ul class="owner--profile animated fadeInUp">
            <li :class="[profileSlide['basic']]" @click="ProfileAccordinHandler ('basic')">
                <div class="op-header">
                    <div class="image">
                        <img src="./../../images/profile/user.svg" />
                    </div>

                    <div class="op-content">
                        <h3>Basic Information</h3>
                        <p>Owner basic info, profiles, name & password settings</p>
                        <input type="file" ref="files" @change="handleFilesUploads" style="display:none;" />
                    </div>

                    <div class="op-hover">
                        <img data-name="hambasic" src="./../../images/icons/ham-menu.svg" />
                        <ul v-if="UpdateBasicInfo == true" class="action-menu admam-resp">
                            <li><img src="./../../images/icons/close.svg" @click="UpdateBasicInfo = false" /></li>
                            <li @click="filesclick"><img src="./../../images/icons/change-avatar.svg" /> Change Avatar</li>
                            <li @click="ChangePasswordHandler"><img src="./../../images/icons/change-password.svg" /> Change Password</li>
                            <li @click="UpdateProfileHandler"><img src="./../../images/icons/update-profile.svg" /> Update Profile</li>
                        </ul>
                    </div>
                </div>

                <div class="op-content animated fadeInUp" v-if="profileSlide.basic">
                    <ul class="opc--info">
                        <li>
                            <div class="asker">
                                <h3>Avatar</h3>
                            </div>
                            <div class="answerer">
                                <p>Last updated June 25, 2021</p>
                            </div>
                            <div class="extraer">
                                <UserImage :init="userProfile.fullname" :source="userProfile.avatar" />
                            </div>
                        </li>

                        <li>
                            <div class="asker">
                                <h3>Fullname</h3>
                            </div>
                            <div class="answerer">
                                <h4>{{userProfile.fullname}}</h4>
                            </div>
                        </li>

                        <li>
                            <div class="asker">
                                <h3>Gender</h3>
                            </div>
                            <div class="answerer">
                                <h4>{{userProfile.gender}}</h4>
                            </div>
                        </li>

                        <li>
                            <div class="asker">
                                <h3>Birthday</h3>
                            </div>
                            <div class="answerer">
                                <h4>{{userProfile.dob}}</h4>
                            </div>
                        </li>

                        <li>
                            <div class="asker">
                                <h3>Password</h3>
                            </div>
                            <div class="answerer">
                                <h4>**********</h4>
                                <span class="password-change" v-if="IsPasswordChanged">Your password is uddated!</span>
                                <p>Last changed {{PasswordChangeDate}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>

            <li :class="[profileSlide['contact']]" @click="ProfileAccordinHandler ('contact')">
                <div class="op-header">
                    <div class="image">
                        <img src="./../../images/profile/mail.svg" />
                    </div>

                    <div class="op-content">
                        <h3>Contact Information</h3>
                        <p>Your contact numbers and primary email and other information</p>
                    </div>
                </div>

                <div class="op-content animated fadeInUp" v-if="profileSlide.contact">
                    <ul class="opc--info">
                        <li>
                            <div class="asker">
                                <h3>Email</h3>
                            </div>
                            <div class="answerer">
                                <h4>{{userProfile.email}}</h4>
                            </div>
                        </li>

                        <li>
                            <div class="asker">
                                <h3>Mobile</h3>
                            </div>
                            <div class="answerer">
                                <h4>{{FormatedMobileNumber}}</h4>
                            </div>
                        </li>

                        <li>
                            <div class="asker">
                                <h3>Phone</h3>
                            </div>
                            <div class="answerer">
                                <h4>{{FormatedPhoneNumber}}</h4>
                            </div>
                        </li>
                        
                    </ul>
                </div>
            </li>
        </ul>
    </div>

    <!-- Change password modal -->
    <PropModal v-if="ChangePassword == true" :from="'admin-change-password'" :data="userProfile" @modalclose="CloseChangePasswordModal" />

    <!-- Update profile -->
    <RightModal v-if="UpdateAdminProfile == true" :form="'admin-profile-update'" @modalclose="CloseModalBox" :data="userProfile" />

</template>

<script>

  //@import JavaScript
  import Document from '../../javascript/Document';
  import Request from './../../javascript/getRequest';
  import Handler from './../../javascript/Handler';
  import Methods from './../../javascript/Handler';


  //@import component
  import UserImage from './../../components/blocks/UserImage.vue';

  import PropModal from './../../components/modal/PropModal.vue';
  import RightModal from './../../components/modal/RightModal.vue';


  export default {
    name: 'AdminProfile',
    props: ['userProfile', 'owners'],
    components: {
      UserImage,
      PropModal,
      RightModal,
    },

    data () {
      return {
        StockURL : Request.StockURL,
        profileSlide : {
            'basic' : 'active',
            'contact' : '',
        },

        FormatedMobileNumber : false,
        FormatedPhoneNumber: false,

        // update ham menu
        UpdateBasicInfo : false,

        // functions
        ChangePassword : false,
        UpdateAdminProfile: false,

        IsPasswordChanged : false,
        PasswordChangeDate : this.userProfile.last_pupadte,

        // profile picture
        UploadAttachment : false,
        AttachmentSchema : ['jpeg', 'jpg', 'png'],
        AttachmentFile: [],
        AttachmentError: false,
        hasFileAttached: false,

      }
    },

    mounted () {
        // site title and seo
        Document.DocumentHeadings ({title : 'Profile - PropStop'});
        
        console.log(this.userProfile);

        this.FormatedMobileNumber = Handler.FormatMobileNumber(this.userProfile.mobile);
        this.FormatedPhoneNumber = Handler.FormatMobileNumber(this.userProfile.phone);

    },

    methods : {
        makeTargetActive (target) {
            this.profileSlide.basic = '';
            this.profileSlide.contact = '';

            this.profileSlide[target] = 'active';
        },

        // accordin click handler
        ProfileAccordinHandler (accordin) {
            var target = event.target.getAttribute('data-name');
            if (target == "hambasic") {
                this.UpdateBasicInfo = true;
            }else this.makeTargetActive (accordin);
        },

        // active accordin
        makeTargetActive (accordin) {
            this.profileSlide.basic = '';
            this.profileSlide.contact = '';

            this.profileSlide[accordin] = 'active';
        },

        // change password pop-up/handler
        ChangePasswordHandler () {
            this.UpdateBasicInfo = false;
            this.ChangePassword = true;
        },

        CloseChangePasswordModal (password) {
            this.ChangePassword = false;
            if (password) {
                this.IsPasswordChanged = true;
                this.PasswordChangeDate = "Today";
            }
        },

         // update profile close modal box
        CloseModalBox () {
            this.UpdateBasicInfo = false;
            this.UpdateAdminProfile = false;
        },

         UpdateProfileHandler () {
            this.UpdateBasicInfo = false;
            this.UpdateAdminProfile = true;
        },

        filesclick () {
            this.$refs.files.click();
            this.AttachmentError = false;
        },

        handleFilesUploads () {
            this.AttachmentFile = this.$refs.files.files[0];
            var AttachedFileInfo = Methods.AttachmentUploadHandler (this.AttachmentFile, this.AttachmentSchema, 2);
            
            // file is okay to upload
            if (AttachedFileInfo.matched && !AttachedFileInfo.largesize) {
                this.hasFileAttached = true;
            }else {
                this.AttachmentFile = [];
                this.hasFileAttached = false;
            }

            // now upload and update the avatar
            if (this.hasFileAttached) {
                this.UploadAndUpdateAvatar ();
            }else {
                alert ('Image not supported!');
            }

        },

        async UploadAndUpdateAvatar () {
            this.UpdateBasicInfo = false;
            
            let formData = new FormData();
            formData.append('user', this.userProfile.id);
            formData.append('document', this.AttachmentFile);

            // load data from server
            const ReturnData = await Request.postServer('admin/upload/avatar.php', formData);
            if (ReturnData.error == false) {
                var NewAvatar = ReturnData.response;
                this.userProfile.avatar = NewAvatar;
            }

        }
    
    }
    
  }
</script>

<style scoped>
  div.profile-content {width: 100%; height: 100%; overflow-y: scroll;}
  ul.action-menu {    text-align: left;
    right: -45px;
    top: -45px;
    left: unset;} span.password-change {color: #00b707;}
</style>
<template>
    <div class="admin-property">
        
        <div v-if="isRequesting" style="width: 50%;display: flex;margin: 0px auto;margin-top: 25%;">
            <Skeleton :type="'user-list'" :data="1" />
        </div>

        <div v-if="!isRequesting && !EmptyDisputeList" class="admin-property-content animated fadeInUp" style="width: calc(100% - 40px);">
            <div class="admin-prop--table">
                <div class="prop-row row-header">
                    <div class="column column30">Property Name</div>
                    <div class="column column30">Owner</div>
                    <div class="column column25">Requested Date</div>
                </div>

                <div class="prop-row" :class="index % 2 ? 'odd' : 'even'" 
                                        v-for="(disputed, index) in DisputedProperties" 
                                        :key="disputed.id" 
                                        @click="ClickHandler(disputed)">

                    <div class="column column30">
                        <div class="Property-details">
                            <div class="image">
                                <ReplaceImage :source="disputed.property.thumbnail" />
                            </div>

                            <div class="property-address">
                                <h3>{{ disputed.property.name }}</h3>
                                <p class="addr">{{ disputed.property.address }}</p>
                                <p>{{ disputed.property.created }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="column column30">
                        <div class="contact-dtl">
                            <div class="image">
                                <UserImage :init="disputed.property_owner.fullname" :source="disputed.property_owner.avatar" />
                            </div>

                            <div class="content">
                                <h3>{{ disputed.property_owner.fullname }}</h3>
                                <p>{{ disputed.property_owner.email }}</p>
                                <p>{{ disputed.property_owner.mobile }}</p>
                            </div>
                        </div>
                    </div> 

                    <div class="column column25">
                        <div class="contact-dtl">
                            <h3>{{ disputed.created }}</h3>
                        </div>
                    </div> 


                </div>
            </div>
        </div>

        <!-- If no data on result -->
        <div v-if="!isRequesting && EmptyDisputeList" class="empty-result animated fadeInUp">
            <img src="../../images/icons/empty-result.svg" />
            <p>You dont have much information on this request. <br />Properties will be listed once found on dispute list.</p>
        </div>

        <!-- Property detail and Confirm verificaiton -->
        <PropModal v-if="ShowPropertyInfo == true" :from="'property-info'" :data="PropertyData" @modalclose="CloseConformModal" />

    </div>
</template>

<script>

    import DataCenter from './../../javascript/DataCenter';

    import Skeleton from './../../components/blocks/Skeleton.vue';
    import ReplaceImage from '../../components/blocks/ReplaceImage.vue';
    import UserImage from '../../components/blocks/UserImage.vue';
    import PropModal from '../../components/modal/PropModal.vue';

    export default {
        name: 'Dispute',
        props: ['userProfile', 'owners'],
        components: {
            Skeleton,
            PropModal,

            ReplaceImage,
            UserImage,
        },

        data () {
            return {
                DisputedProperties: [],
                EmptyDisputeList : false,
                isRequesting: true,
                ShowPropertyInfo: false,
            }
        },

        async mounted () {

            // load the dispute information
            const ReturnData = await DataCenter.ProPRequest('disputed-properties', false, false);
            this.DisputedProperties = ReturnData;

            if (this.DisputedProperties.length <= 0) this.EmptyDisputeList = true;
            this.isRequesting = false;

        },

        methods: {

            CloseConformModal () {
                this.ShowPropertyInfo = false;
            },

            ClickHandler (property) {
                this.PropertyData = property;
                this.ShowPropertyInfo = true;
            }

        }



    }

</script>
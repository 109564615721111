<template>
      <div class="mp-convpage">
            <div class="header">
                  <img src="./../../images/icons/arrow-left.svg" @click="BackToMainPage" />
                  <div class="mph-info">
                        <div class="image">
                              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAABNCAYAAADuF5n3AAAABHNCSVQICAgIfAhkiAAABQ5JREFUeF7tm31M1VUYx7834CIWoGRlRsAEspsNjZmQsFaWpUJciEA0ylqRKRgvsTHJXuhl5laEQ2C8R7ZlWorBwK0tLQysPyogMHQZdivBBJQAIRD6nQM6cFfu77m718XleQbbZff7nPPbh+85v7PznKNZEvDiCDhUEdAwLFWcpIhhqWfFsAisGBbDohAgaHnOYlgEAgQpO+v/AGv7tjgsvVcHJyct4XHMlw4ODqG9vQsJidloa+s0v6FJMq3irLKSNCxYcLtVHthUoz09FxCqfwX9/f+akpK/tzisp2JXIH6jnvwglkyoq2tGcmquJZu0zgo+LycJ9yz2sfiDUho0GP5GVEwGJUWV1uLOys9LxiI/b1WdW0v0519nERn1hsWbZ1gEpFaFVVxajV0ff0l4HPOlPt7zUFSQKhuYks7KL6hEadlB8wkQMn19bsOusi0MSw0zhqWG0piGYTEsAgGClJ3FsAgECFJ2FsMiECBI2VkMi0CAIGVnMazJCWg0Gig/GB4ePYLh4GAvt3wGh4ZQX//rVZOnnbOClt2N5MRICSQ7pxxff1OPm+a4ouLAO+jo6EZIWDrD8vC4GSlJUQgM0EHscgp3ubvPQW1dEz7Z/RWyd2xmWI6ODkjYFA59WBBGRkaQm3cA+8qPyM/RUQ/ghedD5DC0s7tuesMK1wcpMELh5uaMiso6FBRWQsxSYhgqrJCZtRfK7IX4TXqsXhWAgYFBvJe5R2qNhU3OWbo7PZD40uNYvMgHLccNyNrxORoaT2JN9IN4dv2jcHaeKVn09vaj5MNq7P70EBbe5YXUl6Nxh687GhpO4n0FZEuLYQIzm4Ll4jJTDrmQ1YHo6b0gnbRfGXL3BS7E5vhweHnNNeqY1tY2OdnXHW1CRHgw4p4LkUC/qKhFvtLGuXM9Ms+mYJWVpsF7/jzs21+DwuIqzJ51g3SYeAOqiZqaRuzMLUdnVzc2xD2GJyLvx/ETf+DpZ961PVj+/r7o6vwHvylOWbVyKbakrYNWa6+G02WNKNGnby1GzZFGeHreghvdXPDDjydsD9Z4KsJRa9csJ4G6JM5R3pjGKkY2NQyNwerrG8DR75ovf6XTeeLWuW7yb/H2+7b2Z/lZq3VAcNDocJ22sK4ss29Nj0VoSKCE0n6mC/qIV+Vnt9nOqKrcxrDGn0lgWEaKrJfmLHbWuIMhV6tIq5ngeRiOTeUMawzE+CNH7CwTqyM1sJycHDFjxuRnTYcvDuN8d6/sTWzZzFJW/CL6+vrlsuLKsNl1llmrURNJDItAlWExLAIBgpSdxbAIBAhSdhbDIhAgSNlZDItAgCCd8s5qamqV5a5rEa6u1+Oh5f6yqyl5aeBaQDLWx7SA1XzsFFJS8yDuDCYplekIfbAs3VPD5mGJEtnaJ9+ewCVBKb7GrnuYysr2h2GRUngtKqmaAEaU7YsLRy8vUWLKOCs3OxGioEqNPXsPK4dBPpuQJo4gZWXGU5vC74YziI55k5xnKsHiV+geWbEEGa+vlxt2lBAbejHKMDx9ukOmiblKXIkTB0moUX3we2S89RE1zaTe4rBEjwV5KfDzm2+yc2OCQ4d/wtmO8wgLXQZxfosa4uZ9eORr1DRVeqvAEj2LS+XiQJq9vZ2qBzFXJBx4UdmCFr/HfjmFDRs/MLcpk3lWg2Wy5ykoYFiEfxrDYlgEAgQpO4thEQgQpOwshkUgQJCysxgWgQBBys5iWAQCBOl/CG43tPlKYzkAAAAASUVORK5CYII=" alt="">
                        </div>
                        <div class="info">
                              <h3>PropSopt Support</h3>
                              <p>Typically replies in a few minutes</p>
                        </div>
                  </div>
            </div>

            <div class="body">
                  <form v-if="SupportID == null" name="propform" id="propform-support">
                        <div class="start-conversation">
                              <div class="pf--input">
                                    <p>Topic</p>
                                    <input type="text" placeholder="Support topic" id="Topic" v-model="Topic" data-type="len" data-max="5" />
                              </div>

                              <div class="pf--input">
                                    <p>Category</p>
                                    <select name="Category" id="Category" data-type="select" v-model="Category">
                                          <option value="-1">- Select One -</option>
                                          <option value="Questions">Questions</option>
                                          <option value="Comments">Comments</option>
                                          <option value="Suggestions">Suggestions</option>
                                          <option value="I need help">I need help</option>
                                    </select>
                              </div>

                              <div class="pf--input">
                                    <p>Message</p>
                                    <textarea placeholder="Please enter your message" id="Message" v-model="Message" data-type="len" data-max="5" style="height: 120px;"></textarea>
                              </div>

                              <div class="pf--input">
                                    <div class="search">
                                          <div class="pretty p-switch p-fill" style="border: none;">
                                                <input type="checkbox" v-model="toEmail" >
                                                <div class="state p-success">
                                                      <label>May we reach you by email?</label>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </form>

                  <ul v-else class="chatconv">
                        <li v-for="message in Messages" :key="message.id">
                              <div class="user">
                                    <div class="image">
                                          <img :src="user.avatar" alt="">
                                    </div>
                              </div>

                              <div class="content">
                                    <h3 v-if="message.type == 'start'">{{ message.topic }}</h3>
                                    <p>{{ message.message }}</p>
                                    <span>{{ message.created }}</span>
                              </div>
                        </li>

                        <li v-for="thread in AdminMessages" :key="thread.id" style="width: 80%">
                              <div class="content" style="background-color: #2196F3;">
                                    <p>{{ thread.message }}</p>
                                    <span>{{ thread.created }}</span>
                              </div>
                        </li>
                  </ul>

            </div>

            <div class="footer">
                  <div v-if="SupportID == null" class="btn-start" @click="StartConversation()">
                        <p v-if="!Requesting">Start a new conversation</p>
                        <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                  </div>
            </div>
      </div>
</template>

<script>

      import FormValidation from '../../javascript/FormValidation';
      import Request from '../../javascript/getRequest';

      export default {
            name: 'MessagePage',
            props: ['supportid', 'user'],
            emits: ['back', 'update'],
            components: {},

            data () {
                  return {
                        Requesting: false,
                        Topic: '',
                        Category: '-1',
                        Message: '',
                        toEmail: false,
                        SupportID: this.supportid,

                        Messages: [],
                        AdminMessages: [],
                  }
            },

            mounted () {
                  if (this.SupportID !== null) {
                        this.Messages.push(this.SupportID);

                        this.AdminMessages = this.SupportID.threads;
                  }
            },

            methods: {

                  BackToMainPage () {
                        this.$emit('back');
                  },

                  async StartConversation () {
                        if (this.Requesting) return;

                        // form & validation
                        let form = document.getElementById('propform-support');
                        let canGoAhead = FormValidation.Validate (form);
                        if (canGoAhead) {
                              this.Requesting = true;

                              let formData = new FormData();

                              formData.append('topic', this.Topic);
                              formData.append('category', this.Category);
                              formData.append('message', this.Message);
                              formData.append('toemail', this.toEmail);
                              formData.append('userid', this.user.id);
                              formData.append('url', document.location.href);
                              formData.append('title', document.title);

                              // upload and load data from server
                              const ReturnData = await Request.postServer('owner/support/create.php', formData);
                              this.Requesting = false;

                              if (ReturnData.error == false) {
                                    this.SupportID = ReturnData.response.id;
                                    this.Messages.push(ReturnData.response);

                                    // emit the current new conversation to add to store
                                    this.$emit('update', ReturnData.response);
                              }else {
                                    this.formError = true;
                                    this.infoText = "Something went wrong! Please try again.";
                              }
                        }
                  }

            }
      }

</script>

<style>

ul.chatconv {    padding: 15px 25px;}
ul.chatconv li {    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 8px 0;}
ul.chatconv li div.user {    width: 45px;
    height: 45px;
    overflow: hidden;}
ul.chatconv li div.user div.image {    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;}
ul.chatconv li div.user div.image img {    width: 100%;
    height: 100%;
    object-fit: cover;}
ul.chatconv li div.content {    flex: 1;
    background-color: #343768;
    padding: 10px;
    border-radius: 4px;
    margin-top: 5px;}
ul.chatconv li div.content p {    color: white;
    line-height: 17pt;
    letter-spacing: 0.15px;
    font-size: 11pt;}
    ul.chatconv li div.content span {
      color: white;
    line-height: 17pt;
    letter-spacing: 0.15px;
    font-size: 11pt;
    }
    ul.chatconv li div.content h3 {
      color: white;
    margin-bottom: 12px;
    line-height: 18pt;
    }

    div.chatconv li div.content span {
      color: white;
    font-size: 9pt;
    line-height: 24pt;
    }

      div.start-conversation {    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 85%;
    margin: 0px auto;
    margin-top: 30px;}

      div.mp-convpage {    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: #f5f5f5;
    border-radius: 15px;
    overflow: hidden;}
      div.mp-convpage div.header {    background-color: white;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 0;}
      div.mp-convpage div.header img {    width: 20px;
    height: 20px;
    cursor: pointer;
    padding: 10px;}
      div.mp-convpage div.header div.mph-info {    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 9px;
    flex: 1;}
      div.mp-convpage div.header div.mph-info div.image {    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;}
      div.mp-convpage div.header div.mph-info div.image img {    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: unset;}
      div.mp-convpage div.header div.mph-info div.info {    display: flex;
    flex-direction: column;
    gap: 1px;}
      div.mp-convpage div.header div.mph-info div.info h3 {    font-size: 12pt;}
      div.mp-convpage div.header div.mph-info div.info p {    font-size: 9pt;
    margin: 0;}
      
      div.mp-convpage div.body {    flex: 1;
    overflow-y: scroll;}

      div.mp-convpage div.footer {    padding: 15px 25px;
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;}
      div.mp-convpage div.footer div.btn-start {    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    flex: 1;}
      div.mp-convpage div.footer div.btn-start p {    color: white;
    font-size: 11pt;}

    div.mp-convpage div.footer div.btn-start img {
      width: 20px;
    height: 20px;
    object-fit: cover;
    }

</style>
<script>

      import Announcements from './../../components/admin/communication/Announcements.vue';
      import Updates from './../../components/admin/communication/Updates.vue';
      import Wipes from './../../components/admin/communication/Wipes.vue';

      export default {
            name: 'Communication',
            props: ['userProfile', 'owners'],
            components: {
                  Announcements,
                  Updates,
                  Wipes
            },
            
            data () {
                  return {

                        VerifiedOwners: [],

                        AccountFilters : [
                              {
                                    id      : 1,
                                    name    : 'General Announcements',
                                    active  : true,
                              },
                              {
                                    id      : 2,
                                    name    : 'Software Updates',
                                    active  : false,
                              },
                              {
                                    id      : 3,
                                    name    : 'Server Wipe',
                                    active  : false,
                              }
                        ],

                        FilterChoosed :  {
                                    id      : 1,
                                    name    : 'General Announcements',
                                    active  : true,
                              }
                        }
            },

            mounted () {

                  this.owners.forEach(owner => {
                        if (owner.verified == 'true') {
                              this.VerifiedOwners.push({
                                    id: owner.id,
                                    fullname: owner.fullname,
                                    email: owner.email,
                                    avatar: owner.avatar
                              });
                        }
                  });

                  console.log (this.VerifiedOwners);
            },

            methods: {
            
                  CommFilterHandler (filter) {
                        this.AccountFilters.filter(lter => {
                        lter.active = false;
                        if (filter.id == lter.id) {lter.active = true;}
                  })

                  this.FilterChoosed = filter;
                  },
            }

      }

</script>

<template>
      <div class="admin-accounts">
            <div class="sidebar admin_s-debar">
                  <ul class="admin-menu">            
                        <li v-for="filter in AccountFilters" 
                                          :key="filter" 
                                          :class="filter.active ? 'active' : ''"
                                          @click="CommFilterHandler(filter)">
                        <p>{{filter.name}}</p>
                        </li>            
                  </ul>       
            </div>

            <div class="admin-content _propscrl">
                  <Announcements v-if="FilterChoosed.id == 1" :owners="VerifiedOwners" />
                  <Updates v-else-if="FilterChoosed.id == 2" :owners="VerifiedOwners" />
                  <Wipes v-else :owners="VerifiedOwners" />
            </div>

      </div>
</template>

<style>

      div.comm-body {
            width: 80%;
      margin: 0px auto;
      margin-top: 35px;
      }

      .bullt {    border: 1px solid #ddd;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    border-radius: 3px; margin-bottom: 10px;}
      .bullt textarea { border: none !important;}
      .bullt img {    padding: 15px;
    cursor: pointer;}

      .addmor {    background-color: #e7e7e7;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-top: 10px; cursor: pointer;
    gap: 2px;}
      .addmor img {    width: 18px;
    height: 18px;
    object-fit: contain;}

</style>
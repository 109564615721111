<template>
    <div class="custom-content" :style="fullscreen ? 'width: 100%;' : ''">
        <div class="cc-customer">
            <div class="back-btn">
                <img src="../../images/employee-detail/arrow-left.svg" @click="backbtnClick">
            </div>

            <div class="user-avatar">
                <div class="image">
                    <UserImage :init="employee.fullname" :source="employee.avatar" />
                </div>
                <div class="name-wrp">
                    <h3>{{employee.fullname}}</h3>
                    <p>{{employee.date}}</p>
                </div>
            </div>

            <ul class="contact">
                <li>
                    <div class="icon">
                        <img src="../../images/employee-detail/mail.svg">
                    </div>
                    <p>{{employee.email}}</p>
                </li>
                <li>
                    <div class="icon">
                        <img src="../../images/tenant-detail/smartphone.svg">
                    </div>
                    <p v-if="EmployeeMobile">{{employee.mobile}}</p>
                    <input v-else type="text" readonly placeholder="Employee Mobile">
                </li>
                <li>
                    <div class="icon">
                        <img src="../../images/tenant-detail/facebook.svg">
                    </div>
                    <p v-if="EmployeeFacebook">{{employee.facebook}}</p>
                    <input v-else type="text" readonly placeholder="Facebook Profile">
                </li>
                <li>
                    <div class="icon">
                        <img src="../../images/tenant-detail/twitter.svg">
                    </div>
                    <p v-if="EmployeeTwitter">{{employee.twitter}}</p>
                    <input v-else type="text" readonly placeholder="Twitter Profile">
                </li>
            </ul>

            <div class="role-clas">
                <h2>{{employee.fname}}'s Role</h2>
                <p>{{employee.role}}</p>
                <span class="btn-secondary">Update Role</span>
            </div>

            <ul class="links">
                <li @click="assignTask"><p>Assign Task</p></li>
                <li @click="assignTicket"><p>Assign Ticket</p></li>
                <li><p>Reset Password</p></li>
                <li><p class="delete">Archive Employee</p></li>
            </ul>
        </div>

        <div class="company--info">
            <div class="top-tkt-dtl">
                <ul>
                    <li>
                        <h3>{{ employee.hired.count }}</h3>
                        <p class="mnth">{{ employee.hired.time }}</p>
                        <p>Hired Date</p>
                        <p class="dt">({{ employee.hired.date }})</p>
                    </li>
                    <li>
                        <h3 class="mar-bn">{{ employee.tickets }}</h3>
                        <p>All Tickets</p>
                    </li>
                    <li>
                        <h3 class="mar-bn">{{ employee.tasks }}</h3>
                        <p>Task Assigned</p>
                    </li>
                </ul>
            </div>
            <ul class="custo--tabs">
                <li :class="{active : pmenu.active}" v-for="pmenu in PageMenu" :key="pmenu.id" @click="PageMenuClickHandler (pmenu)">{{ pmenu.name }}</li>                  
            </ul>

            <div class="custo--details _propscrl">
                <Profile v-if="EmployeeDetailPage == 'Profile'" :profile="employee" 
                :properties="properties" />
                <Tickets v-if="EmployeeDetailPage == 'Tickets'" :profile="employee" @createticket="assignTicket" />
                <Tasks v-if="EmployeeDetailPage == 'Tasks'" :profile="employee" @createtask="assignTask" />
            </div>
        </div>
    </div>
</template>

<script>
    // import javascript libraries

    // @import components
    import ReplaceImage from './../blocks/ReplaceImage.vue';
    import UserImage from './../blocks/UserImage.vue';

    import Profile from './employee/Profile.vue';
    import Tickets from './employee/Tickets.vue';
    import Tasks from './employee/Tasks.vue';

    export default {
        name: 'OwnerEmployeeDetail',
        props: ['employee', 'properties', 'fullscreen'],
        emits: ['backtolist', 'assigntask', 'assignticket'],
        components: {
            ReplaceImage,
            UserImage,

            Profile,
            Tickets,
            Tasks
        },

        data () {
            return {
                PageMenu : [
                    {
                        id : 1,
                        name : 'Profile',
                        active : true
                    },
                    {
                        id : 2,
                        name : 'Tickets'
                    },
                    {
                        id : 3,
                        name : 'Tasks'
                    }
                ],

                EmployeeDetailPage : 'Profile',

                EmployeeMobile : false,
                EmployeeFacebook : false,
                EmployeeTwitter : false,
            }
        },

        mounted () {

            // current employee contact 
            this.EmployeeMobile = this.contactInfoNullCheck (this.employee.mobile);
            this.EmployeeFacebook = this.contactInfoNullCheck (this.employee.facebook);
            this.EmployeeTwitter = this.contactInfoNullCheck (this.employee.twitter);
            
        },

        methods: {

            backbtnClick () {
                this.$emit('backtolist');
            },

            contactInfoNullCheck (contact) {
                if (contact == "" || contact == undefined || contact == null) return false;
                else return true;
            },

            PageMenuClickHandler (menu) {
                this.PageMenu = this.PageMenu.filter (key => {
                    key.active = false;
                    if (key.id == menu.id) key.active = true;
                    return key;
                });

                // update current menu name
                this.EmployeeDetailPage = menu.name;
            },

            assignTask () {
                this.$emit('assigntask', this.employee);
            },

            assignTicket () {
                this.$emit('assignticket', this.employee);
            }
        }
    };

</script>
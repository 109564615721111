<template>

    <div v-if="count == 0" class="prop-row row-header">
        <div class="column column40">Property Name</div>
        <div class="column column30">Owner</div>
        <div class="column column20">Verified</div>
    </div>

    <div class="prop-row" :class="count % 2 ? 'odd' : 'even'" @click="ClickHandler(property)">
        <div class="column column40">
            <div class="Property-details">
                <div class="image">
                    <ReplaceImage :source="property.thumbnail" />
                </div>

                <div class="property-address">
                    <h3>{{ property.name }}</h3>
                    <p class="addr">{{ property.address }}</p>
                    <p>{{ property.created }}</p>
                </div>
            </div>
        </div>

        <div class="column column30">
            <div class="contact-dtl" v-if="CurrentPropertyOwner">
                <div class="image">
                    <UserImage :init="CurrentPropertyOwner.fullname" :source="CurrentPropertyOwner.avatar" />
                </div>

                <div class="content">
                    <h3>{{ CurrentPropertyOwner.fullname }}</h3>
                    <p>{{ CurrentPropertyOwner.email }}</p>
                    <p>{{ CurrentPropertyOwner.mobile }}</p>
                </div>
            </div>
        </div> 

        <div class="column column20">
            <img v-if="property.verified == 'true'" class="verified" src="../../images/admin/check.svg">
            <img v-else class="unverified" src="../../images/admin/cross.svg">
        </div>
    </div>

</template>

<script>    
    // components
    import ReplaceImage from '../blocks/ReplaceImage.vue';
    import UserImage from '../blocks/UserImage.vue';

    export default {
        name: 'Properties',
        props: ['property', 'owners', 'count'],
        emits: ['choose'],
        components: {
            ReplaceImage,
            UserImage
        },

        data () {
            return {
                CurrentPropertyOwner : false,
            }
        },

        mounted () {
            this.FindOutOwner ();

        },

        methods: {
            FindOutOwner () {
                const PropertOwners = this.owners.filter (owner => {
                    if (this.property.owner == owner.owner) return owner;
                });

                this.CurrentPropertyOwner = PropertOwners[0];
            },

            ClickHandler (prop) {
                this.$emit('choose', prop);
            }
        }
    };
</script>
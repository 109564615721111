<template>
    <div class="fc--header">      
      <div class="fc-more-filter" style="float: right; position:relative">

        <div class="fmf-btn" style="float: right;" @click="FilterEmpByProperty">
          <img src="./../../images/finance/unit.svg" />
          <p>Filter Property</p>
        </div>

        <MultiPropertySelectionVue v-if="MultiPropertySelection" :properties="properties" :style="'top: 0px;left: 85px;'"   
        @lstboxclse="PropertiesAreSelected"/>

      </div>
    </div>

    <ul class="owner-employees">
        <li v-for="employee in FilteredEmployeeList" :key="employee.id" @click="EmployeeClickHandler (employee)">
            <div class="content">
                <div class="image">
                    <UserImage :init="employee.fullname" :source="employee.avatar" />
                </div>

                <div class="contact">
                    <h3>{{employee.fullname}} <strong>{{employee.assignments[0].role}}</strong></h3>
                    <p>
                        <span>{{employee.date}}</span>
                        <span>{{employee.email}}</span>
                        <span>{{employee.phone}}</span>
                    </p>
                </div>
            </div>

            <ul class="hover">
                <li title="Delete"><img data-name="delete" src="../../images/icons/delete.svg" /></li>
                <li title="Assign Task"><img data-name="task" style="width: 20px;opacity: 0.9;" src="../../images/icons/tasks.svg" /></li>
                <li title="Assign Ticket"><img data-name="ticket" src="../../images/icons/ticket.svg" /></li>
            </ul>
        </li>
    </ul>

    <!-- If no data on result -->
    <div v-if="FilteredEmployeeList.length <= 0" class="empty-result animated fadeInUp">
        <img src="../../images/icons/empty-result.svg" />
        <p>You dont have much information on this request. <br />Please add employee on this Role.</p>
    </div>
</template>

<script>
    // @import components
    import UserImage from './../blocks/UserImage.vue';
    import MultiPropertySelectionVue  from './../web-forms/MultiPropertySelection.vue';

    export default {
        name: 'OwnerEmployees',
        props: ['property','employees', 'filter', 'properties'],
        emits: ['detail', 'task', 'delete', 'ticket'],
        components: {
            UserImage,
            MultiPropertySelectionVue,
        },       

        data () {
            return {
                FilteredEmployeeList : [],
                MultiPropertySelection: false,
                PropertiesSelected : [],
            }
        },

         mounted () {
            // filter according the clicked filter menu and load the data
            if (this.employees) this.filterTheList ();
        },

        methods: {
            // filter the employee and show them
            filterTheList () {
                // show only employee which are selected to the selected property
                this.PropertiesSelected = this.properties.filter (property => {
                    if (property.active) return property;
                });

                // now filter accordintly
                if (this.PropertiesSelected.length == 0) {

                    // now show all the properties employees
                    var NewFilter = [];
                    this.employees.forEach (employee => {
                        this.properties.forEach(property => {
                            let assigned = employee.assignments;
                            for (let assigne in assigned) {
                                let propid = assigned[assigne].property.id;
                                if (property.id == propid) {
                                    NewFilter.push (employee);
                                }
                            }
                        });
                    });
                    this.FilteredEmployeeList = NewFilter;

                }else {
                    var NewFilter = [];
                    this.employees.forEach (employee => {
                        this.PropertiesSelected.forEach(property => {
                            let assigned = employee.assignments;
                            for (let assigne in assigned) {
                                let propid = assigned[assigne].property.id;
                                if (property.active && property.id == propid) {
                                    NewFilter.push (employee);
                                }
                            }
                        });
                    });
                    this.FilteredEmployeeList = NewFilter;
                }

                // check for the duplicate
                this.FilteredEmployeeList = [...new Set(this.FilteredEmployeeList)];
                

                // now filter the role clicked
                if (this.filter == "All") return;
                this.FilteredEmployeeList = this.FilteredEmployeeList.filter (employee => {
                    let assigned = employee.assignments;
                    for (let assigne in assigned) {
                        let Role = assigned[assigne].role;
                        if (Role.match(this.filter)) return employee;
                    }
                });
            },

            // want to see the detail information of current employee
            EmployeeClickHandler (employee) {
                let target = event.target.getAttribute('data-name');
                // want to edit the employee
                if (target == "task") {
                    this.$emit('task', employee);
                // want to delete the employee
                }else if (target == "delete") {
                    this.$emit('delete', employee);
                // want to assign ticke to this empployee
                }else if (target == "ticket") {
                    this.$emit('ticket', employee);
                // want to see details
                }else {
                    this.$emit('detail', employee);
                }
            },

            // filter property list click
            FilterEmpByProperty () {
                this.MultiPropertySelection = true;
            },

            // filter the properties by selection
            PropertiesAreSelected (propties) {
                this.MultiPropertySelection = false;
                this.PropertiesSelected = propties;

                // now filter the list
                this.filterTheList ();
            },

        },
    };
</script>

<style scoped>
div.imgcls p { padding-top: 14px !important;}
div.contact h3 strong {     display: inline;
    font-size: 9pt;
    font-weight: 500;
    letter-spacing: 0.25px;
    background-color: #e9e9e9;
    padding: 2px 9px;
    color: #505050;
    border-radius: 7px;
    margin-left: 3px;}


    .fc--header {    width: 100%;
    height: 60px;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 20px;}
</style>
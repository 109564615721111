<script>

      import DataCenter from '../../../javascript/DataCenter';
      import Request from '../../../javascript/getRequest';
      import Handler from '../../../javascript/Handler';

      import PropertyList from '../../blocks/PropertyList.vue';
      import MultiPropertySelectionVue from './../../web-forms/MultiPropertySelection.vue';
      import UserImage from '../../blocks/UserImage.vue';
      import SkeletonVue from '../../blocks/Skeleton.vue';

      // pinia
      import { useOwnerStore } from './../../../stores/OwnerStore';

      export default {
            name: 'Tenants',
            components: {
                  PropertyList,
                  MultiPropertySelectionVue,
                  UserImage,
                  SkeletonVue,
            },

            data () {
                  return {
                        isRequesting: true,
                        isDataSaving: false,
                        isDataSaved: false,
                        BulkPaymentOrginal: [],
                        BulkPaymentData: [],

                        PropertySingleSelect: false,
                        SelectedProperty: null,

                        // pinia storage
                        OwnerProperties: false,
                        OwnerStore: useOwnerStore (),
                  }
            },

            async mounted () {
                  // update pinia storage to local variables
                  this.OwnerProperties = this.OwnerStore.properties;
                  this.SelectedProperty = this.OwnerProperties;
                  
                  const UnitsInfo = await DataCenter.ProPRequest('tenants-payment-info', false, false);
                  this.BulkPaymentOrginal = UnitsInfo;
                  this.BulkPaymentData = UnitsInfo;
                  this.isRequesting = false;
            },

            methods: {

                  async UpdateFinanceData () {
                        if (this.isDataSaving || this.isDataSaved) return;

                        this.isDataSaving = true;

                        // copy all data to new array
                        let NewData = [];
                        NewData = this.BulkPaymentData.map(payment => {
                              return {
                                    property: payment.property,
                                    unit: payment.unit,
                                    tenantid: payment.tenantid,
                                    assignment: payment.assignment,
                                    balance: payment.balance,
                                    paid: payment.paid,
                                    note: payment.note,
                                    method: payment.method
                              }
                        });

                        // collect form data
                        let formData = new FormData();
                        formData.append('tenantinfo', JSON.stringify(NewData));
      
                        // upload and load data from server
                        const ReturnData = await Request.postServer('owner/finance/bulktenantpay.php', formData);
                        this.BulkPaymentData = ReturnData.response;

                        this.isDataSaving = false;
                        this.isDataSaved = true;

                        setTimeout(() => {
                              this.isDataSaved = false;
                        }, 1000);

                        // update notification
                        Handler.PropNotification ('Successfully updated transactions!');
                        
                  },

                  PropertySelectClose () {
                        this.PropertySingleSelect = false;
                  },

                  PropertySelected (properties) {
                        if (properties !== null) {
                              this.SelectedProperty = properties;

                              // filter by property
                              this.BulkPaymentData = [];
                              this.BulkPaymentOrginal.forEach(bulk => {
                                    properties.forEach(prop => {
                                          if (bulk.property == prop.id && prop.active) this.BulkPaymentData.push(bulk);
                                    });
                              });

                        }

                        // hide single or multiple selection
                        this.PropertySingleSelect = false;
                  },

            }

      }

</script>

<template>

      <div class="fc--header" style="overflow: unset;">      
            <div class="fc-more-filter" style="width: 65%">

                  <div class="fmf-btn" @click="PropertySingleSelect = true">
                        <img src="./../../../images/icons/sort.svg" style="width: 10px;height: 12px;margin-top: 5px;" />
                        <p>Choose Property</p>
                  </div>
                  
                  <!-- <PropertyList v-if="PropertySingleSelect" :properties="OwnerProperties"  @prosel="PropertySelected"
                        @proboxclse="PropertySelectClose"
                        :distyle="'top: 65px;position: absolute;height: 355px;width: 360px;left: 23px;overflow-y: scroll;'" /> -->

                  <MultiPropertySelectionVue v-if="PropertySingleSelect" :properties="OwnerProperties" :style="'width: 365px;top: -15px;left: 85px;'"   
                        @lstboxclse="PropertySelected"/>

            </div>
      </div>

      <div v-if="!isRequesting" class="fin-property" style="margin-top: 90px">
            <div class="prop--table">
                  <div class="prop-row row-header" style="padding-bottom: 20px !important;">
                        <div class="column column30"><p>Unit/Tenant</p></div>
                        <div class="column column25"><p>Amount Paid</p></div>
                        <div class="column column25"><p>Pay Method</p></div>
                        <div class="column column20"><p>Notes</p></div>
                  </div>

                  <div class="prop-row" v-for="data in BulkPaymentData" :key="data.unit">
                        <div class="column column30" style="display: flex;justify-content: center;align-items: center;">
                              <div class="unit--tenant" style="display: flex;justify-content: center;align-items: center;">
                                    <div class="image">
                                          <UserImage :init="data.tenant.fullname" :source="data.tenant.avatar" />
                                    </div>

                                    <div class="content" style="margin-top: 0;">
                                          <h3>{{data.tenant.fullname}}</h3>
                                          <p v-if="data.balance < 0" style="color: red !important">Due: {{ data.balance.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            }) }}</p>
                                          <p v-else-if="data.balance == 0" style="color: rgb(179 179 179) !important">Balance is maintained ($0)</p>
                                          <p v-else style="color: green !important">Overpaid: {{ data.balance.toLocaleString('en-US', {
                                                      style: 'currency',
                                                      currency: 'USD',
                                                      }) }}</p>
                                    </div>
                              </div>
                        </div>

                        <div class="column column25">
                            <div class="brp-input">
                                <input type="number" v-model="data.paid" class="number" placeholder="Enter amount.." :disabled="isDataSaving">
                            </div>
                        </div>

                        <div class="column column25">
                            <div class="brp-input">
                                <select name="method" v-model="data.method" class="inputselect" :disabled="isDataSaving">
                                    <option value="">Pay Method</option>
                                    <option value="check">Check</option>
                                    <option value="Credit Card">Credit Card</option>
                                    <option value="moneyorder">Money Order</option>
                                    <option value="venmo">Venmo</option>
                                    <option value="zelle">Zelle</option>
                                </select>
                            </div>
                        </div>

                        <div class="column column20">
                            <div class="brp-input">
                                <input type="text" style="width: 80%;" v-model="data.note" placeholder="Remarks.." :disabled="isDataSaving">
                            </div>
                        </div>
                  
                  </div>

            </div>

            <div class="auto-save" @click="UpdateFinanceData()">
                  <p v-if="isDataSaving">Updating...</p>
                  <p v-if="isDataSaved">Updated</p>
                  <p v-if="isDataSaving == false && isDataSaved == false">Update Payment</p>
            </div>
      </div>

      <div v-if="isRequesting" style="margin-top:145px">
            <SkeletonVue :type="'user-list'" :data="2" />
      </div>

</template>

<style>

      select.inputselect {
    font-size: 11pt;
    padding: 10px;
    border: 1px solid #e3e3e3 !important;
    border-radius: 4px;
      }

      div.auto-save {
            cursor: pointer;
    width: auto;
    height: auto;
    overflow: hidden;
    background-color: #449adf;
    color: white;
    padding: 12px 20px;
    border-radius: 4px;
    float: left;
    text-align: center;
    margin-top: 15px;
      }


      div.auto-save p {
            font-size: 10pt;
    color: white;
    font-weight: 600;
      }

</style>
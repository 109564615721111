<template>

      <div class="support-box" v-if="isMessageBoxOpen">
            <MainPage v-if="isMainPage" :user="CurrentUser" @start="OpenMessagePage(null)" @open="OpenPreviousMessage" />
            <MessagePage v-else :supportid="supportId" :user="CurrentUser" @update="UpdateToStore" @back="OpenMainPage()" />
      </div>

      <div class="site-help-button" @click="OpenMessageBoxHandler">
            <img src="../../images/icons/help-centre.svg" />
            <span v-if="count >= 1">{{ count }}</span>
      </div>
</template>

<script>

      // pinia
      import { useOwnerStore } from "./../../stores/OwnerStore";


      // components
      import MainPage from "./MainPage.vue";
      import MessagePage from "./MessagePage.vue";

      export default {
            name: 'Support',
            props: ['count'],
            components: {
                  MainPage,
                  MessagePage
            },

            data () {
                  return {
                        OwnerStore: useOwnerStore (),
                        CurrentUser: {},

                        isMessageBoxOpen: false,
                        isMainPage: true,
                        supportId: '',

                        isRequesting: true,
                  }
            },

            async mounted () {
                  this.CurrentUser = this.OwnerStore.user;
            },

            methods: {

                  OpenMessageBoxHandler () {
                        this.isMessageBoxOpen = !this.isMessageBoxOpen;
                  },

                  OpenMessagePage (id) {
                        this.supportId = id;

                        // open conversation page
                        this.isMainPage = false;
                  },

                  OpenPreviousMessage (message) {
                        console.log ('open previous')
                        this.OpenMessagePage(message);
                  },

                  OpenMainPage () {
                        this.isMainPage = true;
                  },

                  UpdateToStore (message) {
                        let updated = this.OwnerStore.conversations;
                        if (updated.length == 0) updated[0] = message;
                        else updated.unshift(message);

                        this.OwnerStore.ResetConversations(updated);
                  }

            }
      }

</script>

<style>

      div.support-box {
            width: 400px;
    height: 640px;
    position: fixed;
    background-color: white;
    border-radius: 7px;
    box-shadow: 0px 0px 42px rgb(0 0 0 / 30%);
    right: 45px;
    bottom: 80px; border-radius: 15px;
      }

      div.site-help-button span {
            display: block;
    position: absolute;
    top: -1px;
    right: -11px;
    background-color: red;
    padding: 5px;
    font-size: 13pt;
    font-weight: 600;
    color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 14pt;
      }

</style>
<template>
    <div class="internal--header">
        <ul class="tab-menu">
            <li :class="{'active' : TenantActive}" @click="MakeTenantTabActive">Tenants</li>
            <li :class="{'active' : !TenantActive}" @click="MakeEmployeeTabActive">Employees</li>
        </ul>
        <div class="actions" style="display: none;">
            <div v-if="TenantActive" class="btn-secondary" @click="AddNewTenant">
                <img src="" />
                <p>Add Tenant</p>
            </div>
            <div v-if="!TenantActive" class="btn-secondary" @click="AddNewEmployee">
                <img src="" />
                <p>Add Employee</p>
            </div>
        </div>
    </div>

    <!-- Tenant Skeleton -->
    <div v-if="LoadingTenantState == true && TenantActive" style="margin-top:55px">
        <Skeletion :data="3" />
    </div>

    <!-- List all tenants data -->
    <div v-if="!TenantisSelected && TenantsListed && TenantActive" class="prop--table animated fadeInUp">
        <TenantList v-for="(tenant, index) in PropertyTenants" 
                :key="tenant.id" 
                :tenant="tenant" 
                :count="index"
                
                @select="TenantSelected"/>
    </div>

    <!-- Tenant Detail Page -->
    <TenantDetail v-if="TenantisSelected == true" :tenant="TenantChoosed" :property="property" :fullscreen="true" @backtolist="BackbtnClicked" />

    <!-- If no data on result -->
    <div class="emptyskeleton" v-if="EmptyTenants && TenantActive">
        <img src="./../../images/icons/empty-result.svg" class="psanim-1 fadeInUp">
        <p class="psanim-2 fadeInUp">You dont have much information on this request. <br />Please add tenants on this property.</p>
        <div class="btn-secondary psanim-3 fadeInUp" @click="AddNewTenant">
            <p>Add Tenant</p>
        </div>
    </div>

    <!-- Employee Skeleton -->
    <div v-if="LoadingEmployeeState == true && !TenantActive" style="margin-top:55px">
        <Skeletion :data="3" />
    </div>
    
    <!-- List all employees data -->
    <div v-if="!EmployeeisSelected && EmployeesListed && !TenantActive" class="employees-content animated fadeInUp">
        <ul class="owner-employees">              
            <EmployeeList v-for="employee in PropertyEmployees" :key="employee.id" :employee="employee" @select="EmployeeSelected" />
        </ul>
    </div>

    <!-- Employee Detail -->
    <OwnerEmployeeDetail v-if="EmployeeisSelected" 
                            :employee="EmployeeChoosed" 
                            @assigntask="''"
                            @assignticket="''"
                            :fullscreen="true"
                            @backtolist="BackbtnClicked" />

    <!-- If no data on result -->
    <div class="emptyskeleton" v-if="EmptyEmployees && !TenantActive">
        <img src="./../../images/icons/empty-result.svg" class="psanim-1 fadeInUp">
        <p class="psanim-2 fadeInUp">You dont have much information on this request. <br />Please add employees on this property.</p>
        <div class="btn-secondary psanim-3 fadeInUp" @click="AddNewEmployee">
            <p>Add Employee</p>
        </div>
    </div>

    <!-- pop ups --> 
    <RightModal v-if="AddNewTenantModal" :form="'add-tenant'" :data="PassFormData" @modalclose="CloseTenantBox" />
    <RightModal v-if="AddNewEmployeeModal == true" :form="'add-employee'" :data="PassFormData" @modalclose="CloseEmployeeBox" />

</template>

<script>
    //@ JS Modules
    import Document from '../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';
    import Storage from './../../javascript/Storage';

    import Skeletion from './../blocks/Skeleton.vue';

    //@ import components blocks
    import TenantList from './blocks/TenantList.vue';
    import EmployeeList from './blocks/EmployeeList.vue';

    import TenantDetail from './../../components/owner/OwnerTenantDetail.vue';
    import OwnerEmployeeDetail from './../../components/owner/OwnerEmployeeDetail.vue';

    // Modal
    import RightModal from './../modal/RightModal.vue';

    export default {
        name: 'People',
        props: ['property', 'initPeople'],
        emits: ['dischargePeople'],
        components: {
            Skeletion,

            TenantList,
            EmployeeList,

            TenantDetail,
            OwnerEmployeeDetail,

            RightModal,
        },

        data () {
            return {
                TenantActive : true, // false = Employee
                PropertyTenants : [],
                TenantsListed : false,
                EmptyTenants : false,
                TenantisSelected : false,
                TenantChoosed : {},
                filterChanges: 0,

                PropertyEmployees : [],
                EmployeesListed : false,
                EmptyEmployees : false,
                EmployeeisSelected : false,
                EmployeeChoosed : {},


                // page modal settings
                AddNewTenantModal : false,
                AddNewEmployeeModal : false,
                PassFormData : false,

                // fetch data
                LoadingTenantState : true,
                LoadingEmployeeState : true,
            }
        },

        async mounted () {
            // site title and seo
            Document.DocumentHeadings ({title : ' Properties - People'});

            // current property
            let PropertyID = this.property.id;

            if (this.initPeople == 'tenant') this.AddNewTenant();
            else if (this.initPeople == 'employee') this.AddNewEmployee();

            // load units data from server
            let getdata = {
                key : 'property',
                value : PropertyID
            }

            // now load all the owner tenants
            const Tenants = await DataCenter.ProPRequest('property-tenants-list', false, getdata);
            this.LoadingTenantState = false;
            if (!Tenants) {
                this.TenantsListed = false;
                this.EmptyTenants = true;
            }else {
                this.PropertyTenants = Tenants;
                this.TenantsListed = true;
                this.EmptyTenants = false;

                // filter tenants remove this later
                // this.filterTenants (PropertyID, this.PropertyTenants);
            }

            // now load all the owner employees
            const Employees = await DataCenter.ProPRequest('employees-list', false, false);
            this.LoadingEmployeeState = false;
            if (!Employees) {
                this.EmployeesListed = false;
                this.EmptyEmployees = true;
            }else {
                this.PropertyEmployees = Employees;
                this.EmployeesListed = true;
                this.EmptyEmployees = false;

                // filter employees
                this.filterEmployee (PropertyID, this.PropertyEmployees);
            }
            
        },

        methods: {
            // filter all the employees according to the current property
            filterEmployee (propertyid, Employees) {
                this.PropertyEmployees = Employees.filter (employee => {
                    // assignments on current employee
                    var employeeAssignment = employee.assignments;
                    var isThisEmployee = employeeAssignment.map((prop) => {
                        if (prop.property.id == propertyid && prop.id != null) return true;
                    }).join('');

                    if (isThisEmployee) return employee;
                });

                // find if empty list
                if (this.PropertyEmployees.length == 0) this.EmptyEmployees = true;
                else this.EmptyEmployees = false;
            },
            
            // filter all the tenants according to the current property
            filterTenants (propertyid, Tenants) {
                this.PropertyTenants = Tenants.filter (tenant => {
                    if (propertyid == tenant.property) return tenant;
                });

                // find if empty list
                if (this.PropertyTenants.length == 0) this.EmptyTenants = true;
                else this.EmptyTenants = false;
            },

            // Add New Tenant Form/Modal
            AddNewTenant () {
                // set data to pass to forms
                this.PassFormData = {
                    property : this.property,
                    hasProperty: true
                }
                
                // now load the right modal for new tenant additions
                this.AddNewTenantModal = true;
            },

            // close the tenant modal box
            CloseTenantBox (tenant) {
                if (tenant) {
                    if (!this.PropertyTenants) {
                        this.PropertyTenants = [];
                        this.PropertyTenants[0] = tenant;
                    }else {
                        this.PropertyTenants.unshift (tenant);
                    }

                    // update data
                    this.EmptyTenants = false;
                    this.TenantActive = true;
                    this.TenantsListed = true; 
                    this.filterChanges = Math.round(+new Date()/1000);
                }

                this.AddNewTenantModal = false;
                this.$emit('dischargePeople');
            },

            // Add New Employee Form/Modal
            AddNewEmployee () {
                // set data to pass to forms
                this.PassFormData = {
                    property : this.property
                }
                
                // now load the right modal for new employee additions
                this.AddNewEmployeeModal = true;
            },

            // close modal box
            CloseEmployeeBox (employee) {
                if (employee) {
                    if (!this.PropertyEmployees) {
                        this.PropertyEmployees = [];
                        this.PropertyEmployees[0] = employee;
                    }else {
                        this.PropertyEmployees.push (employee);
                    }

                    // update data
                    this.EmptyEmployees = false;
                    this.TenantActive = false;
                    this.EmployeesListed = true;

                    // update the Storage
                    Storage.deleteCache('employees-list');
                    Storage.storeCache('employees-list', this.PropertyEmployees);  

                    // filter employees
                    this.filterEmployee (this.property.id, this.PropertyEmployees);
                }

                // close the modal
                this.AddNewEmployeeModal = false;
                this.$emit('dischargePeople');
                
            },

            // tenant list active
            MakeTenantTabActive () {
                this.TenantActive = true;
                this.EmployeeisSelected = false;
                this.TenantisSelected = false;
            },

            // employee list active
            MakeEmployeeTabActive () {
                this.TenantActive = false;
                this.EmployeeisSelected = false;
                this.TenantisSelected = false;
            },

            // once tenant selected 
            TenantSelected (tenant) {
                this.TenantisSelected = true;
                this.TenantChoosed = tenant;
            },

            // once employee is selected
            EmployeeSelected (employee) {
                this.EmployeeisSelected = true;
                this.EmployeeChoosed = employee;
            },

            // back to list
            BackbtnClicked () {
                this.TenantisSelected = false;
                this.EmployeeisSelected = false;
            },

        }
    }
</script>
<template>    

    <div v-if="count == 0" class="prop-row row-header">
        <div class="column column30">Owner</div>
        <div class="column column30">Contact</div>
        <div class="column column20">Email Verification</div>
        <div class="column column20">Verification</div>
    </div>

    <div class="prop-row" :class="count % 2 ? 'odd' : 'even'" @click="ClickHandler(owner)">
        <div class="column column30">
            <div class="unit--admin">
                <div class="image">
                    <UserImage :init="owner.fullname" :source="owner.avatar" />
                </div>

                <div class="content">
                    <h3>{{ owner.fullname }}</h3>
                    <p>{{ owner.created }}</p>
                </div>
            </div>
        </div>
        <div class="column column30">
            <div class="contact">
                <p>{{ owner.email }}</p>
                <p>{{ owner.mobile }}</p>
            </div>
        </div> 

        <div class="column column20">
            <img v-if="owner.email_verify == 'true'" class="verified" src="../../images/admin/check.svg">
            <img v-else class="unverified" src="../../images/admin/cross.svg">
        </div>


        <div class="column column20">
            <img v-if="owner.verified == 'true'" class="verified" src="../../images/admin/check.svg">
            <img v-else class="unverified" src="../../images/admin/cross.svg">
        </div>
    </div>

    
</template>

<script>    
    // components
    import UserImage from '../blocks/UserImage.vue';

    export default {
        name: 'OwnerAccounts',
        props: ['owner', 'count', 'filter'],
        emits: ['choose'],
        components: {
            UserImage,
        },
        
        methods: {
            ClickHandler (owner) {
                this.$emit('choose', owner);
            }
        }
    };
</script>

<style scoped>
div.prop--table {width: 100%;height: max-content;}
div.prop--table div.prop-row {width: 100%;height: auto;padding: 10px 0;background-color: #fff;margin-bottom: 5px;overflow: hidden;border-bottom: 1px solid #e8e8e8; cursor: pointer;}
div.prop--table div.odd {background-color: #fff;}
div.prop--table div.even {background-color: #FCFCFC;}
div.prop--table div.row-header {background-color: #fff !important;}
div.prop--table div.prop-row .column30 {width: 30% !important;}
div.prop--table div.prop-row .column {width: auto;height: auto;float: left;}
div.prop--table div.row-header .column {font-size: 10pt;font-weight: 500;}
div.prop--table div.prop-row .column20 {width: 20%;}
div.prop--table div.unit--admin {width: 95%;margin-right: 5%;height: 100%;overflow: hidden;}
div.prop--table div.unit--admin div.image {width: 65px;height: 65px;overflow: hidden;margin-right: 10px;float: left;}
div.prop--table div.unit--admin div.image img {width: 100%;}
div.prop--table div.unit--admin div.content {width: calc(100% - 75px);height: auto;float: left;margin-top: 12px;}
div.prop--table div.prop-row .column h3 {font-size: 11pt;font-weight: 600;color: #191919;}
div.prop--table div.unit--admin div.content p {color: var(--light-blue);}
div.prop--table div.prop-row .column p {font-size: 10pt;letter-spacing: 0.15px;color: #000;}
img.verified { margin-top: 20px; height: 10px; width: 10px; border-radius: 50%; padding:5px;background-color: #4caf50; margin-left: 40px; }
img.unverified { margin-top: 20px; height: 10px; width: 10px; border-radius: 50%; padding:5px;background-color: #f44336; margin-left: 40px; }
div.prop--table div.contact { width: 95%; margin: 0px auto; height: auto; margin-top: 10px;}
div.prop--table div.status.primary { background-color: #449ADF; border: 1px solid #ffffff; color: #ffffff;}
div.prop--table div.status { width: max-content; font-size: 10pt; padding: 5px 15px; border-radius: 35px; margin-bottom: 5px;}
div.prop--table div.status img{height: 10px; width: 10px; border-radius: 50%; padding:5px;background-color: #4caf50;}
div.prop--table .admin--score {margin-top: 15px;}
</style>
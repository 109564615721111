<template>
    <div class="user-list animatedss fadeInUp" :style="style">
        <!-- If no data on result -->
        <div v-if="lists.length <= 0 || lists == false" class="empty-result">
            <img src="../../images/icons/empty-result.svg" />
            <p>No user in this list, Please add users and get back to the list.</p>
        </div>

        <div class="search" style="margin: 15px;margin-bottom: 0;">
            <input type="text" placeholder="Search" v-model="SearchTxt" @keyup="StartSearching()">
        </div>

        <ul class="_propscrl" id="UserList">            
            <li class="transAe" :class="{ active : lst.active}" v-for="lst in lists" :key="lst.id" @click="ListSelect(lst)">
                <div class="image">
                    <UserImage :init="lst.fullname" :source="lst.avatar" />
                </div>

                <div class="content">
                    <h3>{{ lst.fullname }}</h3>
                    <h4 style="font-weight: normal;color: black;">{{ lst.email }}</h4>
                </div>
            </li>
        </ul>
    </div>
    <div class="overlay" @click="closeList" style="opacity: 0;"></div>
</template>

<script>

    //@ import components blocks
    import UserImage from './../blocks/UserImage.vue';

    export default {
        name: 'UserSelectPop',
        props: ['lists', 'style'],
        emits: ['lstboxclse'],
        components: {
            UserImage
        },

        data () {
            return {
                ListData : this.lists,
                SearchTxt: ''
            }
        },

        methods: {

            StartSearching () {
                let query = this.SearchTxt.toUpperCase();
                let UserList = document.getElementById('UserList');
                let list = UserList.getElementsByTagName('li');
                
                for (let i = 0; i < list.length; i++) {
                    var name = list[i].getElementsByTagName('h3')[0];
                    var email = list[i].getElementsByTagName('h4')[0];
                    let nameTxt = name.innerText.toUpperCase();
                    let emailTxt = email.innerText.toUpperCase();
                    console.log (emailTxt);

                    if (nameTxt.includes(query) || emailTxt.includes(query)) {
                            list[i].style.display = 'block';
                    }else {
                            list[i].style.display = 'none';
                    }
                }
            },
            

            ListSelect (sl) {
                this.ListData = this.ListData.filter(list => {
                    if (list.id == sl.id) {
                        if (sl.active) {
                            list.active = false;
                        }else {
                            list.active = true;
                        }
                    }
                    return list;
                });
            },

            closeList () {
                this.$emit('lstboxclse', this.lists);
            }
        },
    };
</script>

<style scoped>
    div.user-list ul li.ative {
        background-color: red;
    }
</style>
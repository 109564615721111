<script>

      import DataCenter from './../../javascript/DataCenter';

      import Request from './../../javascript/getRequest';

      import Skeleton from './../../components/blocks/Skeleton.vue';
      import ReplaceImage from '../../components/blocks/ReplaceImage.vue';
      import UserImage from '../../components/blocks/UserImage.vue';

      export default {
            name: 'ProdOwners',
            props: ['userProfile', 'owners'],
            components: {
                  Skeleton,
                  ReplaceImage,
                  UserImage,

            },

            data () {
                  return {
                        ProdOwners: [],
                        EmptyProdOwnersList : false,
                        isRequesting: true,
                  }
            },

            async mounted () {
                  // load the dispute information
                  const ReturnData = await DataCenter.ProProDRequest('https://dev.propstop.com' + '/api/v1/admin/list/' + 'prod-user', false);
                  // const ReturnData = await DataCenter.ProProDRequest('http://localhost/api.propstop.com/v1/admin/list/' + 'prod-user', false);
                  this.ProdOwners = ReturnData;

                  console.log (this.ProdOwners);

                  if (this.ProdOwners.length <= 0) this.EmptyProdOwnersList = true;
                  this.isRequesting = false;
            },

            methods: {

                  async CopyOwnerToDevelopment (owner) {
                        
                        // create new formdata
                        let formData = new FormData();

                        // add datas
                        formData.append('firstname', owner.fname);
                        formData.append('lastname', owner.lname);
                        formData.append('email', owner.email);
                        formData.append('units', '');
                        formData.append('usertype', 'Owner');

                        // request to server
                        const ReturnData = await Request.postServer('admin/action/copy-owner.php', formData);

                        // now check if the email already exists
                        if (ReturnData.success == false) {
                              if (ReturnData.reason == "duplicate") {
                                    this.userDuplicate = true;
                                    alert ("This user is already registered with PropStop. Please try login with your email address " + owner.email);
                              }else {
                                    this.signupError = true;
                                    alert ("Something went wrong! Please try again later or contact Support");
                              }
                        }else if (ReturnData.success == true) {
                              alert ('Successfully, Transfered to Development')

                        }else {
                              this.signupError = true;
                              alert ("Something went wrong! Please try again later or contact Support");
                        }
                        
                  }

            }

      };

</script>

<template>

<div class="admin-property">
        
      <div v-if="isRequesting" style="width: 50%;display: flex;margin: 0px auto;margin-top: 25%;">
            <Skeleton :type="'user-list'" :data="1" />
      </div>

      <div class="prop--table" style="padding: 25px;width: calc(100% - 50px);" v-else>
            <div class="prop-row row-header">
                  <div class="column column30">Owner</div>
                  <div class="column column30">Contact</div>
                  <div class="column column40">Copy to Dev</div>
            </div>

            <div class="prop-row" v-for="owner in ProdOwners">
                  <div class="column column30">
                        <div class="unit--admin">
                        <div class="image">
                              <UserImage :init="owner.fullname" :source="owner.avatar" />
                        </div>

                        <div class="content">
                              <h3>{{ owner.fullname }}</h3>
                              <p>{{ owner.created }}</p>
                        </div>
                        </div>
                  </div>
                  <div class="column column30">
                        <div class="contact">
                        <p>{{ owner.email }}</p>
                        <p>{{ owner.mobile }}</p>
                        </div>
                  </div> 

                  <div class="column column40">
                        <span class="btn-primary" style="display: flex;" @click="CopyOwnerToDevelopment (owner)">Copy</span>
                  </div>
            </div>
      </div>

        <!-- If no data on result -->
        <div v-if="!isRequesting && EmptyProdOwnersList" class="empty-result animated fadeInUp">
            <img src="../../images/icons/empty-result.svg" />
            <p>You dont have much information on this request. <br />New requested features on dashboard will be listed here.</p>
        </div>

      

</div>
      

</template>

<style scoped>
div.prop--table {width: 100%;height: max-content;}
div.prop--table div.prop-row {width: 100%;height: auto;padding: 10px 0;background-color: #fff;margin-bottom: 5px;overflow: hidden;border-bottom: 1px solid #e8e8e8; cursor: pointer;}
div.prop--table div.odd {background-color: #fff;}
div.prop--table div.even {background-color: #FCFCFC;}
div.prop--table div.row-header {background-color: #fff !important;}
div.prop--table div.prop-row .column30 {width: 30% !important;}
div.prop--table div.prop-row .column {width: auto;height: auto;float: left;}
div.prop--table div.row-header .column {font-size: 10pt;font-weight: 500;}
div.prop--table div.prop-row .column20 {width: 20%;}
div.prop--table div.unit--admin {width: 95%;margin-right: 5%;height: 100%;overflow: hidden;}
div.prop--table div.unit--admin div.image {width: 65px;height: 65px;overflow: hidden;margin-right: 10px;float: left;}
div.prop--table div.unit--admin div.image img {width: 100%;}
div.prop--table div.unit--admin div.content {width: calc(100% - 75px);height: auto;float: left;margin-top: 12px;}
div.prop--table div.prop-row .column h3 {font-size: 11pt;font-weight: 600;color: #191919;}
div.prop--table div.unit--admin div.content p {color: var(--light-blue);}
div.prop--table div.prop-row .column p {font-size: 10pt;letter-spacing: 0.15px;color: #000;}
img.verified { margin-top: 20px; height: 10px; width: 10px; border-radius: 50%; padding:5px;background-color: #4caf50; margin-left: 40px; }
img.unverified { margin-top: 20px; height: 10px; width: 10px; border-radius: 50%; padding:5px;background-color: #f44336; margin-left: 40px; }
div.prop--table div.contact { width: 95%; margin: 0px auto; height: auto; margin-top: 10px;}
div.prop--table div.status.primary { background-color: #449ADF; border: 1px solid #ffffff; color: #ffffff;}
div.prop--table div.status { width: max-content; font-size: 10pt; padding: 5px 15px; border-radius: 35px; margin-bottom: 5px;}
div.prop--table div.status img{height: 10px; width: 10px; border-radius: 50%; padding:5px;background-color: #4caf50;}
div.prop--table .admin--score {margin-top: 15px;}
</style>
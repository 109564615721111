import { createRouter, createWebHistory } from 'vue-router';

/**
 * Website and Authentication Pages/Views
 * auth/ for login and signup page
 * propstop.com/ for website and other pages
*/

import HomePage from '../views/website/HomePage.vue';
import PrivacyPolicy from '../views/website/PrivacyPolicy.vue';
import TermsConditions from '../views/website/TermsConditions.vue';
import CookiePolicy from '../views/website/CookiePolicy.vue';
import AboutUs from '../views/website/About-Us.vue';
import Explore from '../views/website/Explore.vue';
import Auth from '../views/website/Auth.vue';

import WebFeatures from '../views/website/Features.vue';
import WebTenants from '../views/website/Tenants.vue';
import WebLandlords from '../views/website/Landlords.vue';
import WebWaitlist from '../views/website/Waitlist.vue';


/**
 * Owner Dashboard, with Multiple Pages/Views
 * Dashboard's components will be on components/owner folder
*/

import Dashboard from '../views/owner/Dashboard.vue';

// Properties for Owner Dashboard
import Properties from '../views/owner/Properties.vue';

// Ticket page for Owner Dashboard
import Tickets from '../views/owner/Tickets.vue';

// Tenants Page for Owner Dashboard
import Tenants from '../views/owner/Tenants.vue';

// Employees Page for Owner Dashboard
import Employees from '../views/owner/Employees.vue';

// Finance Page for Owner Dashboard
import Finance from '../views/owner/Finance.vue';

// Reports Page for Owner Dashboard
import Reports from '../views/owner/Reports.vue';

// Owner Profile Page
import Profile from '../views/owner/Profile.vue';




/**
 * Tenant Dashboard, with Multiple Pages/Views
 * Dashboard's components will be on components/tenants folder
*/

import TenantDashboard from '../views/tenants/Dashboard.vue';

// Tenant Dashboard Profile
import TenantProfile from '../views/tenants/Profile.vue';

// Tenant Search Page
import TenantSearch from '../views/tenants/Search.vue';

// Tenat Property Details Page
import PropertyDetail from '../views/tenants/PropertyDetail.vue';

// Tenant Property detail page
import PropStopPropertyDetail from '../views/tenants/PropStopPropertyDetail.vue';
import PropublicPropertyDetail from '../views/website/PropublicPropertyDetail.vue';

// Tenants Ticket & Ticket Detail Page
import TenantTickets from '../views/tenants/TenantTickets.vue';

// Tenant Payment Page
import TenantPayments from '../views/tenants/TenantPayments.vue';

// Tenant Maintenance Page
import TenantMaintenance from '../views/tenants/TenantMaintenance.vue';

// Tenant Reports Page
import TenantReports from '../views/tenants/TenantReports.vue';


/// Admin Components
import AdminDashboard from '../views/admin/Dashboard.vue';
import AdminProperties from '../views/admin/Properties.vue';
import OwnerAccounts from '../views/admin/OwnerAccounts.vue';
import AdminProfile from '../views/admin/Profile.vue';
import AdminDispute from '../views/admin/Dispute.vue';
import AdminFeature from '../views/admin/Features.vue';
import ProdOwners from '../views/admin/ProdOwners.vue';
import Communication from '../views/admin/Communication.vue';
import UserAssistance from './../views/admin/UserAssistance.vue';

/**
 *  Employee Dashboard routing components
*/

import EmployeeDashboard from '../views/employee/Dashboard.vue';
import EmployeeProfile from '../views/employee/Profile.vue';
import EmployeeTicket from '../views/employee/Tickets.vue';


const routes = [

  // Website Pages Homepage
  // login/signup, About etc
  // it runs on https://propstop.com/

  {
    path: '/',
    name: 'home-page',
    component: HomePage
  },

  // Privacy Policy & Other Policy pages
  // https://propstop.com/privacy-policy/

  {
    path: '/privacy-policy/',
    name: 'privacy-policy',
    component: PrivacyPolicy
  },

  // Terms & Conditions,  Other Policy pages
  // https://propstop.com/privacy-policy/

  {
    path: '/terms-conditions/',
    name: 'terms-conditions',
    component: TermsConditions
  },

  // Cookie Policy
  // https://propstop.com/cookie-policy/

  {
    path: '/cookie-policy/',
    name: 'cookie-policy',
    component: CookiePolicy
  },

  // About PropStop Page
  // https://propstop.com/about/

  {
    path: '/about/',
    name: 'about-us',
    component: AboutUs
  },

  // Features
  // https://propstop.com/features/

  {
    path: '/features/',
    name: 'web-features',
    component: WebFeatures
  },

  // For Tenants Page
  // https://propstop.com/tenants/

  {
    path: '/tenants/',
    name: 'web-tenants',
    component: WebTenants
  },

  // For Landlords page
  // https://propstop.com/landlords/

  {
    path: '/landlords/',
    name: 'web-landlords',
    component: WebLandlords
  },

  // Waitlist page
  // https://propstop.com/waitlist/

  {
    path: '/waitlist/',
    name: 'web-waitlist',
    component: WebWaitlist
  },

  // Explore Properties
  // https://propstop.com/explore/

  {
    path: '/explore/',
    name: 'explore',
    component: Explore
  },

  // Athentication pages login and signup
  // https://propstop.com/auth/

  {
    path : '/auth/',
    name : 'Auth',
    component : Auth
  },

  // Admin Dashboard page
  // https://propstop.com/admin/dashboard/
  {
    path : '/admin/dashboard/',
    name : 'admin-dashboard',
    component : AdminDashboard
  },

  // Admin Properties page to verify
  // https://propstop.com/admin/properties/
  {
    path : '/admin/properties/',
    name : 'admin-properties',
    component : AdminProperties
  },

  // Dispute Properties
  {
    path : '/admin/dispute',
    name: 'admin-dispute',
    component: AdminDispute,
  },

  // App New Features
  {
    path : '/admin/feature',
    name: 'admin-features',
    component: AdminFeature,
  },

  // Copy Prod Owners to Development
  {
    path : '/admin/prodowners',
    name: 'admin-prodowners',
    component: ProdOwners,
  },

  // Admin Owner Accounts Verification page
  // https://propstop.com/admin/accounts/
  {
    path : '/admin/accounts/',
    name : 'admin-accounts',
    component : OwnerAccounts
  },

  // Admin Profile page
  // https://propstop.com/admin/profile/
  {
    path : '/admin/profile/',
    name : 'admin-profile',
    component : AdminProfile
  },

  // Admin Profile page
  // https://propstop.com/admin/profile/
  {
    path : '/admin/communication/',
    name : 'admin-communication',
    component : Communication
  },

  // Admin Profile page 
  // https://propstop.com/admin/assistance/
  {
    path : '/admin/assistance/',
    name : 'user-assistance',
    component : UserAssistance
  },

  // Owner Dashboard pages Dashboard, Tickets etc
  // https://propstop.com/owner/dashboard/

  {
    path : '/owner/dashboard/',
    name : 'owner-dashboard',
    component : Dashboard
  },

  // Owner Properties pages
  // https://propstop.com/owner/properties/

  {
    path : '/owner/properties/',
    name : 'owner-properties',
    component : Properties
  },

  // Owner Ticket Page
  // https://propstop.com/owner/ticket/

  {
    path : '/owner/tickets/',
    name : 'owner-tickets',
    component : Tickets
  },

  // Owner Tenants Page
  // https://propstop.com/owner/tenants/

  {
    path : '/owner/tenants/',
    name : 'owner-tenants',
    component : Tenants
  },

  // Owner Employees Page
  // https://propstop.com/owner/employees/

  {
    path : '/owner/employees/',
    name : 'owner-employees',
    component : Employees
  },

  // Owner Finance Page
  // https://propstop.com/owner/finance/

  {
    path : '/owner/finance/',
    name : 'owner-finance',
    component : Finance
  },

  // Owner Reports Page
  // https://propstop.com/owner/finance/

  {
    path : '/owner/reports/',
    name : 'owner-reports',
    component : Reports
  },

  // Owner Profile Page
  // https://propstop.com/owner/profile/

  {
    path : '/owner/profile/',
    name : 'owner-profile',
    component : Profile
  },

  // Tenants Dashboard and Other pages
  // https://propstop.com/tenant/dashboard/

  {
    path : '/tenant/dashboard/',
    name : 'tenant-dashboard',
    component : TenantDashboard
  },

  // Tenants Profile and Other pages
  // https://propstop.com/tenant/profile/

  {
    path : '/tenant/profile/',
    name : 'tenant-profile',
    component : TenantProfile
  },

  // Tenants Search Properties page
  // https://propstop.com/tenant/search/

  {
    path : '/tenant/search/',
    name : 'tenant-search',
    component : TenantSearch
  },

  // Tenants Property Detail Page
  // https://propstop.com/tenant/property/

  {
    path : '/tenant/property/',
    name : 'tenant-property',
    component : PropertyDetail
  },

  // Tenants property detail page
  // https://propstop.com/tenant/property/ID
  {
    path: '/tenant/property/:propertyid',
    name: 'propstop-property-detail',
    component: PropStopPropertyDetail,
  },

  // public property detail page
  // https://propstop.com/explore/property/ID
  {
    path: '/explore/property/:propertyid',
    name: 'public-property-detail',
    component: PropublicPropertyDetail,
  },

  // Tenants Tickets & Detail Page
  // https://propstop.com/tenant/tickets/

  {
    path : '/tenant/tickets/',
    name : 'tenant-tickets',
    component : TenantTickets
  },

  // Tenants Payments & Detail Page
  // https://propstop.com/tenant/payments/

  {
    path : '/tenant/payments/',
    name : 'tenant-payments',
    component : TenantPayments
  },

  // Tenants Maintance Page
  // https://propstop.com/tenant/maintenance/

  {
    path : '/tenant/maintenance/',
    name : 'tenant-maintenance',
    component : TenantMaintenance
  },

  // Tenants Reports Page
  // https://propstop.com/tenant/reports/

  {
    path : '/tenant/reports/',
    name : 'tenant-reports',
    component : TenantReports
  },

  // Employee Dashboard
  // https://propstop.com/employee/dashboard/

  {
    path : '/employee/dashboard/',
    name : 'employee-dashboard',
    component : EmployeeDashboard
  },

  // Employee Profile
  // https://propstop.com/employee/profile/

  {
    path : '/employee/profile/',
    name : 'employee-profile',
    component : EmployeeProfile
  },

  // Employee Tickets
  // https://propstop.com/employee/tickets/

  {
    path : '/employee/tickets/',
    name : 'employee-tickets',
    component : EmployeeTicket
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

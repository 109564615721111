<script>

      import DataCenter from './../../javascript/DataCenter';

      import Skeleton from './../../components/blocks/Skeleton.vue';
      import ReplaceImage from '../../components/blocks/ReplaceImage.vue';
      import UserImage from '../../components/blocks/UserImage.vue';

      export default {
            name: 'Feature',
            props: ['userProfile', 'owners'],
            components: {
                  Skeleton,
                  ReplaceImage,
                  UserImage,

            },

            data () {
                  return {
                        AppFeatures: [],
                        EmptyAppFeaturesList : false,
                        isRequesting: true,
                  }
            },

            async mounted () {
                  // load the dispute information
                  const ReturnData = await DataCenter.ProPRequest('app-features', false, false);
                  this.AppFeatures = ReturnData;

                  if (this.AppFeatures.length <= 0) this.EmptyAppFeaturesList = true;
                  this.isRequesting = false;
            }

      };

</script>

<template>

<div class="admin-property">
        
      <div v-if="isRequesting" style="width: 50%;display: flex;margin: 0px auto;margin-top: 25%;">
            <Skeleton :type="'user-list'" :data="1" />
      </div>

      <div v-if="!isRequesting && !EmptyAppFeaturesList" class="admin-property-content animated fadeInUp" style="width: calc(100% - 40px);">
            <div class="admin-prop--table">
                <div class="prop-row row-header">
                    <div class="column column25">Requsted</div>
                    <div class="column column15">Dashboard</div>
                    <div class="column column20">Page</div>
                    <div class="column column40">Information</div>
                </div>

                <div class="prop-row" :class="index % 2 ? 'odd' : 'even'" 
                                        v-for="(feature, index) in AppFeatures" 
                                        :key="feature.id">

                    <div class="column column25">
                        <div class="contact-dtl">
                            <div class="image">
                                <UserImage :init="feature.user.fullname" :source="feature.user.avatar" />
                            </div>

                            <div class="content">
                                <h3>{{ feature.user.fullname }}</h3>
                                <p>{{ feature.user.email }}</p>
                                <p>{{ feature.user.created }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="column column15">
                        <div class="contact-dtl">
                            <p>{{ feature.dashboard }}</p>
                        </div>
                    </div> 

                    <div class="column column20">
                        <div class="contact-dtl">
                            <p>{{ feature.page }}</p>
                        </div>
                    </div> 

                    <div class="column column40">
                        <div class="contact-dtl">
                            <p>{{ feature.message }}</p>
                        </div>
                    </div> 

                </div>
            </div>
        </div>

        <!-- If no data on result -->
        <div v-if="!isRequesting && EmptyAppFeaturesList" class="empty-result animated fadeInUp">
            <img src="../../images/icons/empty-result.svg" />
            <p>You dont have much information on this request. <br />New requested features on dashboard will be listed here.</p>
        </div>

      

</div>
      

</template>
<template>
    <div class="right-modal animated fadeInRight" :class="form" :style="'width:' + FormWidth">
        <div class="gs--header">
            <img src="./../../images/icons/close.svg" @click="closeDialog(false)" />
        </div>

        <!-- Add New Property -->
        <AddNewProperty v-if="Form == 'add-property'" @close="closeDialog" />
        <EditProperty v-if="Form == 'edit-property'" :data="data" @close="closeDialog" />

        <!-- Add Tenants Form -->
        <AddTenantsForm v-if="Form == 'add-tenant'" :data="data" @close="closeDialog" />

        <!-- Add Employee Form -->
        <AddEmployeeForm v-if="Form == 'add-employee'" :data="data" @close="closeDialog" />

        <!-- Complete Tenant/Owner Profile Form -->
        <CompleteOwnerProfile v-if="Form == 'owner-profile-complete'" :data="data" />
        <UpdateOwnerProfile v-if="Form == 'owner-profile-update'" :data="data" @close="closeDialog" />
        <OwnerBusinessProfile v-if="Form == 'owner-business-profile'" :data="data" @close="closeDialog" />

        <CompleteTenantProfile v-if="Form == 'tenant-profile-complete'" :data="data" @close="closeDialog" />
        <UpdateTenantProfile v-if="Form == 'tenant-profile-update'" :data="data" @close="closeDialog" />

        <CompleteEmployeeProfile v-if="Form == 'employee-profile-complete'" :data="data" @close="closeDialog" />
        <UpdateEmployeeProfile v-if="Form == 'employee-profile-update'" :data="data" @close="closeDialog" />
        
        <UpdateAdminProfile v-if="Form == 'admin-profile-update'" :data="data" @close="closeDialog" />

        <!-- Add New Ticket Form -->
        <AddNewTicket v-if="Form == 'add-ticket'" :data="data" @close="closeDialog" />
        <EditTicket v-if="Form == 'edit-ticket'" :data="data" @close="closeDialog" />
        <TenantNewTicket v-if="Form == 'add-tenant-ticket'" :data="data" @close="closeDialog" />
        <EmployeeNewTicket v-if="Form == 'add-employee-ticket'" :data="data" @close="closeDialog" />

        <!-- Add New Parking Form -->
        <AddNewParking v-if="Form == 'add-parking'" :data="data" @close="closeDialog" />
        <EditParking v-if="Form == 'edit-parking'" :data="data" @close="closeDialog" />

        <!-- Assign Tenant to Parking or floor plans -->
        <AssignParkings v-if="Form == 'assign-tenant-parking'" :data="data" @close="closeDialog" />
        <AssignFloorPlan v-if="Form == 'assign-floor-plan'" :data="data" @close="closeDialog" />

        <!-- Assign New Tasks -->
        <AssignTasks v-if="Form == 'assign-tasks'" :data="data" @close="closeDialog" />
        <EditTasks v-if="Form == 'edit-tasks'" :data="data" @close="closeDialog" />

        <!-- finance -->
        <AddTransaction v-if="Form == 'add-transaction'" :data="data" @close="closeDialog" />
        <AddPayment v-if="Form == 'add-payment'" :data="data" @close="closeDialog" />

        <!-- assign unit -->
        <AssignUnit v-if="Form == 'assign-unit'" :data="data" @close="closeDialog" />

        <!-- view image/upadte -->
        <ViewDocuments v-if="Form == 'view-document'" :data="data" @close="closeDialog" />
        <ViewContract v-if="Form == 'view-contract'" :data="data" @close="closeDialog" />

        <!-- change rent amount -->
        <RentAmountChangeVue v-if="Form == 'change-rent-amount'" :data="data" @close="closeDialog" />
        <AdditionalDeposits v-if="Form == 'additional-deposits'" :data="data" @close="closeDialog" />

        <!-- move out process -->
        <InitiateMoveOut v-if="Form == 'initiate-moveout'" :data="data" @close="closeDialog" />
        <TenantInitiateMoveOut v-if="Form == 'initiate-tenant-moveout'" :data="data" @close="closeDialog" />
        <CompleteMoveOut v-if="Form == 'complete-moveout'" :data="data" @close="closeDialog" />

        <!-- cookie process -->
        <CookiePreferences v-if="Form == 'cookie-preferences'" :data="data" @close="closeDialog" />

        <GetInTouch v-if="Form == 'get-in-touch'" :data="data" @close="closeDialog" />

    </div>
    
    <div class="overlay" @click="closeDialog(false)"></div>
</template>

<script>
    
    // add Forms
    import AddNewProperty from './AddNewProperty.vue';
    import EditProperty from './EditProperty.vue';

    import AddTenantsForm from './AddTenants.vue';
    import AddEmployeeForm from './AddEmployee.vue';
    import CompleteOwnerProfile from './CompleteOwnerProfile.vue';
    import UpdateOwnerProfile from './UpdateOwnerProfile.vue';
    import OwnerBusinessProfile from './OwnerBusinessProfile.vue';
    import CompleteTenantProfile from './tenant/CompleteTenantProfile.vue';
    import UpdateTenantProfile from './tenant/UpdateTenantProfile.vue';
    import CompleteEmployeeProfile from './employee/CompleteEmployeeProfile.vue';
    import UpdateEmployeeProfile from './employee/UpdateEmployeeProfile.vue';

    import UpdateAdminProfile from './admin/UpdateAdminProfile.vue';

    import AddNewTicket from './AddNewTicket.vue';
    import EditTicket from './EditTicket.vue';
    import TenantNewTicket from './TenantNewTicket.vue';
    import EmployeeNewTicket from './employee/AddNewTicket.vue';

    import AddNewParking from './AddNewParking.vue';
    import EditParking from './EditParking.vue';

    import AssignParkings from './AssignParkings.vue';
    import AssignFloorPlan from './floorplan/AssignFloorPlan.vue';
    import AssignTasks from './AssignTasks.vue';
    import EditTasks from './EditTasks.vue';

    import AddTransaction from './finance/AddTransaction.vue';
    // import AddTransaction from './AddTransaction.vue';
    import AddPayment from './AddPayment.vue';

    import AssignUnit from './AssignUnit.vue';
    import ViewDocuments from './ViewDocuments.vue';
    import ViewContract from './ViewContract.vue';

    import RentAmountChangeVue from './RentAmountChange.vue';
    import AdditionalDeposits from './AdditionalDeposits.vue';

    import InitiateMoveOut from './InitiateMoveOut.vue';
    import TenantInitiateMoveOut from './TenantInitiateMoveOut.vue';
    import CompleteMoveOut from './CompleteMoveOut.vue';

    import CookiePreferences from './CookiePreferences.vue';

    import GetInTouch from './tenant/GetInTouch.vue';

    export default {
        name: 'RightModal',
        props: ['form', 'data'],
        emits: ['modalclose'],
        components: {
            AddNewProperty,
            EditProperty,
            
            AddTenantsForm,
            AddEmployeeForm,
            CompleteOwnerProfile,
            UpdateOwnerProfile,
            OwnerBusinessProfile,
            CompleteTenantProfile,
            UpdateTenantProfile,
            CompleteEmployeeProfile,
            UpdateEmployeeProfile,

            UpdateAdminProfile,

            AddNewTicket,
            EditTicket,
            TenantNewTicket,
            EmployeeNewTicket,
            AddNewParking,
            EditParking,

            AssignParkings,
            AssignFloorPlan,
            AssignTasks,
            EditTasks,

            AddTransaction,
            AddPayment,

            AssignUnit,

            ViewDocuments,
            ViewContract,

            RentAmountChangeVue,
            AdditionalDeposits,

            InitiateMoveOut,
            TenantInitiateMoveOut,
            CompleteMoveOut,

            CookiePreferences,

            GetInTouch,
        },

        data () {
            return {
                Form : this.form,
                FormWidth : '50%',
            }
        },

        mounted () {
            // calculate form width
            if (this.Form == "owner-profile-complete" || this.Form == "tenant-profile-complete" || this.Form == "employee-profile-complete") {
                this.FormWidth = "100%";
            }else if (this.Form == "add-parking" || this.Form == "edit-parking" || this.Form == 'add-payment' || this.Form == 'create-task' || this.Form == 'assign-tenant-parking' || this.Form == 'assign-tasks' || this.Form == 'edit-tasks' || this.Form == 'assign-floor-plan' || this.Form == 'view-document' || this.Form == 'view-contract' || this.Form == 'change-rent-amount' || this.Form == 'rent-history' || this.Form == 'additional-deposits' || this.Form == 'initiate-moveout' || this.Form == 'initiate-tenant-moveout' || this.Form == 'complete-moveout' || this.Form == 'cookie-preferences') {
                this.FormWidth = "35%";
            }else if (this.Form == "get-in-touch" || this.Form == "add-ticket" || this.Form == "owner-business-profile" || this.Form == "owner-profile-update" || this.Form == "add-transaction" || this.Form == "assign-unit") {
                this.FormWidth = "50%";
            }else if (this.Form == "add-property") {
                this.FormWidth = "70%";
            }else {
                this.FormWidth = "60%";
            }
        },

        methods: {
            // close the dialog
            closeDialog (returndata) {
                this.$emit('modalclose', returndata);
            }
        }
    };
</script>

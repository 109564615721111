<template>
    <div class="user-popup animatedss fadeInUp">
        <div class="popwner">
            <div class="image">
                <UserImage :init="user.fullname" :source="user.avatar" style="border-radius:50%" />
            </div>

            <h3>{{user.fullname}}</h3>
            <p>{{user.email}}</p>
        </div>

        <ul class="user-popmenu">
            <li @click="openProfilePage">
                <img src="./../../images/profile/user.svg" />
                <h4>Personal Profile</h4>
            </li>
            <li>
                <img src="./../../images/profile/settings.svg" />
                <h4>Settings</h4>
            </li>
            <li @click="logoutCurrentUser">
                <img src="./../../images/profile/logout.svg" />
                <h4>Logout</h4>
            </li>
        </ul>
    </div>

    <div class="overlay" @click="closeProfile"></div>
</template>

<script>

    //@import JavaScript
    import Request from './../../javascript/getRequest';
    import Cookie from './../../javascript/Cookie';

    //@import component
    import UserImage from './../blocks/UserImage.vue';


    export default {
        name: 'OwnerProfilePopup',
        props: ['user'],
        emits: ['profileclose'],
        components: {
            UserImage
        },

        data () {
            return {
                StockURL : Request.StockURL,
            }
        },

        methods : {
            closeProfile () {
                this.$emit('profileclose');
            },

            openProfilePage () {
                // close the profile pop-up
                this.$emit('profileclose');
                
                this.$router.push({name : 'tenant-profile'})
            },

            // reset the cookie and send route to homepage
            logoutCurrentUser () {
                Cookie.setCookie('ps-route', '', -1);
                Cookie.setCookie('ps-token', '', -1);

                // close the profile pop-up
                this.$emit('profileclose');

                // load the application
                location.reload ();
            },
        }
    };
</script>
// get cookie for user token
import Cookie from './Cookie';

const Production = true;
// const apiUri = Production ? 'https://dev.propstop.com' + '/api/v1/' : 'http://localhost/api.propstop.com/v1/';
const apiUri = Production ? 'https://propstop.com' + '/api/v1/' : 'http://localhost/api.propstop.com/v1/';

// request base url, where the api is hosted development vs production
const BaseUrl = apiUri;

const StockURL = "https://dev.propstop.com/fetch-stock/";
const UserToken = '238ljasdfu!idsQp';

const Error = {
    'status': false,
    'reason': "error"
};


// make request and get JSON data GET
const getData = async (RequestPage) => {

    const Token = Cookie.getCookie('ps-token');

    // start request using fetch Api
    try {
        let response = await fetch(BaseUrl + RequestPage, {
            
            // requested method
            method: 'GET',

            // headers
            headers: {
                Accept: 'application/json',
                'X-User-Token': Token,
            }

        });

        let responseData = await response.json();
        if (responseData) {
            if (responseData.reason == "user error") {
                // force to remove this user and load home page
                // now set data to cookies and force login
                Cookie.setCookie ('ps-route', '', -1);
                Cookie.setCookie ('ps-token', '', -1);

                // reload the app in auth
                location.reload();

            }else return responseData;
        } else {
            return Error;
        }

    } catch (error) {
        return Error;
    }
};

// make request and get JSON data GET
const getProdData = async (RequestPage) => {

    const Token = Cookie.getCookie('ps-token');

    // start request using fetch Api
    try {
        let response = await fetch(RequestPage, {
            
            // requested method
            method: 'GET',

            // headers
            headers: {
                Accept: 'application/json',
                'X-User-Token': Token,
            }

        });

        let responseData = await response.json();
        if (responseData) {
            if (responseData.reason == "user error") {
                // force to remove this user and load home page
                // now set data to cookies and force login
                Cookie.setCookie ('ps-route', '', -1);
                Cookie.setCookie ('ps-token', '', -1);

                // reload the app in auth
                location.reload();

            }else return responseData;
        } else {
            return Error;
        }

    } catch (error) {
        return Error;
    }
};


// post data to server
const postServer = async (RequestPage, Data) => {

    const Token = Cookie.getCookie('ps-token');
    
    // start request to the server using fetch api
    try {
        let response = await fetch(BaseUrl + RequestPage, {
            // request Method
            method : 'POST',

            // headers
            headers: {
                Accept: 'application/json',
                'X-User-Token': Token,
            },

            body: Data
        });

        let responseData = await response.json();
        if (responseData)
            return responseData;
        else
            return Error;

    } catch (error) {
        return error
    }

};


export default {
    StockURL,
    getData,
    getProdData,
    postServer,
}
<template>
    <div v-if="!ImageLoad" class="imgcls" :data-loading-image="source" :style="{'background-image':'url('+require('../../images/icons/img-loading.svg')+')'}"></div>
    <div v-else-if="ImageError" class="imgcls" :data-error-image="source" :style="'background-color: #' + this.back">
        <p>{{ImageName}}</p>
    </div>
    <img v-else :src="source" />
</template>

<script>    
    export default {
        name : 'UserImage',
        props : ['init','source'],

        data () {
            return {
                Img : null,
                ImageLoad : false,
                ImageError : false,

                ImageName : null,
                BGColor : null,
                TextColor : null,
                BorderColor : null,
                back: this.RandomBackgroundColor()
            }
        },

         mounted () {
            if (this.init != undefined || this.init != null || this.source != undefined || this.source != null) {
                this.Img = new Image ();
                this.Img.addEventListener ('load', () => {
                    this.ImageLoad = true;
                });

                this.Img.addEventListener ('error', () => {
                    this.ImageLoad = true;
                    this.ImageError = true;
                });
                this.Img.src = this.source;

                if (this.init.length >= 2) {
                    let NmeAry = this.init.split(" ");
                    this.ImageName = NmeAry[0].charAt(0);

                    var x = Math.floor(Math.random() * 256);
                    var y = Math.floor(Math.random() * 256);
                    var z = Math.floor(Math.random() * 256);
                    this.BGColor = "rgba(" + x + "," + y + "," + z + ", 0.2)";
                    this.TextColor = "rgb(" + x + "," + y + "," + z + ")";
                    this.BorderColor = "rgba(" + x + "," + y + "," + z + ", 0.3)";
                }
            }
        },

        methods: {
            RandomBackgroundColor () {
                let colors = ['52A3FE', '8C84F3', 'F08072', '9DD11F', 'E87093', 'C17AE0', '2CB7D4', 'FDA748', '32955A', '8B8B8B'];
                let Random = Math.floor(Math.random() * 10);
                return colors[Random];
            }
        }
    }
</script>

<style scoped>
.imgcls {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-repeat: no-repeat;
    background-size: 35%;
    background-position: center;
    border-radius: 50%;
    border: 1px solid #ffffff;
    background-color: #FF9800;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.imgcls p {
    font-weight: 600;
    text-align: center;
    font-size: 14pt !important;
    padding-top: 0 !important;
    color: white !important;
}
</style>
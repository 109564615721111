<template>
    <div class="tenant-dashboard _propscrl" :style="{'background-image':'url('+require('../../images/company/loading-bg.jpg')+')'}">        
        <div v-if="!employeeProfile" style="width: 50%;">
            <Skeletion :type="'user-list'" :data="1" />
        </div>

        <div class="user" v-else>
            <div class="avatar">
                <UserImage :init="employeeProfile.fullname" :source="employeeProfile.avatar" />
            </div>

            <div class="content">
                <h2>Hi, {{employeeProfile.fname}}</h2>
            </div>
            
        </div>

        <div class="teandashboard">
            <ul class="dash-content">
                <li>
                    <div class="dash-box">
                        <div class="header">
                            <h3>Quick Links</h3>
                        </div>
                        <div class="content">
                            <ul class="dllinks _propscrl">
                                <li>
                                    <div class="image">
                                        <img src="../../images/icons/user-profile.svg" />
                                    </div>

                                    <div class="content">
                                        <h3>Personal Profile</h3>
                                        <p>Update profile, password</p>
                                    </div>
                                </li>

                                <li>
                                    <div class="image">
                                        <img src="../../images/icons/document-text-outline.svg" />
                                    </div>

                                    <div class="content">
                                        <h3>Lease Agreement</h3>
                                        <p>Agreement for both parties</p>
                                    </div>
                                </li>

                                <li>
                                    <div class="image">
                                        <img src="../../images/icons/policy.svg" />
                                    </div>

                                    <div class="content">
                                        <h3>Renters Insurance</h3>
                                        <p>Powered by PropStop Insurance</p>
                                    </div>
                                </li>

                                <li>
                                    <div class="image">
                                        <img src="../../images/icons/tools-line.svg" />
                                    </div>

                                    <div class="content">
                                        <h3>Maintenance Requests</h3>
                                        <p>You can request unit maintenance</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li>

                    <div class="dash-box" v-if="!SelectedProperty" style="padding-top: 15px;">
                        <Skeletion :type="'user-list'" :data="1" />
                    </div>

                    <div v-else class="dash-box">
                        <div class="dbprtimage">
                            <ReplaceImage :source="SelectedProperty.thumbnail" />
                            <div class="count">
                                <p>{{SelectedProperty.gallery}}</p>
                                <img src="../../images/icons/gallery-white.svg" />
                            </div>
                        </div>

                        <div class="content">
                            <div class="propery-detail">
                                <div class="title">
                                    <span>{{SelectedProperty.type}}</span>
                                </div>

                                <h3>{{SelectedProperty.name}}</h3>
                                <p>{{SelectedProperty.address}}</p>

                                <ul class="features" v-if="SelectedProperty.total_features > 0">
                                    <li>{{SelectedProperty.feature}}</li>
                                    <li>{{SelectedProperty.total_features}}+</li>
                                </ul>
                            </div>

                            <ul class="main-feat">
                                <li>
                                    <img src="../../images/icons/bed-outline.svg" />
                                    <p>2 Beds</p>
                                </li>

                                <li>
                                    <img src="../../images/icons/bathroom.svg" />
                                    <p>1 Bath</p>
                                </li>

                                <li>
                                    <img src="../../images/icons/scale.svg" />
                                    <p>1.2k sq. m.</p>
                                </li>
                            </ul>

                        </div>
                    </div>

                </li>

                <li>
                    <div class="dash-box" style="padding: 0 15px;padding-bottom: 15px;">
                        <div class="header">
                            <h3>Recent Activities</h3>
                            <span>See All</span>
                        </div>

                        <RecentActivity :data="RcntActivity" :key="rctyload" />
                    </div>
                </li>
            </ul>
        </div>
        
    </div>
</template>

<script>
    // @ is an alias to /src
    import Document from '../../javascript/Document';
    import DataCenter from './../../javascript/DataCenter';
    import Storage from './../../javascript/Storage';

    import Skeletion from './../../components/blocks/Skeleton.vue';
    import ReplaceImage from '../../components/blocks/ReplaceImage.vue';
    import UserImage from './../../components/blocks/UserImage.vue';

    // dashboard component
    import RecentActivity from './../../components/owner/dashboard/RecentActivity.vue';

    export default {
        name: 'Dashboard',
        props: ['employeeProfile', 'properties', 'selected'],

        components : {
            Skeletion,
            ReplaceImage,
            UserImage,

            RecentActivity,
        },

        data () {
            return {
                DashboardInformation : false,
                PropertyData : false,
                RcntActivity : [],
                
                // recent activity loaded
                rctyload : false,

                EmployeeProperties : this.properties,
                SelectedProperty : this.selected.property,
            }
        },

        mounted () {
            // site title and seo
            Document.DocumentHeadings ({title : 'Dashboard - PropStop'});

            // employee activities and property details
            if (this.employeeProfile) this.loadEmployeeData ();
        },

        methods: {

            async loadEmployeeData () {
                const RequestData = await DataCenter.ProPRequest('employee-dashboard', false, false);
                if (!RequestData) {} else {
                    this.DashboardInformation = RequestData;

                    // Property and activities
                    this.RcntActivity = RequestData;
                    this.rctyload = true;

                    // now store data to storage
                    Storage.storeCache('employee-activity', this.RcntActivity);
                }
            }
        }
    };
</script>
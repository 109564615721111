<template>
    <div class="internal--header">
        <div class="sumfpsmry summery fp-smry">
            <ul class="backtounitlist" v-if="PlanIsSelected">
                <li><img src="../../images/employee-detail/arrow-left.svg" @click="BackToList"> <p>Back</p></li>
            </ul>

            <h3 v-if="!PlanIsSelected"><strong>{{TotalPlans}}</strong> Plans</h3>
            <h3 v-if="!PlanIsSelected"><strong>{{TotalBeds}}</strong> Beds</h3>            
            <h3 v-if="!PlanIsSelected"><strong>{{TotalBaths}}</strong> Baths</h3>            
        </div>

        <div class="sumfpact actions fp-atns">
            <div class="btn-secondary" style="display: none;" v-if="!PlanIsSelected" @click="AddNewFloorPlan = true"><p>Add Floorplan</p></div>
            <div class="btn-secondary" style="border-color: red; color: red;" v-if="PlanIsSelected" @click="DeleteFloorPlan"><p>Delete</p></div>
            <div class="btn-secondary" v-if="PlanIsSelected" @click="EditFloorPlan"><p>Edit</p></div>
            <div class="btn-secondary" v-if="PlanIsSelected" @click="UploadAttachment = true"><p>Upload Images</p></div>
        </div>
    </div>

    <!-- Loading State Floorplan Skeletion -->
    <div v-if="PlansLoadingState" style="margin-top:55px">
        <Skeletion :data="3" />
    </div>

    <div v-if="!PlansLoadingState && PropertyPlans.length >= 0 && !PlanIsSelected" class="floor--plans">
        <ul>
            <li v-for="plan in PropertyPlans" :key="plan" @click="FloorPlanClick (plan)">
                <div class="fps-content">
                    <h3>{{plan.name}}</h3>
                    <ul class="plan-desc">
                        <li>{{plan.bedrooms}} Beds</li>
                        <li>{{plan.bathrooms}} Batsh</li>
                        <li>{{plan.squarefeet}} sq. ft</li>
                    </ul>
                    <p>{{plan.description}}</p>
                </div>

                <div class="fps-image">
                    <div class="blueprint">
                        <ReplaceImageVue :source="plan.planurl" :key="''" />
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <FloorPlanDetail v-if="PlanIsSelected" :property="property" :plan="SelectedPlanDetail" :units="PropertyUnits" />

    <!-- If no data on result -->
    <div v-if="!PlansLoadingState && PropertyPlans.length <= 0" class="empty-result animated fadeInUp">
        <img src="../../images/icons/empty-result.svg" />
        <p>You dont have much information on this request. <br />Please modify or add new floorplans.</p>

        <WalkThroughVue
                v-if="WthoughPrimary"
                :image="'edit-property.svg'"
                :title="'Add Floorplan'"
                :content="'Add floorplan with different amenities and other detailsss'"
                :toPoint="'nowhere'"
                :style="'top: -30px;right: 55px;'"
                @closewth="WthoughPrimary = false" />
    </div>

    <PropModal v-if="AddNewFloorPlan == true" :from="'floor-plans'" :data="PropertyID" @modalclose="CloseFloorPlanModal" />    
    <PropModal v-if="WantEditFloorPlan == true" :from="'edit-floor-plan'" :data="EditParkingData" @modalclose="CloseEditFloorPlanModal" />
    <PropModal v-if="UploadAttachment" :from="'upload-document'" :data="AttachmentData" @modalclose="CloseUploadModal" />
    <PropModal v-if="WantDeleteFloorPlan == true" :from="'confirm-delete-floorplan'" :data="DeleteParkingData" @modalclose="CloseConfrimDelete" />


</template>

<script>

     //@ JS Modules
    import Request from './../../javascript/getRequest';
    import DataCenter from './../../javascript/DataCenter';
    import Locale from './../../javascript/Locale';
    import Handler from './../../javascript/Handler';

    import Skeletion from './../blocks/Skeleton.vue';
    import FloorPlanDetail from './blocks/FloorPlanDetail.vue';
    import ReplaceImageVue from '../blocks/ReplaceImage.vue';

    // walk through
    import WalkThroughVue from './../reusable/WalkThrough.vue';

    // modal 
    import PropModal from './../modal/PropModal.vue';

    export default {
        name: 'FloorPlan',
        props: ['property', 'initFloorPlan'],
        emits: ['dischargeFloorPlan'],
        components: {
            Skeletion,
            FloorPlanDetail,
            ReplaceImageVue,
            WalkThroughVue,

            PropModal,
        },
        data () {
            return {
                PropertyPlans : [],
                PlanIsSelected : false,
                SelectedPlanDetail : null,
                TotalPlans : 0,
                TotalBeds : 0,
                TotalBaths : 0,

                PlansLoadingState: true,
                hasFloorPlan: false,

                Amenities: Locale.UnitAmenities (),

                // attachment
                UploadAttachment : false,
                AttachmentData: {
                    title: 'Upload Images',
                    description: 'Please click on the "Add Files" button or drag and drop an image below to upload pictures of the floorplan.',
                    error: 'Please upload a JPEG or JPG files only!',
                    fileschema: ['jpeg', 'jpg'],
                },

                AddNewFloorPlan: false,
                PropertyID: this.property.id,

                PropertyUnits: [],
                UnitsLoaded: false,

                WantDeleteFloorPlan: false,
                DeleteParkingData : [],                
                WantEditFloorPlan: false,
                EditParkingData: [],

                // page walk through
                WthoughPrimary: true,
            }
        },

        async mounted () {

            // init add floorplan
            if (this.initFloorPlan) {
                this.AddNewFloorPlan = true;
            }

            // load the current property amenities
            // load units data from server
            let getdata = {
                key : 'property',
                value : this.PropertyID
            }
            
            const FloorPlans = await DataCenter.ProPRequest('property-floor-plans', false, getdata);
            this.PlansLoadingState = false;
            if (!FloorPlans) {
                this.hasFloorPlan = false;
            }else {
                this.hasFloorPlan = true;
                this.PropertyPlans = FloorPlans;
                this.GetTotalData ();
            }
            
            // load all the units of current property
            this.loadSelectedPropertyUnits (this.PropertyID); 
        },

        methods: {

            DeleteFloorPlan () {
                this.WantDeleteFloorPlan = true;
                this.DeleteParkingData = this.SelectedPlanDetail;
            },

            async CloseConfrimDelete (confirm) {

                if (confirm) {
                    // delete floorplan
                    // unlink all the units
                    // delete all the images
                    var delPlan = this.SelectedPlanDetail.id;
                    this.PropertyPlans = this.PropertyPlans.filter (plan => {
                        if (delPlan == plan.id) {}
                        else return plan;
                    });

                    // remove from server too
                    let formData = new FormData();
                    formData.append('property', this.PropertyID);
                    formData.append('floorplan', this.SelectedPlanDetail.id);            

                    // upload to the server
                    const ReturnData = await Request.postServer('owner/property/floorplan/delete.php', formData);
                    if (!ReturnData.error) {          
                        this.WantDeleteFloorPlan = false;

                        // now go the the main page
                        this.PlanIsSelected = false;

                        // update notification
                        Handler.PropNotification ("Selected floorplan successfully deleted! Units are unlinked and Images are all removed associated with this floorplan!");  
                    }
                }else {
                    this.WantDeleteFloorPlan = false;
                }
            },

            // edit floorplan
            EditFloorPlan () {
                this.WantEditFloorPlan = true;
                this.EditParkingData = this.SelectedPlanDetail;
            },

            CloseEditFloorPlanModal (plans) {

                if (plans) {
                    this.PropertyPlans = plans;
                    this.GetTotalData ();

                    // update notification
                    Handler.PropNotification ("Floorplan successfully updated!");
                }

                this.WantEditFloorPlan = false;

            },

            // load blank units on the selected properties
            async loadSelectedPropertyUnits (propertyid) {
                // load units data from server
                let getdata = {
                    key : 'property',
                    value : propertyid
                }
                
                const Units = await DataCenter.ProPRequest('property-units', false, getdata);

                if (!Units) {}else {
                    this.PropertyUnits = Units;
                    this.UnitsLoaded = true;
                }   
            },

            GetTotalData () {
                this.TotalPlans = 0;
                this.TotalBeds = 0;
                this.TotalBaths = 0;
                this.PropertyPlans.filter (plan => {
                    this.TotalPlans++;
                    this.TotalBeds += parseInt(plan.bedrooms);
                    this.TotalBaths += parseInt(plan.bathrooms);
                });
            },

            // close floor plan modal
            CloseFloorPlanModal (plans) {
                if (plans) {
                    this.PropertyPlans = plans;
                    this.GetTotalData ();

                    // update notification
                    Handler.PropNotification ("Floorplan successfully added!");
                }

                this.AddNewFloorPlan = false;
                this.$emit('dischargeFloorPlan');
            },

            // floorplan selected
            FloorPlanClick (plan) {
                if (this.UnitsLoaded) {
                    this.SelectedPlanDetail = plan;
                    this.PlanIsSelected = true;
                }

            },

            // back to list from floorplan detail page
            BackToList () {
                this.SelectedPlanDetail = {};
                this.PlanIsSelected = false;
            },

            // close documents upload modal
            async CloseUploadModal (blobupload) {
                
                if (blobupload) {
                    // now upload the selected files to the server
                    let formData = new FormData();
                    formData.append('property', this.PropertyID);
                    formData.append('uploadtype', 'floorplan');
                    formData.append('floorplan', this.SelectedPlanDetail.id);

                    // convert file list into files[array] form data                
                    blobupload.forEach(element => {
                        formData.append('documents[]', element);
                    });                

                    // upload to the server
                    const ReturnData = await Request.postServer('owner/upload/gallery-upload.php', formData);
                    if (!ReturnData.error) {          
                        // add all to floorplan gallery
                        this.SelectedPlanDetail.gallery = ReturnData.response;

                        // close the modal
                        this.UploadAttachment = false;

                        // update notification
                        Handler.PropNotification ("Successfully uploaded media to current floorplan!"); 
                    }
                }else {
                    // close the modal
                    this.UploadAttachment = false;
                }

            },

        }
    };
</script>
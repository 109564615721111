<template>
    <div class="property-verify _propscrl">
        <div class="summery">
            <div class="avatar">
                <div class="image">
                    <UserImage :init="Owner.fullname" :source="Owner.avatar" style="border-radius: 0;" />
                </div>    
                <h2>{{ Owner.fullname }}</h2>
                <span v-if="!isOwnerVerified && !isSendingInvitation" @click="ResendInvitation">Resend Invitation</span>
                <span v-if="isSendingInvitation">Sending...</span>

                <span v-if="!isOwnerVerified && !isDeletingInvitation" style="line-height: 12pt;color: red;" @click="DeleteRequest">Delete Request</span>
                <span v-if="isDeletingInvitation" style="line-height: 12pt;color: red;">Deleting Request...</span>

            </div>

            <ul class="sumry">
                <li>
                    <div class="icon">
                        <img src="./../../../images/admin/email.svg">
                    </div>
                    <p>{{ Owner.email }}</p>
                </li>
                <li>
                    <div class="icon">
                        <img src="./../../../images/admin/date.svg">
                    </div>
                    <p>{{ Owner.created }}</p>
                </li>
                <li>
                    <div class="icon">
                        <img src="./../../../images/admin/phone.svg">
                    </div>
                    <p>{{ Owner.mobile }}</p>
                </li>
            </ul>

            <ul class="status">
                <li v-if="Owner.verified == 'true'"><img class="admin-verified" src="../../../images/admin/check.svg"> <p>Verified</p></li>
                <li v-else><img class="admin-unverified" src="../../../images/admin/cross.svg"> <p>Unverified</p></li>

                <li v-if="Owner.email_verify == 'true'"><img class="admin-verified" src="../../../images/admin/check.svg"> <p>Email Verified</p></li>
                <li v-else><img class="admin-unverified" src="../../../images/admin/cross.svg"> <p>Email Unverified</p></li>

                <li v-if="Owner.verification == 'true'"><img class="admin-verified" src="../../../images/admin/check.svg"> <p>Waitlist</p></li>
            </ul>
        </div>

        <div class="content">
            <ul class="other-summery">
                <li>
                    <h3>Business Information</h3>
                    <table>
                        <tr>
                            <td>Units Managing</td>
                            <td>{{ Owner.units }}</td>
                        </tr>
                        <tr>
                            <td>Business Name</td>
                            <td>{{ Owner.business }}</td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>{{ Owner.address }}</td>
                        </tr>
                        <tr>
                            <td>Mobile/Phone</td>
                            <td>{{ Owner.buz_phone }}</td>
                        </tr>
                        <tr>
                            <td>Support Email</td>
                            <td>{{ Owner.buz_support }}</td>
                        </tr>
                    </table>
                </li>

                <li>
                    <h3>Owner Properties</h3>
                    <ul class="pop-owner-properties">
                        
                        <!-- If no data on result -->
                        <div v-if="Properties.length <= 0" class="empty-result" style="height: 200px; text-align: left; position: unset;">
                            <img src="../../../images/icons/empty-result.svg" style="margin: 0;width: 150px;" />
                            <p>This owner has no <br />registered property yet.</p>
                        </div>

                        <li v-for="property in Properties" :key="property">
                            <div class="image">
                                <ReplaceImage :source="property.thumbnail" />
                            </div>
                            <div class="property-add">
                                <h3>{{ property.name }}</h3>
                                <p>{{ property.address }}</p>
                                <p class="date-listing">{{ property.created }}</p>
                                <span v-if="property.verified=='false'" style="color:#ff5722">(Unverified)</span>
                            </div>
                        </li>
                    </ul>
                </li>
                
            </ul>
        </div>
    </div>

    <div class="form-action" style="width: calc(100% - 50px);">
        <div class="form-area" style="width: 95%;">
            <div v-if="Owner.verified == 'false' && Owner.verification == 'true' && Owner.email_verify == 'true'" class="btn-primary" ref="verifyowner" @click="VerifyOwner">Verify Account</div>
            <div v-else-if="Owner.verified == 'true' && Owner.verification == 'false' && Owner.email_verify == 'true'">
                <p class="info success"><img src="../../../images/admin/check.svg" /> Owner is verified</p>
            </div>
            <div v-else>
                <p class="info"><img src="../../../images/icons/info-icon.svg" /> To Verify, Owner must be email verified and is on waitlist!</p>
            </div>
        </div>
    </div>
</template>

<script>
    // js library
    import Request from './../../../javascript/getRequest';
    import Handler from './../../../javascript/Handler';

    // components
    import UserImage from '../../blocks/UserImage.vue';
    import ReplaceImage from '../../blocks/ReplaceImage.vue';


    export default {
        name: 'OwnerVerify',
        props: ['data'],
        emits: ['close'],
        components: {
            UserImage,
            ReplaceImage
        },

        data () {
            return {
                Properties: this.data.properties,
                Owner: this.data.owner,

                isSendingInvitation: false,
                isDeletingInvitation: false,
                isOwnerVerified: false,
            }
        },

        mounted () {

            var isverified = this.Owner.verified;
            if (isverified == 'true') this.isOwnerVerified = true;

        },

        methods: {

            async DeleteRequest () {
                // user id
                var userid = this.Owner.id;
                this.isDeletingInvitation = true;
                const Requests = await Request.getData('admin/action/delete-invitation.php?user=' + userid);

                // notify success
                Handler.PropNotification('Successfully deleted the request of the owner <strong>' + this.Owner.fullname + '</strong>');

                // close the dialog and update
                // update the account list too.
                this.isDeletingInvitation = false;
                this.$emit('close', {task: 'delete', owner: this.Owner});
                
            },

            async ResendInvitation () {
                // user id
                var userid = this.Owner.id;
                this.isSendingInvitation = true;

                // request to send invitation again
                const Requests = await Request.getData('admin/action/resend-invitation.php?owner=' + userid);

                // notify on success
                Handler.PropNotification('Successfully sent veriticaion email to the owner <strong>' + this.Owner.fullname + '</strong>');
                
                this.isSendingInvitation = false;
                this.isOwnerVerified = true;

            },

            async VerifyOwner () {
                let userid, ownerid, fullname, email;
                ownerid = this.Owner.owner;
                fullname = this.Owner.fullname;
                email = this.Owner.email;
                userid = this.Owner.id;

                let formData = new FormData();
                    formData.append('user', userid);
                    formData.append('owner', ownerid);
                    formData.append('fullname', fullname);
                    formData.append('email', email);

                    this.$refs.verifyowner.innerHTML = 'Working...';

                    // load data from server
                    const ReturnData = await Request.postServer('admin/action/verify-account.php', formData);
                    if (ReturnData.error == false) {
                        this.Owner.verified = 'true';
                        this.Owner.verification = 'false';

                        // update the account list too.
                        this.$emit('close', {task: 'verify', owner: this.Owner});
                    }else {
                        this.$refs.verifyowner.innerHTML = 'Verify Account';
                    }

            }
        }
    };
</script>